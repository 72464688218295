import { Button, Avatar, Statistic } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { SVGMap } from 'react-svg-map';
import { navPage } from '../../funcAndVar/func'
import { SvgIraq } from '../../funcAndVar/SvgIraq';
import CountUp from 'react-countup';
import { MdMilitaryTech } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal } from 'react-bootstrap';
import Loading from '../../components/Loading';
import { defaultProfile2 } from '../../funcAndVar/var';
import { BsDatabase } from 'react-icons/bs';
export default function Spyker() {
    const [loading, setLoading] = useState(true)
    const [dead, setDead] = useState([]);
    const [deadCount, setDeadCount] = useState(0);
    const [deadCity, setDeadCity] = useState([]);
    const [pointedLocation, setPointedLocation] = useState('');
    const [location, setLocation] = useState('');
    const [count, setCount] = useState('');
    const [tooltipStyle, setTooltipStyle] = useState({ display: 'none' });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        document.title = 'شهدائنا'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/spyker/?page_size=30')
                .then(res => {
                    setDead(res.data.results);
                    setDeadCount(res.data.count);
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            if (show) {
                await axios.get(`/dead_wounded/api/v1/spyker/?search=${location}`)
                    .then(res => {
                        setDeadCity(res.data.results)
                        setCount(res.data.count)
                    })
            } else {
                return null
            }

        }
        getData()
    }, [location])
    const formatter = (value) => <CountUp end={value} separator="," />;

    function getLocationName(event) {
        return event.target.attributes.name.value;
    }

    function handleLocationMouseClick(event) {
        const pointedLocation = getLocationName(event);
        setLocation(pointedLocation);
        handleShow()
    }
    function handleLocationMouseOver(event) {
        const pointedLocation = getLocationName(event);
        setPointedLocation(pointedLocation);
    }
    function handleLocationMouseOut() {
        setPointedLocation(null);
        setTooltipStyle({ display: 'none' });
    }
    function handleLocationMouseMove(event) {
        const tooltipStyle = {
            display: 'block',
            top: event.clientY + 10,
            left: event.clientX - 100
        };
        setTooltipStyle(tooltipStyle);
    }
    if (loading) {
        return <Loading />
    }
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-md-12">
                        <div className="container-fluid mt-3 m-0 p-0">
                            <div className="row justify-content-start align-items-center g-2">
                                <div className="col-md-12 home-dead-content">

                                    <div className="container-fluid">
                                        <div className="row justify-content-center align-items-center g-2">
                                            <div className="col-xl-11">
                                                <p className='home-dead-title'>السيد السيستاني عن شهداء سبايكر</p>
                                                <p className='home-dead-disc'>عندما يمر ذكر شهداء الحشد الشعبي استذكر تدافع أصحاب الإمام الحسين عليه السلام الى المنية...</p>
                                            </div>
                                            <div className="col-xl-11">
                                                <div className='home-dead-card'>
                                                    <div className="container-fluid">
                                                        <div className="row justify-content-center align-items-start g-2">
                                                            <div className="col-xl-6">
                                                                <p className='card-title'>الشهداء حسب المحافظات العراقية</p>
                                                                <div className='card-info'>
                                                                    <p className='card-info-text'>
                                                                        مجزرة سبايكر الاليمة في تكريت، التي وقعت في يونيو 2014، كانت واحدة من أسوأ المجازر التي ارتكبتها العصابات التكفيرية الصدامية داخل تكريت. وتمثل جريمة حرب وجريمة ضد الإنسانية، حيث تم قتل مئات الشباب العراقيين العزل الذين كانوا طلاباً في قاعدة سبايكر في محافظة صلاح الدين لذلك يعتبر جمع بيانات شهداء مجزرة سبايكر ضرورياً للغاية. فهذه البيانات يمكن أن تساعد على توثيق الأدلة والمعلومات عن هذه المجزرة ومساعدة أسر الضحايا في الحصول على الإغاثة والعدالة. ومن خلال جمع البيانات، يمكن تحديد عدد الضحايا وأسمائهم وأماكن الدفن والتعرف على مصير الأشخاص الذين لا يزالون في عداد المفقودين.
                                                                        وبالإضافة إلى ذلك، فإن جمع بيانات شهداء مجزرة سبايكر يمكن أن يساعد في إبراز أهمية حماية حقوق الإنسان وتعزيز العدالة والمساواة في المجتمع العراقي، والعمل على منع وقوع مثل هذه المجازر..
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <Button href='/spyker/list/' icon={<BsDatabase size={20} />} className="data-but" style={{ zIndex: 10 }}>الولوج لقاعدة بيانات الشهداء </Button>
                                                                </div>

                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className='iraq-map'>
                                                                    <SVGMap
                                                                        className='svg-map svg-iraq'
                                                                        map={SvgIraq}
                                                                        onLocationMouseOver={handleLocationMouseOver}
                                                                        onLocationMouseOut={handleLocationMouseOut}
                                                                        onLocationMouseMove={handleLocationMouseMove}
                                                                        onLocationClick={handleLocationMouseClick}
                                                                    />
                                                                    <div className="map-tooltip" style={tooltipStyle}>
                                                                        {pointedLocation}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-11">
                                                <p className='home-dead-title' style={{ width: '50%' }}>السيد السيستاني عن شهداء سبايكر</p>
                                                <p className='home-dead-disc'>عندما يمر ذكر شهداء الحشد الشعبي استذكر تدافع أصحاب الإمام الحسين عليه السلام الى المنية...</p>
                                            </div>
                                            <div className="col-xl-11">
                                                <div className='home-dead-card'>
                                                    <div className="container-fluid">
                                                        <div className="row justify-content-center align-items-center g-2">
                                                            <div className="col-xl-12 ">
                                                                <p className='card-title'>الشهداء</p>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <Statistic title="" value={deadCount} prefix={<MdMilitaryTech size={50} color='#23b5ab' />} formatter={formatter} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12">
                                                                <div className='card-info'>
                                                                    <p className='card-info-text'>للشهادة والشهيد منزلة ومقام عظيم عند الله تعالى ، والشهادة كرامة وشرف يخص بها الله تعالى اوليائه وأحباءه من دون سائر الخلق ، ومما خص به الله تعالى الشهداء وحباهم به من عظيم آياته هو قوله عز من قائل ﴿ وَلَا تَحْسَبَنَّ الَّذِينَ قُتِلُوا فِي سَبِيلِ اللَّهِ أَمْوَاتًا بَلْ أَحْيَاءٌ عِنْدَ رَبِّهِمْ يُرْزَقُونَ * فَرِحِينَ بِمَا آَتَاهُمُ اللَّهُ مِنْ فَضْلِهِ وَيَسْتَبْشِرُونَ بِالَّذِينَ لَمْ يَلْحَقُوا بِهِمْ مِنْ خَلْفِهِمْ أَلَّا خَوْفٌ عَلَيْهِمْ وَلَا هُمْ يَحْزَنُونَ * يَسْتَبْشِرُونَ بِنِعْمَةٍ مِنَ اللَّهِ وَفَضْلٍ وَأَنَّ اللَّهَ لَا يُضِيعُ أَجْرَ الْمُؤْمِنِينَ﴾ ، ولا يخفى على القراء الكرام ما تضمنته الآية الكريمة أعلاه من فضائل الشهداء وعلو مكانتهم ومقدار كرامتهم وعظيم منزلتهم بخلودهم وفوزهم بالحياة الأبدية .</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-11">
                                                <p className='home-dead-title' style={{ width: '50%' }}>السيد السيستاني عن شهداء سبايكر</p>
                                                <p className='home-dead-disc'>عندما يمر ذكر شهداء الحشد الشعبي استذكر تدافع أصحاب الإمام الحسين عليه السلام الى المنية...</p>
                                            </div>
                                            <div className="col-xl-11">
                                                <div className='home-dead-card'>
                                                    <div className="container-fluid">
                                                        <div className="row justify-content-start align-items-start g-2">
                                                            <p className='card-title'>نبذة من الشهداء </p>
                                                            {dead.map((item, index) => {
                                                                return (
                                                                    <div key={item.id} className="col-xl-2 col-md-3 col-md-4 col-sm-6 col-6">
                                                                        <a key={item.id} href={`/spyker/list/${item.id}/`} className="d-flex flex-column justify-content-center align-items-center p-1">
                                                                            <Avatar size={75} src={item.image !== null ? item.image : defaultProfile2} />
                                                                            <div>
                                                                                <p className='card-man-info-title'>{item.first_name} {item.second_name} {item.grandfather_name} {item.last_name}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>شهداء {location}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-center'>
                        اكثر من {count} شهيد من شهداء سبايكر من محافظة {location}
                    </p>
                    <p>ابرزهم</p>
                    <div className="col-xl-12 ">
                        <Swiper
                            dir="rtl"
                            slidesPerView={4}
                            spaceBetween={4}
                            slidesPerGroup={4}
                            loop={true}
                        >
                            {deadCity.map((item, index) => {
                                return (
                                    <SwiperSlide key={item.id}>
                                        <a href={`/spyker/list/${item.id}/`} className="d-flex flex-column justify-content-center align-items-center">
                                            <Avatar size={75} src={item.image !== null ? item.image : defaultProfile2} />
                                            <div>
                                                <p className='card-man-info-title'>{item.first_name} {item.second_name} {item.grandfather_name} {item.last_name}</p>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
