import React from 'react'
import Logo from '../style/svg/Logo'
import { BsFacebook, BsInstagram, BsTelegram, BsTwitterX, BsYoutube } from "react-icons/bs";
export default function Footer() {
    return (
        <footer id='footer' className='main-footer'>
            <div className="container-fluid p-0 m-0">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-12 footer-header">
                        <div className='logo-footer'>
                            <div className='logos'>
                                <Logo
                                    className={'sr'}
                                    width="5em"
                                    height="5em"
                                />
                            </div>
                        </div>
                        <div className='list'>
                            <div className='count'>
                                <p className='number'>2k</p>
                                <p className='info'>التحميلات</p>
                            </div>
                            <div className='count'>
                                <p className='number'>10k</p>
                                <p className='info'>الزيارات للموقع</p>
                            </div>
                            <div className='count'>
                                <p className='number'>5k</p>
                                <p className='info'>الملفات</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 footer-map">
                        <div className='site-map'>
                            <a href='/'>الرئيسية</a>
                            <a href='/contact_us/'>اتصل بنا</a>
                            <a href='/applications/'> تطبيقات</a>
                            <a href='/news/'>آخر الأخبار</a>
                            <a href='/speak_up/'>قم بالإبلاغ</a>
                            <a href='/terms_and_conditions/'> الشروط والأحكام</a>
                            <a href='/privacy_policy/'>سياسة الخصوصية</a>
                        </div>
                        <div className='icons-navbar'>
                            <a href='/#' className='facebook'><BsFacebook/></a>
                            <a href='/#' className='instagram'><BsInstagram/></a>
                            <a href='/#' className='telegram'><BsTelegram/></a>
                            <a href='/#' className='twitter'><BsTwitterX/></a>
                            <a href='/#' className='youtube'><BsYoutube/></a>
                        </div>
                        <div className='copy'>
                            <p>جميع الحقوق محفوضة لبوابة جمال الرقمية &copy; 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
