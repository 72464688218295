import React from 'react'

export function CategoryCard(props) {
    const { name, image } = props;
    return (
        <div className='card-catogary'>
            <img alt="..." className='img-catogary' src={image !== null ? image : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/mokhadramoun.jpg'} />
            <div className='catogary-info'>
                <p className='title-catogary f-bold'>{name}</p>
            </div>
        </div>
    )
}
