import { Avatar, Rate } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { IoIosBook, IoMdDownload, IoMdHeart } from 'react-icons/io'
import { CgQuote } from 'react-icons/cg'
import { useParams } from 'react-router-dom'
import { SwiperSlide, Swiper } from 'swiper/react'
import { downloadFile, navPage } from '../../funcAndVar/func'
import { Navigation } from 'swiper'
import Loading from '../../components/Loading';
import { defaultProfile } from '../../funcAndVar/var'
export default function BookItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [book, setBook] = useState('')
    const [author, setAuthor] = useState('')
    const [books, setBooks] = useState([])
    const [index, setIndex] = useState(0)
    const [defaultImage, setDefaultImage] = useState('')

    const active = (image, i) => {
        setIndex(i)
        setDefaultImage(image);
    }
    useEffect(() => {
        document.title = 'مكتبة جمال'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/${id}/`)
                .then(res => {
                    document.title = res.data.title
                    setBook(res.data)
                    setAuthor(res.data.author)
                    setDefaultImage(res.data.cover)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/`)
                .then(res => {
                    setBooks(res.data.results)
                    if (res.status === 200) setLoading(false)
                })

        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-12">
                        <div className='book-item-header'>
                            <div className="container-fluid">
                                <div className="row justify-content-center align-items-center g-2">
                                    <div className="col-lg-2 col-12">
                                        <div className='book-item-header-list '>
                                            {[...new Array(4)].map((item, i) => {
                                                return (
                                                    <div
                                                        key={item}
                                                        onClick={() => active(book.cover, i)}
                                                        className='book-item-header-images'
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={book.cover}
                                                            className={index === i ? 'active-cover' : ''}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    
                                    </div>
                                    <div className="col-lg-10">
                                        <div className='book-item-header-book'>
                                            <img alt="..." src={defaultImage} className='book-item-header-book-image' />
                                            <div className='book-item-header-book-info'>
                                                <div className='book-item-header-book-info-names'>
                                                    <h3 className='f-bold text-center'>{book.title}</h3>
                                                    <p className='book-item-header-book-info-author'>{author !== null ? `${author.first_name} ${author.second_name} ${author.last_name}` : ''}</p>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <p className='book-item-header-book-info-type'>{book.type === 0 ? 'pdf' : 'docx'}</p>
                                                        <Rate disabled defaultValue={4} />
                                                        <p className='book-item-header-book-info-read my-0 py-0'>150 قراءة</p>
                                                    </div>
                                                    <p className='book-item-header-book-info-summary'>{book.summary} </p>
                                                </div>
                                                <div className='book-item-header-book-info-buts'>
                                                    {book.type === 0 ?
                                                        <Button href={`/library/book/id/${id}/name/${book.title}`} variant='' className='book-item-header-book-info-buts-read'>قراءة</Button>
                                                        :
                                                        <Button href={book.file} variant='' className='book-item-header-book-info-buts-read'>تنزيل</Button>}
                                                    {book.type === 0 &&
                                                        <Button href={book.file} variant='' className='book-item-header-book-info-buts-download'><IoMdDownload size={20} color='#fff' /></Button>
                                                    }
                                                    <Button variant='' onClick={() => console.log('ok')}><IoMdHeart size={25} color='#f00' /></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='book-item-swiper'>
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="mb-3 book-item-tab"
                            >
                                <Tab eventKey="home" title="حول المؤلف">
                                    <div className='author-info'>
                                        <div>
                                            <Avatar shape='square' src={author.image ? author.image : defaultProfile} size={150} />
                                            <p className='pt-3 fs-18 f-bold text-center'>{author.first_name} {author.second_name} {author.last_name}</p>
                                        </div>

                                        <p className='p-3 fs-16'>{author.description} </p>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="اقتباسات من الكتاب">
                                    <div>
                                        <Swiper
                                            dir="rtl"
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            slidesPerGroup={1}
                                            breakpoints={{

                                                768: {
                                                    slidesPerView: 2,
                                                    slidesPerGroup: 2
                                                },
                                                1024: {
                                                    slidesPerView: 3,
                                                    slidesPerGroup: 3
                                                },
                                                1300: {
                                                    slidesPerView: 4,
                                                    slidesPerGroup: 4
                                                },
                                            }}
                                            loop={true}
                                            modules={[Navigation]}
                                            navigation={true}
                                            loopFillGroupWithBlank={true}
                                            className='pt-5 pb-5'
                                        >

                                            {books.map((item) => {
                                                return (
                                                    <SwiperSlide key={item.id}>
                                                        <div className='book-item-swiper soon-item pt-5'>
                                                            <p
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: -20,
                                                                    left: 0
                                                                }}
                                                            >
                                                                <CgQuote size={75} />
                                                            </p>
                                                            <p style={{ fontSize: 14 }}>كتاب "المعرفة" للكاتب نجيب محفوظ هو كتاب ملهم ومثير للتفكير يستحق القراءة، يعرض بطريقة مثيرة للاهتمام أهمية البحث عن المعرفة والتفكير النقدي والقراءة في الحياة اليومية.</p>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                    </div>
                                </Tab>
                                <Tab eventKey="contact" title="اراء القراء">
                                    <div>
                                        <Swiper
                                            dir="rtl"
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            slidesPerGroup={1}
                                            breakpoints={{

                                                768: {
                                                    slidesPerView: 2,
                                                    slidesPerGroup: 2
                                                },
                                                1024: {
                                                    slidesPerView: 3,
                                                    slidesPerGroup: 3
                                                },
                                                1300: {
                                                    slidesPerView: 4,
                                                    slidesPerGroup: 4
                                                },
                                            }}
                                            loop={true}
                                            modules={[Navigation]}
                                            navigation={true}
                                            loopFillGroupWithBlank={true}
                                            className='pt-5 pb-5'
                                        >

                                            {books.map((item) => {
                                                return (
                                                    <SwiperSlide key={item.id}>
                                                        <div className='book-item-swiper soon-item'>
                                                            <div className='d-flex justify-content-start align-items-start'>
                                                                <Avatar src={author.image !== null ? author.image : defaultProfile} size={30} />
                                                                <p>{author.first_name} {author.second_name} {author.last_name}</p>
                                                            </div>
                                                            <p style={{ fontSize: 14 }}>{book.summary}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='book-item-swiper'>
                            <p className='f-bold'><IoIosBook size={20} color='#23b5ab' /> كتب ذات صلة </p>
                            <Swiper
                                dir="rtl"
                                slidesPerView={1}
                                spaceBetween={5}
                                breakpoints={{

                                    768: {
                                        slidesPerView: 2,
                                        slidesPerGroup: 2
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                        slidesPerGroup: 4
                                    },
                                }}
                                loop={true}
                                modules={[Navigation]}
                                navigation={true}
                                loopFillGroupWithBlank={true}
                            >
                                {books.map((item) => {
                                    return (
                                        <SwiperSlide key={item.id}>
                                            <div className='ibrary-home-swiper-item'>
                                                <div className='ibrary-home-card'>
                                                    <img alt="..." src={item.cover !== null ? item.cover : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/bookcover.jpg'} className='ibrary-home-card-image' />
                                                    <div className='ibrary-home-card-info'>
                                                        <p className='ibrary-home-card-title'>{item.title}</p>
                                                        <p className='ibrary-home-card-author'>{item.author.first_name} {item.author.second_name} {item.author.last_name}</p>
                                                        <Button variant='' href={`/library/book/${item.id}/`} className='ibrary-home-card-but'>قراءة</Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='ibrary-home-info'>الوصف</p>
                                                    <p className='ibrary-home-summary'>
                                                        {String(item.summary).slice(0, 75)} ...
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
