import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FiDownload, FiMoreVertical, FiShare } from "react-icons/fi";
import Empty from '../Empty';
import { downloadFile } from '../../funcAndVar/func';
import Share from '../Share';
import axios from 'axios';
import Loading from '../Loading';
import { AuthorCard } from '../cards';
import { Button } from 'antd';

export default function Gallery({ path, url, effect, loading, setLoading }) {
    const [images, setImages] = useState([])

    // pagnation
    const observer = useRef();
    const [currPage, setCurrPage] = useState(1)
    const [hasMore, setHasMore] = useState(true);
    const [more, setMore] = useState(1);
    const [Skeleton, setSkeleton] = useState(false);
    // end pagnation
    useEffect(() => {
        async function getData() {
            await axios.get(`${url}&page=${currPage}`)
                .then(res => {
                    const count = res.data.count;
                    if (count === images.length || count < 100) {
                        setHasMore(false)
                    }
                    setImages([...images, ...res.data.results]);
                    if (res.status === 200) setLoading(false)

                }).catch(err => {
                    console.log(err.response.status)
                    const status = err.response.status;
                    if (status === 404) {
                        setHasMore(false)
                    }
                })
        }
        getData()
    }, [effect, currPage])

    const lastelemet = useCallback(node => {
        setSkeleton(true)
        if (loading) return
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(e => {
            if (e[0].isIntersecting) {
                console.log('showing')
                setTimeout(() => {
                    if (hasMore) {
                        setCurrPage(currPage + 1)
                        setMore(more + 1)
                    }
                    setSkeleton(false)
                }, 2000)
            }
        })
        if (node) {
            observer.current.observe(node)
        }
    }, [loading, hasMore, more])
    const Card = (props) => {
        return (
            <>
                <img alt="..."
                    src={props.image}
                    style={{
                        objectFit: 'cover',
                        borderRadius: 15,
                        width: '100%',
                        height: '100%'
                    }}
                    title={props.name}
                />
                <div className='gallery-images-cover'>
                    <div>
                        <Button variant='' icon={<FiDownload />} title='تحميل' onClick={() => downloadFile(props.image, props.name)} className='gallery-images-but'>
                          
                        </Button>
                    </div>
                    <a style={{ flex: 1, height: '100%', width: '100%' }} title={props.name} href={`${path}${props.id}/`}></a>
                    <div className='gallery-images-more'>
                        <div>
                            <Dropdown >
                                <Dropdown.Toggle icon={<FiMoreVertical />} className='gallery-images-dropdown' as={Button} variant="" id="dropdown-basic"/>
                                <Dropdown.Menu>
                                    <Dropdown.Item href={`${path}${props.id}/`}>معاينة</Dropdown.Item>
                                    <Dropdown.Item href='/#' onClick={() => downloadFile(props.image)}>تنزيل</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            
                        </div>
                        <div>
                            <Share url={props.image} type='file'>
                                <Button variant='' title='مشاركة' className='gallery-images-dropdown'>
                                    <FiShare />
                                </Button>
                            </Share>
                        </div>
                       
                    </div>
                </div>
            </>
        )
    }
    if (loading) {
        return <Loading />
    }
    if (images.length === 0) return <Empty />
    return (
        <div className='gallery-layout'>
            {images.map((item, index) => {
                const dim = item.dimensions
                const dims = dim.split('x')
                const width = dims[0]
                const rundom = Math.floor(Number(width) / 150)
                if (images.length === index + 1) {
                    return (
                        <div
                            ref={lastelemet}
                            className='gallery-images'
                            key={item.id}
                            style={{
                                gridRowEnd: 'span ' + (rundom + 15),
                            }} >
                            <Card
                                id={item.id}
                                name={item.name}
                                image={item.image}
                                rundom={rundom}
                            />
                        </div>
                    )
                }
                return (
                    <div
                        className='gallery-images'
                        key={item.id}
                        style={{
                            gridRowEnd: 'span ' + (rundom + 15),
                        }} >
                        <Card
                            id={item.id}
                            name={item.name}
                            image={item.image}
                            rundom={rundom}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export function GalleryAlpum({ path, loading, setLoading }) {
    const [album, setAlbum] = useState([])

    // pagnation
    const observer = useRef();
    const [currPage, setCurrPage] = useState(1)
    const [hasMore, setHasMore] = useState(true);
    const [more, setMore] = useState(1);
    const [Skeleton, setSkeleton] = useState(false);
    // end pagnation
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=5&page_size=100&page=${currPage}`)
                .then(res => {
                    const count = res.data.count;
                    if (count === album.length) {
                        setHasMore(false)
                    }
                    setAlbum([...album, ...res.data.results]);
                    if (res.status === 200) setLoading(false)

                }).catch(err => {
                    console.log(err.response.status)
                    const status = err.response.status;
                    if (status === 404) {
                        setHasMore(false)
                    }
                })

        }
        getData()
    }, [currPage])
    const lastelemet = useCallback(node => {
        setSkeleton(true)
        if (loading) return
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(e => {
            if (e[0].isIntersecting) {
                console.log('showing')
                setTimeout(() => {
                    if (hasMore) {
                        setCurrPage(currPage + 1)
                        setMore(more + 1)
                    }
                    setSkeleton(false)
                }, 2000)
            }
        })
        if (node) {
            observer.current.observe(node)
        }
    }, [loading, hasMore, more])

    const Card = (props) => {
        return (
            <>
                <img alt="..."
                    src={props.image}
                    style={{
                        objectFit: 'cover',
                        borderRadius: 15,
                        width: '100%',
                        height: '100%'
                    }}
                />
                <div className='gallery-images-card'>
                    <a style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'flex-end', color: 'white', fontSize: 18 }} href={`${path}${props.id}/`}><p className='p-2'>{props.name}</p></a>
                </div>
            </>
        )
    }
    if (loading) {
        return <Loading />
    }
    if (album.length === 0) return <Empty />
    return (
        <div className='gallery-layout'>
            {album.map((item, index) => {
                if (album.length === index + 1) {
                    return (
                        <div
                            ref={lastelemet}
                            className='gallery-images'
                            key={item.id}
                            style={{
                                gridRowEnd: 'span 25',
                            }} >
                            <Card
                                id={item.id}
                                name={item.name}
                                image={item.image}
                            />
                        </div>
                    )
                }
                return (
                    <>
                        <div
                            className='gallery-images'
                            key={item.id}
                            style={{
                                gridRowEnd: 'span 25',
                            }}>
                            <Card
                                id={item.id}
                                name={item.name}
                                image={item.image}
                            />
                        </div>
                    </>
                )
            })}
        </div>
    )
}
export function GalleryPhotographer({ path, loading, setLoading }) {
    const [photographers, setPhotographers] = useState([])

    // pagnation
    const observer = useRef();
    const [currPage, setCurrPage] = useState(1)
    const [hasMore, setHasMore] = useState(true);
    const [more, setMore] = useState(1);
    const [Skeleton, setSkeleton] = useState(false);
    // end pagnation
    useEffect(() => {
        async function getData() {
            await axios.get(`/gallery/api/v1/photographers/?page_size=100&page=${currPage}`)
                .then(res => {
                    const count = res.data.count;
                    if (count === photographers.length) {
                        setHasMore(false)
                    }
                    setPhotographers([...photographers, ...res.data.results]);
                    if (res.status === 200) setLoading(false)

                }).catch(err => {
                    const status = err.response.status;
                    if (status === 404) {
                        setHasMore(false)
                    }
                })

        }
        getData()
    }, [currPage])
    const lastelemet = useCallback(node => {
        setSkeleton(true)
        if (loading) return
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(e => {
            if (e[0].isIntersecting) {
                console.log('showing')
                setTimeout(() => {
                    if (hasMore) {
                        setCurrPage(currPage + 1)
                        setMore(more + 1)
                    }
                    setSkeleton(false)
                }, 2000)
            }
        })
        if (node) {
            observer.current.observe(node)
        }
    }, [loading, hasMore, more])

    const Card = (props) => {
        return (
            <>
                <img alt="..."
                    src={props.image}
                    style={{
                        objectFit: 'cover',
                        borderRadius: 15,
                        width: '100%',
                        height: '100%'
                    }}
                />
                <div className='gallery-images-card'>
                    <a style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'flex-end', color: 'white', fontSize: 18 }} href={`${path}${props.id}/`}><p className='p-2'>{props.name}</p></a>
                </div>
            </>
        )
    }
    if (loading) {
        return <Loading />
    }
    if (photographers.length === 0) return <Empty />
    return (
        <div className='container-fluid'>
            <div
                class="row justify-content-start align-items-start g-2"
            >
                {photographers.map((item, index) => {
                    if (photographers.length === index + 1) {
                        return (
                            <div
                                ref={lastelemet}
                                key={item.id}
                                className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6"
                            >
                                <AuthorCard
                                    id={item.id}
                                    title='مصور'
                                    path={`/gallery/photographer/${item.id}/`}
                                    name={`${item.first_name} ${item.second_name} ${item.last_name}`}
                                    image={item.image}
                                    infoTitleOne='صوره'
                                    infoTitleTow='متابع'
                                    infoCountOne={50}
                                    infoCountTow={50}
                                />
                            </div>
                        )
                    }
                    return (
                        <>
                            <div
                                key={item.id}
                                className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6"
                            >
                                <AuthorCard
                                    id={item.id}
                                    title='مصور'
                                    path={`/gallery/photographer/${item.id}/`}
                                    name={`${item.first_name} ${item.second_name} ${item.last_name}`}
                                    image={item.image}
                                    infoTitleOne='صوره'
                                    infoTitleTow='متابع'
                                    infoCountOne={50}
                                    infoCountTow={50}
                                />
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}