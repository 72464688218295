import { mediaURL, melodyURL } from "../config"

export const navDead = [
    {
        name: 'شهدائنا',
        route: '/dead/'
    },
    {
        name: 'سبايكر',
        route: '/spyker/'
    },
    {
        name: 'اثار الشهداء',
        route: '/remains/'
    },
    {
        name: 'اقتباسات الشهداء',
        route: '/quote/'
    },
    {
        name: 'خواطر الشهداء',
        route: '/thoughts/'
    },
]
export const navOsise = [
    {
        name: 'الاخبار',
        route: '/news/'
    },
    {
        name: 'التدوينات',
        route: '/article/'
    },
]
export const navLit = [
    {
        name: 'القصائد',
        route: '/poetry/'
    },
    {
        name: 'القصص',
        route: '/storys/'
    },
]
export const navMid = [
    {
        name: 'معرض جمال',
        route: '/gallery/'
    },
    {
        name: 'جمال ميديا',
        route: mediaURL
    },
    {
        name: 'جمال ميلوديا',
        route: melodyURL
    },
]