import { Avatar, Button } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { defaultCover, defaultProfile } from '../../funcAndVar/var';
import { useCookies } from 'react-cookie';
import PaginationPage from '../../components/Pagination';
import PoemCard from '../../components/poems/PoemCard';
import { SlUserFollowing } from 'react-icons/sl';
import Empty from '../../components/Empty';

export default function AuthorItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams();
    const [item, setItem] = useState('');
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { author_item_poems } = page;
    useEffect(() => {
        document.title = 'القصائد'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {

            await axios.get(`/shared/api/v1/authors/${id}/`)
                .then(res => {
                    setItem({ ...res.data })
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {

            await axios.get(`/literature/api/v1/poems/?author=${id}&page=${author_item_poems ? author_item_poems : 1}`)
                .then(res => {
                    setCount(res.data.count);
                    setData(res.data.results)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('author_item_poems', 1)
                    }
                })
        }
        getData()
    }, [author_item_poems])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page'>
            <div className="container-fluid p-0 m-0 position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className='col-12  position-absolute top-0 start-0'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/poetry/`}>الشعر</a>
                            <a className='header-link-item' href='/poetry/authors/'>الشعراء</a>
                            <a className='header-link-item'>{item.first_name} {item.second_name} {item.last_name}</a>
                        </div>
                    </div>
                    <div className="col-12 p-0 m-0">
                        <img alt="..." src={item.cover !== null ? item.cover : defaultCover} className='literature-page-cover rounded-3' />
                    </div>
                    <div className="col-12 p-0 m-0">
                        <div className='literature-page-header'>
                            <Avatar
                                size={{ xs: 100, sm: 100, md: 125, lg: 150, xl: 150, xxl: 150 }}
                                src={item.image !== null ? item.image : defaultProfile}
                                className='header-avatar'
                            />
                            <div className='header-about'>
                                <p className='header-about-name f-bold'>{item.first_name} {item.second_name} {item.last_name}</p>
                                <Button variant='' icon={<SlUserFollowing size={16} />} className='but-flow'>متابعة</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                        className="mb-5 literature-page-tabs"
                                    >
                                        <Tab eventKey="home" title="القصائد">
                                            <div className="container-fluid ">
                                                <div className="row justify-content-center align-items-start g-2">
                                                    {count===0 && <Empty />}
                                                    {data.map(data => {
                                                        return <PoemCard data={data} />
                                                    })}
                                                    <div className="col-12">
                                                        <PaginationPage
                                                            defaultCurrent={!author_item_poems ? 1 : Number(author_item_poems)}
                                                            total={count}
                                                            defaultPageSize={10}
                                                            onChange={(e) => setPage('author_item_poems', e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="contact" title="حول الشاعر">
                                            <div class="container-fluid">
                                                <div class="row justify-content-between align-items-start g-2">
                                                    <div class="col-xl-6">
                                                        <div className='mb-5'>
                                                            <p className='literature-page-info'>{item.description}</p>
                                                            <div>
                                                                <p className='literature-page-info-links'>التواصل الأجتماعي</p>
                                                                <div className='icons-navbar'>
                                                                    <a href='#'><i className="fa-brands fa-facebook-f"></i></a>
                                                                    <a href='#'><i className="fa-brands fa-instagram"></i></a>
                                                                    <a href='#'><i className="fa-brands fa-telegram"></i></a>
                                                                    <a href='#'><i className="fa-brands fa-twitter"></i></a>
                                                                    <a href='#'><i className="fa-brands fa-youtube"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4">
                                                        <div className='mb-5'>
                                                            <p className='literature-page-info'>الاحصائيات</p>
                                                            <div>
                                                                <p className='literature-page-info-links'>المتابعين</p>
                                                                <div className='icons-navbar'>
                                                                    <p>50</p>
                                                                </div>
                                                                <p className='literature-page-info-links'>القصائد</p>
                                                                <div className='icons-navbar'>
                                                                    <p>100</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
