import React from 'react'
import Logo from '../style/svg/Logo'

export default function Loading() {
    return (
        <div className='loading'>
            <div className='loading-image'>
                <Logo
                    className={'fr'}
                    width="25em"
                    height="10em"
                />
                <p className='fs-1'>بوابة جمال الرقمية</p>
            </div>
        </div>
    )
}
