import React from 'react'
import { MdQueryBuilder } from 'react-icons/md'
import Share from '../Share'
import { IoShareOutline } from 'react-icons/io5'

export default function PoemCard({ data }) {
  const text = String(data.poem_text).split('\n')
  return (
    <div key={data.id} className="col-xl-10 col-12">
      <div className='poem-card'>
        <div className='title-content'>
          <a href={`/poetry/poem/${data.id}/`} className='title-1 f-bold'>{data.poem_name}</a>
          <p className='title-2'><MdQueryBuilder size={14} /> {String(data.created).slice(11, 19)}   {String(data.created).slice(0, 10)}</p>
        </div>
        <a href={`/poetry/poem/${data.id}/`}>
          {text.slice(0, 5).map(text => {
            return (
              <p key={text}>{text}</p>
            )
          })}

        </a>
        <div className='footer-content'>
          <div className='footer-count'>
            <p>اعجاب 10</p>
            <p>المشاهدات 100</p>
          </div>
          <p className='footer-but'>
            <Share url={`/poetry/poem/${data.id}/`}>
              <IoShareOutline size={22} />
            </Share>
          </p>
        </div>
      </div>

    </div>
  )
}
