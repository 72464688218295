
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import { useCookies } from 'react-cookie';
import PaginationPage from '../../components/Pagination';
import { AuthorCard } from '../../components/cards';

export default function StoryAuthorList() {
    const [loading, setLoading] = useState(true)
    const [authors, setAuthors] = useState([]);
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { story_author } = page;
    useEffect(() => {
        document.title = 'القُصَّاص'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/authors/?type=5&page_size=60&page=${story_author ? story_author : 1}`)
                .then(res => {
                    setCount(res.data.count);
                    setAuthors(res.data.results)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('story_author', 1)
                    }
                })
        }
        getData()
    }, [story_author])

    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page mt-3'>
            <div className="container-fluid  position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/storys/`}>القصص</a>
                            <a className='header-link-item'>القُصَّاص</a>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <div className="container-fluid">
                            <div className="row justify-content-start align-items-center g-2">
                                <div className="col-12 border-bottom">
                                    <h4 className='f-bold'>القُصَّاص</h4>
                                </div>
                                {authors.map((item) => {
                                    const name = `${item.first_name} ${item.second_name} ${item.last_name}`
                                    return (
                                        <div key={item.id} className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6">
                                            <AuthorCard
                                                id={item.id}
                                                title='قاص'
                                                path={`/storys/authors/id/${item.id}/name/${name}`}
                                                name={name}
                                                image={item.image}
                                                infoTitleOne='قصة'
                                                infoTitleTow='متابع'
                                                infoCountOne={50}
                                                infoCountTow={50}
                                            />
                                        </div>
                                    )
                                })}
                                <div className="col-12">
                                    <PaginationPage
                                        defaultCurrent={!story_author ? 1 : Number(story_author)}
                                        total={count}
                                        defaultPageSize={60}
                                        onChange={(e) => setPage('story_author', e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
