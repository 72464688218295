import { Input, Button } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { LuLayoutList } from 'react-icons/lu';

export default function PrayAndVisits() {
    const [loading, setLoading] = useState(true)
    const [pray, setPray] = useState([]);
    const [visits, setVisits] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [page2, setPage2] = useState(1);
    const [count2, setCount2] = useState(0);
    useEffect(() => {
        document.title = 'الأدعية والزيارات'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/praying/api/v1/prays/?type=1&page_size=20&page=${page}`)
                .then(res => {
                    setCount(res.data.count)
                    if (page === 1) {
                        setPray(res.data.results);
                    } else {
                        setPray([...pray, ...res.data.results]);
                    }
                })
            await axios.get(`/praying/api/v1/prays/?type=2&page_size=20&page=${page2}`)
                .then(res => {
                    setCount2(res.data.count)
                    if (page2 === 1) {
                        setVisits(res.data.results);
                    } else {
                        setVisits([...pray, ...res.data.results]);
                    }
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [page, page2])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='oasis-dinia-page'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>البحث</h5>
                                            <Input type='text' placeholder='بحث' suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>الأقسام</h5>
                                            <a href='/oasis_dinia/quran/'>القرآن الكريم</a>
                                            <a href='/oasis_dinia/pray_and_visits/'>الادعية والزيارات</a>
                                            <a href='/oasis_dinia/sahifa_and_mafatih/'>أنوار العاشقين</a>
                                            <a href='/oasis_dinia/questions/'>الأستفتاءات</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8 col-lg-8 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='pray-list'>
                                        <p className='name-list f-bold'><img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/icon_8.png'} width={30} /> الأدعية</p>
                                        <div className="container">
                                            <div className="row justify-content-center align-items-center g-2">

                                                {pray.map(item => {
                                                    return (
                                                        <div key={item.id} className='col-xl-6 col-12 pray-item'>
                                                            <a className='list-item' href={`/oasis_dinia/pray/${item.id}/`} title={item.name}>
                                                                <p className='pray-name'>{item.name}</p>
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                                {count > 20 && count !== pray.length ?
                                                    <div className='col-12 more-pagination'>
                                                        <Button variant='' icon={<LuLayoutList />} onClick={() => setPage(page + 1)}>المزيد</Button>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {count2 !== 0 &&
                                    <div className="col-12">
                                        <div className='pray-list'>
                                            <p className='name-list f-bold'><img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/icon_10.png'} width={30} /> الزيارات</p>
                                            <div className="container">
                                                <div className="row justify-content-center align-items-center g-2">
                                                    {visits.map(item => {
                                                        return (
                                                            <div key={item.id} className='col-xl-6 col-12 pray-item'>
                                                                <a className='list-item' href={`/oasis_dinia/pray/${item.id}/`} title={item.name}>
                                                                    <p className='pray-name'>{item.name}</p>
                                                                </a>
                                                            </div>
                                                        )
                                                    })}
                                                    {count2 > 20 && count2 !== visits.length ?
                                                        <div className='col-12 more-pagination'>
                                                            <Button variant='' icon={<LuLayoutList />} onClick={() => setPage2(page2 + 1)}>المزيد</Button>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
