

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import { useCookies } from 'react-cookie';
import PaginationPage from '../../components/Pagination';
import { CategoryCard } from '../../components/cards';

export default function StoryCatogaryList() {
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { story_category } = page;
    useEffect(() => {
        document.title = 'القصائد'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=8&page_size=60&page=${story_category ? story_category : 1}`)
                .then(res => {
                    setCategory(res.data.results)
                    setCount(res.data.count);
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('story_category', 1)
                    }
                })

        }
        getData()
    }, [story_category])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page mt-3'>
            <div className="container-fluid  position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/storys/`}>القصص</a>
                            <a className='header-link-item'>الأقسام</a>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <div className="container-fluid">
                            <div className="row justify-content-start align-items-center g-2">
                                <div className="col-12 border-bottom">
                                    <h4 className='f-bold'>الأقسام</h4>
                                </div>
                                {category.map(item => {
                                    return (
                                        <a key={item.id} href={`/storys/categorys/id/${item.id}/name/${item.name}`} className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <CategoryCard
                                                name={item.name}
                                                image={item.image}
                                            />
                                        </a>
                                    )
                                })}
                                <div className="col-12">
                                    <PaginationPage
                                        defaultCurrent={!story_category ? 1 : Number(story_category)}
                                        total={count}
                                        defaultPageSize={60}
                                        onChange={(e) => setPage('story_category', e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
