import { Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import { defaultProfile } from '../../funcAndVar/var';

export default function QuestionsAuthor() {
    const [loading, setLoading] = useState(true)
    const [author, setAuthor] = useState([]);
    useEffect(() => {
        document.title = 'المراجع'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/authors/?type=3&page_size=50`)
                .then(res => {
                    setAuthor(res.data.results);
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='oasis-dinia-page'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>البحث</h5>
                                            <Input type='text' placeholder='بحث' suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>الأقسام</h5>
                                            <a href='/oasis_dinia/quran/'>القرآن الكريم</a>
                                            <a href='/oasis_dinia/pray_and_visits/'>الادعية والزيارات</a>
                                            <a href='/oasis_dinia/sahifa_and_mafatih/'>أنوار العاشقين</a>
                                            <a href='/oasis_dinia/questions/author/'>الأستفتاءات</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8 col-lg-8 col-12">
                        <h3 className='f-bold'>المراجع</h3>
                        <div className="container-fluid">
                            <div className="row justify-content-start align-items-start g-2">
                                {author.map(item => {
                                    return (
                                        <div key={item.id} className="col-xxl-4">
                                            <a href={`/oasis_dinia/questions/author/${item.id}/`} className='questions-author'>
                                                <div className='image-content'>
                                                    <img alt="..." className='cover-author' src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/cover_image.png'} />
                                                    <img alt="..." className='img-author' src={item.image !== null ? item.image : defaultProfile} />
                                                </div>
                                                <p className='name-author f-bold'>{item.first_name} {item.second_name} {item.last_name}</p>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
