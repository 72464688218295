import { Avatar } from 'antd';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
export default function SoundHome({ data }) {
  const [url, setUrl] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)
  const onPlay = (url, index) => {
    setUrl(url);
    setActiveIndex(index);
  }
  return (
    <div className='sound-home'>
      <div className='sound-home-content'>
        <div className='sound-home-player'>
          <AudioPlayer
            src={url ? url : (data[0] ? data[0].file : '')}
            onPlay={e => console.log("onPlay")}
            autoPlay={false}
            muted={true}
          // other props here
          />
        </div>
        <div className='sound-home-list'>
          {data.map((item, index) => {
            return (
              <Button
                key={item.id}
                variant=''
                onClick={() => onPlay(item.file, index)}
                className='sound-home-item'
                style={{ backgroundColor: index === activeIndex ? "#19978f" : '#f0f8ff', }}
              >
                <div className='d-flex flex-row justify-content-center align-items-center'>
                  {/* <i
                    className="fa-solid fa-music m-1"
                    style={{
                      color: index === activeIndex ? "#fff" : '#000'
                    }}
                  ></i> */}
                  <Avatar
                    src={item.artist !== null ? item.artist.image : ''}
                    size={50}
                  />
                  <p
                    className='sound-home-title'
                    style={{
                      color: index === activeIndex ? "#fff" : '#000',
                      paddingRight: 5
                    }}
                  >
                    {item.name}
                  </p>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>

                  <p
                    className='sound-home-title'
                    style={{
                      color: index === activeIndex ? "#fff" : '#000',
                      fontSize: 10,
                    }}
                  >
                    {item.artist !== null ? `${item.artist.first_name} ${item.artist.second_name} ${item.artist.last_name}` : ''}
                  </p>
                </div>
              </Button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
