
import React, { useState } from 'react'
import { Container, Dropdown, DropdownButton, Nav, Navbar as NavBar, Offcanvas } from 'react-bootstrap';
import DropdownNav, { DropdownNavMin } from '../components/DropdownNav';
import Logo from '../style/svg/Logo';
import { navDead, navLit, navMid, navOsise } from './listNavbar';
import { mediaURL, melodyURL } from '../config';
import { BsFacebook, BsInstagram, BsTelegram, BsTwitterX, BsYoutube } from "react-icons/bs";

export default function Navbar() {
    return (
        <div id='navbar-content' className='navbar-content'>
            <section id='nav-one' className='nav-one'>
                <div dir='rtl'>
                    <DropdownButton variant='' className='lang' id="dropdown-basic-button" title="اللغة">
                        <Dropdown.Item className='text-right' href="#/action-1">العربية</Dropdown.Item>
                        <Dropdown.Item className='text-right' href="#/action-2">الفارسية</Dropdown.Item>
                    </DropdownButton>
                </div>
                <div className='icons-navbar'>
                    <a href='/#' ><BsFacebook /></a>
                    <a href='/#' ><BsInstagram /></a>
                    <a href='/#'><BsTelegram /></a>
                    <a href='/#'><BsTwitterX /></a>
                    <a href='/#'><BsYoutube /></a>
                </div>
            </section>
            <section id='nav-two' className='nav-two'>
                <ul>
                    <li>
                        <a href='/'>الرئيسية</a>
                    </li>
                    <li>
                        <a href={mediaURL}>جمال ميديا</a>
                    </li>
                    <li>
                        <a href={melodyURL}>جمال ميلوديا</a>
                    </li>
                    <li>
                        <a href='/applications/'>التطبيقات</a>
                    </li>
                    <li>
                        <a href='/contact_us/'>تواصل معنا</a>
                    </li>
                    <li>
                        <a href='/speak_up/'>قم بالإبلاغ</a>
                    </li>
                </ul>
            </section>
            <section id='nav-three' className='nav-three'>
                <div className='logo-nav'>
                    <a href='/' className='d-flex flex-row justify-content-center align-items-center logo-link'>
                        <Logo
                            className={'st'}
                            width="5em"
                            height="3em"
                        />
                        <p>بوابة جمال الرقمية</p>
                    </a>
                </div>
                <nav>
                    <ul>
                        <li>
                            <DropdownNav title='الشهداء' list={navDead} />
                        </li>
                        <li>
                            <a href='/wounded/' id='dropdown-bot' className='dropdown-bot btn'>الجرحى</a>
                        </li>
                        <li>
                            <a href='/event/' id='dropdown-bot' className='dropdown-bot btn'>مذكرة جمال</a>
                        </li>
                        <li>
                            <DropdownNav title='الواحة' list={navOsise} />
                        </li>
                        <li>
                            <a href='/oasis_dinia/' id='dropdown-bot' className='dropdown-bot btn'>سبل السعادة</a>
                        </li>
                        <li>
                            <a href='/library/' id='dropdown-bot' className='dropdown-bot btn'>مكتبة جمال</a>
                        </li>
                        <li>
                            <DropdownNav title='الأدب المقاوم' list={navLit} />
                        </li>
                        <li>
                            <a href='/gallery/' id='dropdown-bot' className='dropdown-bot btn'>معرض جمال</a>
                        </li>
                        <li>
                            <a onClick={(e) => window.open(mediaURL)} href='/#' id='dropdown-bot' className='dropdown-bot btn'>جمال ميديا</a>
                        </li>
                        <li>
                            <a onClick={(e) => window.open(melodyURL)} href='/#' id='dropdown-bot' className='dropdown-bot btn'>جمال ميلوديا</a>
                        </li>
                    </ul>
                </nav>
            </section>
        </div>
    )
}

export function NavBarMin() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <NavBar bg='light' expand="xxl" style={{ backgroundColor: 'white', height: '100%' }} fixed='top'>
            <Container fluid>
                <div>
                    <Logo
                        className={'sr'}
                        width="4em"
                        height="3em"
                    />
                </div>
                <NavBar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
                <Offcanvas show={show} onHide={handleClose} placement='end' >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className='m-0 p-0'>
                            <a href='/' className='d-flex flex-row justify-content-center align-items-center p-0 m-0'>
                                <Logo
                                    className={'sr'}
                                    width="3em"
                                    height="2em"
                                />
                                <p className='main-color f-bold'>بوابة جمال الرقمية</p>
                            </a>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='m-0 p-0'>
                        <Nav className="m-0 p-0 nav-min">
                            <DropdownNavMin title='الشهداء' list={navDead} />
                            <Nav.Link href='/wounded/' className='dropdown-bot-min'>الجرحى</Nav.Link>
                            <Nav.Link href='/event/' className='dropdown-bot-min'>مذكرة جمال</Nav.Link>
                            <DropdownNavMin title='الواحة' list={navOsise} />
                            <Nav.Link href='/oasis_dinia/' className='dropdown-bot-min'>سبل السعادة</Nav.Link>
                            <Nav.Link href='/library/' className='dropdown-bot-min'>مكتبة جمال</Nav.Link>
                            <DropdownNavMin title='الأدب' list={navLit} />
                            <DropdownNavMin title='الوسائط' list={navMid} />
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </Container>
        </NavBar>
    );
}

