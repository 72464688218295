import { Button, Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MdTextSnippet } from 'react-icons/md';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import { AuthorCard, CategoryCard } from '../../components/cards';
import { LuLayoutList } from "react-icons/lu";

export default function PoemsHome() {
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [search, setSearch] = useState('');
    const [dataSearch, setDataSearch] = useState([]);
    useEffect(() => {
        document.title = 'القصائد'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {

            await axios.get(`/shared/api/v1/categorys/?type=1&page_size=4`)
                .then(res => {
                    setCategory(res.data.results)
                })
            await axios.get(`/shared/api/v1/authors/?type=1`)
                .then(res => {
                    setAuthors(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/literature/api/v1/poems/?search=${search}`)
                .then(res => {
                    setDataSearch(res.data.results)
                })
        }
        getData()
    }, [search])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page'>
            <div className="container-fluid p-0 m-0 position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-xl-5 col-lg-6  literature-page-search">
                        <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                            <Input
                                type='text'
                                size='large'
                                placeholder='بحث'
                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                style={{
                                    borderRadius: 5,
                                    width: '100%'
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search &&
                                <div className='literature-page-search-content'>
                                    {dataSearch.map((item) => {
                                        return (
                                            <div key={item.id} className='poem-item p-1'>
                                                <a href={`/poetry/poem/${item.id}/`}><MdTextSnippet size={20} color='#23b5ab' /> {item.poem_name}</a>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12 section-1">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-xl-6">
                                    <div>
                                        <p className='title-1 f-bold'>الشعر ... متراساً</p>
                                        <p className='title-2'>
                                            الادب المقاوم هو بيت الثقافة والعزة والمقاومة حيث يتجسد التمسك بالهوية والاشتباك مع العدو في كل حرف ونغمة ،الهمس بأصوات المشتبكين في اقاصي الأرض وهم يسطرون البطولات بأبيات شعرية مليئة بالعزيمة والشجاعة ونشدوا بها بكل فخر
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-6 d-flex justify-content-center align-items-center mt-3">
                                    <img alt="..." className='img-section' src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/adad_thumb_dw01.png'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 section-2">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-xl-6 d-flex justify-content-start align-items-end">
                                    <img alt="..." className='img-section' src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/adab_ph_jwd.png'} />
                                </div>
                                <div className="col-xl-6">
                                    <div>
                                        <p className='title-1 f-bold'>البيت ... شاهد</p>
                                        <p className='title-2'>
                                            الأدب المقاوم" في منصة جمال الرقمية يعد وجهة متكاملة لعشاق الأدب والشعر الذين يسعون للاستمتاع بأعمال تنبض بالحق والعزة والصمود، سواء كان ادب الفتوى المقدسة والمقاومة الإسلامية في لبنان والبحرين واليمن وفلسطين
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 section-3">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-xl-6">
                                    <div>
                                        <p className='title-1 f-bold'>بوح المقاومة</p>
                                        <p className='title-2'>
                                            في الادب المقاوم ستباغتك رائحة البارود المخلوطة بالشجاعة والعزم وتكسب في قدحك مشاعر الألم والصمود
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-6  d-flex justify-content-center align-items-center">
                                    <div className="lab-item">
                                        <div className="lab-inner">
                                            <div className="lab-thumb">
                                                <div className="img-grp">
                                                    <div className="about-circle-wrapper">
                                                        <div className="about-circle-2"></div>
                                                        <div className="about-circle"></div>
                                                    </div>
                                                    <div className="about-fg-img-2">
                                                        <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/cover1.png'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-5 pt-5">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <h4 className='f-bold'>الأقسام</h4>
                                </div>
                                {category.map(item => {
                                    return (
                                        <a key={item.id} href={`/poetry/category/${item.id}/`} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <CategoryCard
                                                name={item.name}
                                                image={item.image}
                                            />
                                        </a>
                                    )
                                })}
                                <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
                                    <Button variant='' icon={<LuLayoutList />} href='/poetry/category/' className='but-more'>المزيد</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-5 pt-5 section-4">
                        <div className="container">
                            <div className="row justify-content-start align-items-center g-2">
                                <div className="col-12">
                                    <h4 className='f-bold'>ابرز الشعراء</h4>
                                </div>
                                {authors.map((item) => {
                                    return (
                                        <div key={item.id} className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6">
                                            <AuthorCard
                                                id={item.id}
                                                title='شاعر'
                                                path={`/poetry/authors/${item.id}/`}
                                                name={`${item.first_name} ${item.second_name} ${item.last_name}`}
                                                image={item.image}
                                                infoTitleOne='قصيدة'
                                                infoTitleTow='متابع'
                                                infoCountOne={50}
                                                infoCountTow={50}
                                            />
                                        </div>
                                    )
                                })}
                                <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
                                    <Button variant='' icon={<LuLayoutList />} href='/poetry/authors/' className='but-more'>المزيد</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
