import React from 'react';
import ReactDOM from 'react-dom/client';
import { baseURL } from './config'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './App';
import axios from 'axios';
import './style'

//axios.defaults.baseURL = 'https://api.jamaltests.com/';
 axios.defaults.baseURL = baseURL;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

