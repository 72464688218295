import React from 'react'
import { CiTextAlignRight } from 'react-icons/ci';
import { MdEvent } from 'react-icons/md';

export default function EventCard(props) {
    const { id, name, dayName, date } = props;
    return (
        <div key={id} title={name} className='day-card'>
            <div className='timer'>
                <p className='name'>{dayName}</p>
                <p className='number'>{String(date).slice(-2,)}</p>
            </div>
            <div className='info-card'>
                <p className='title-1'><CiTextAlignRight size={18} /> حدث  في مثل هذا اليوم </p>
                <div className='title-2'>
                    <a href={`/event/list/${id}/`}>{name}</a>
                </div>
                <p className='date'><MdEvent /> {date}</p>
            </div>
        </div>
    )
}
