import { Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { MdPause, MdPlayArrow, MdTextSnippet, MdThumbUp } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import AudioPlayer from '../../components/AudioPlayer';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import Share from '../../components/Share';
import { defaultProfile } from '../../funcAndVar/var';
import { AuthorCard } from '../../components/cards';
import { IoShareOutline } from 'react-icons/io5';

export default function StoryItem() {
    const [loading, setLoading] = useState(true)
    const { id, name } = useParams();
    const [item, setItem] = useState('');
    const [text, setText] = useState([]);
    const [data, setData] = useState([]);
    const [author, setAuthor] = useState('');
    const [category, setCategory] = useState('');
    const [play, setPlay] = useState('')
    const [pause, setPause] = useState(true)

    useEffect(() => {
        document.title = name
        navPage()
    }, [name])
    useEffect(() => {
        async function getData() {

            await axios.get(`/literature/api/v1/storys/${id}/`)
                .then(res => {
                    setItem({ ...res.data })
                    setAuthor(res.data.author)
                    setCategory(res.data.category)

                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            if (author) {
                await axios.get(`/literature/api/v1/storys/?author=${author.id}&page_size=6`)
                    .then(res => {
                        setData(res.data.results)
                        if (res.status === 200) setLoading(false)
                    })
            }
        }
        getData()
    }, [author])
    const onPlay = (item) => {
        setPlay(item)
        if (pause) {
            setPause(false)
        } else {
            setPause(true)
        }
    }
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page'>
            <div className="container-fluid mt-5">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xl-2 col-lg-3 col-md-4 order-1 order-md-0">
                        <div className="container-fluid m-0 p-0">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <AuthorCard
                                        id={item.author.id}
                                        title='قاص'
                                        path={`/storys/authors/${item.author.id}/`}
                                        name={`${item.author.first_name} ${item.author.second_name} ${item.author.last_name}`}
                                        image={item.author.image}
                                        infoTitleOne='قصة'
                                        infoTitleTow='متابع'
                                        infoCountOne={50}
                                        infoCountTow={item.author.id * 50}
                                    />
                                </div>
                                <div className="col-12 mt-3">
                                    <div className='more-poem shadow'>
                                        <p className='title'>قصص اخرى</p>
                                        {
                                            data.filter(f => f.id === Number(id) ? null : f).map(data => {
                                                return (
                                                    <div key={data.id} className='poem-item'>
                                                        <a href={`/storys/story/id/${data.id}/name/${data.name}`}><MdTextSnippet size={20} color='#23b5ab' /> {data.name}</a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 order-0 order-md-1">
                        <div className='pome-content'>
                            <div className='pome-header'>
                                <p className='title f-bold'>{item.name}</p>
                                <div className='buts'>
                                    {item.file &&
                                        <Button onClick={() => onPlay(item)} variant=''>
                                            {pause ? <MdPlayArrow size={20} /> : <MdPause size={20} />}
                                        </Button>
                                    }
                                    <Button title='اعجاب' variant=''><MdThumbUp size={20} /></Button>
                                    <Share url={`/storys/story/id/${id}/name/${name}`}>
                                        <Button title='مشاركة' variant=''><IoShareOutline size={20} /></Button>
                                    </Share>
                                </div>
                            </div>
                            <div className='pome'>
                                <p className='fs-22 line-60'>{item.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
