import { Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { defaultProfile } from '../../funcAndVar/var';

export default function DeadThoughts() {
    const [loading, setLoading] = useState(true)
    const [dead, setDead] = useState([]);
    useEffect(() => {
        document.title = 'شهدائنا'
        navPage()
    }, [])

    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/Death/?has_quote=true&page_size=60')
                .then(res => {
                    setDead(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div className="quote-page">
            <div className="container-fluid mt-3">
                <div className="row justify-content-start align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/thoughts/`}>الخواطر</a>
                            <a href='/#' className='header-link-item'>الشهداء</a>
                        </div>
                    </div>
                    {dead.length > 0 ?
                        <>
                            {dead.map((item) => {
                                return (
                                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-12">
                                        <div key={item.id} className="card-author" style={{ backgroundColor: '#fff' }}>
                                            <a href={`/thoughts/dead/${item.id}/`} className='card-author-content'>
                                                <Avatar src={item.image !== null ? item.image : defaultProfile} className='avatar' size={100} />
                                                <p className='title-1'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                                <p className='title-2'>{item.place_birth !== null ? item.place_birth.name : ''}</p>
                                                <div className='info-count'>
                                                    <p>
                                                        <span>عدد الخواطر</span>
                                                        <span>{item.q_count}</span>
                                                    </p>
                                                    <p>
                                                        <span>عدد المتابعين</span>
                                                        <span>150</span>
                                                    </p>
                                                </div>
                                            </a>
                                            <Button variant='' className='but-flow'>متابعة</Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        : <Empty />}
                </div>
            </div>
        </div>
    )
}
