import { Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { FaDove } from 'react-icons/fa';
import Loading from '../../components/Loading';
import { useParams } from 'react-router-dom';
import { navPage } from '../../funcAndVar/func';
import { defaultProfile2 } from '../../funcAndVar/var';
import { IoShareOutline } from 'react-icons/io5';

export default function ThoughtsItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams();
    const [item, setItem] = useState('');
    const [data, setData] = useState([]);
    const [author, setAuthor] = useState('');

    useEffect(() => {
        document.title = 'خاطرة'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {

            await axios.get(`/dead_wounded/api/v1/quotes/${id}/`)
                .then(res => {
                    setItem({ ...res.data })
                    setAuthor(res.data.name)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {
            if (author) {
                await axios.get(`/dead_wounded/api/v1/quotes/?type=1&name=${author.id}`)
                    .then(res => {
                        setData(res.data.results)
                    })
            }
        }
        getData()
    }, [author])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page'>
            <div className="container-fluid mt-3">
                <div className="row justify-content-center align-items-start g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/thoughts/`}>الخواطر</a>
                            <a className='header-link-item' href={`/thoughts/dead/`}>الشهداء</a>
                            <a className='header-link-item' href={`/thoughts/dead/${author.first_name}/`}>{author.first_name} {author.second_name} {author.third_name} {author.last_name}</a>
                            <a href='/#' className='header-link-item' >خاطرة</a>
                        </div>
                    </div>
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="container-fluid  m-0 p-0">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className="card-author shadow" style={{ backgroundColor: '#fff' }}>
                                        <a href={`/thoughts/dead/${author.id}/`} className='card-author-content'>
                                            <Avatar src={author !== null ? author.image : defaultProfile2} className='avatar' size={100} />
                                            <p className='title-1'>{author.first_name} {author.second_name} {author.third_name}  {author.last_name}</p>
                                            <div className='info-count'>
                                                <p>
                                                    <span>عدد الخواطر</span>
                                                    <span>100</span>
                                                </p>
                                                <p>
                                                    <span>عدد المتابعين</span>
                                                    <span>150</span>
                                                </p>
                                            </div>
                                        </a>
                                        <Button variant='' className='but-flow'>متابعة</Button>
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className='more-poem shadow'>
                                        <p className='title'>خواطر اخرى</p>
                                        {data.map(data => {
                                            return (
                                                <div key={item.id} className='poem-item'>
                                                    <a  href={`/thoughts/list/${data.id}/`}><FaDove size={20} color='#23b5ab' /> {String(item.text).slice(0, 30)}...</a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12">
                        <div className='pome-content'>
                            <div className='pome-header'>
                                <p className='title'>خاطرة</p>
                                <div className='buts'>
                                    <Button title='مشاركة' variant=''><IoShareOutline size={20} /></Button>
                                </div>
                            </div>
                            <div className='pome'>
                                <div className="container">
                                    <div className="row justify-content-center align-items-center g-2">
                                        <p
                                            className='text col-xl-12 lh-lg'
                                            style={{ textAlign: 'justify' }}
                                        >
                                            {item.text}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
