import { Avatar } from 'antd'
import React from 'react'
import { defaultCover } from '../../funcAndVar/var'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
export default function QuoteHome({ data }) {

    return (
        <div className='quote-home row justify-content-center align-items-center g-2'>
            <div className="col-12">
                <Swiper
                    dir="rtl"
                    slidesPerView={1}
                    spaceBetween={5}
                    loop={true}
                    centeredSlides={true}
                    centerInsufficientSlides={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        560: {
                            slidesPerView: 1.25,
                            spaceBetween: 45
                        },
                        768: {
                            slidesPerView: 1.5,
                            spaceBetween: 75
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 75
                        },
                        1300: {
                            slidesPerView: 2.25,
                            spaceBetween: 75
                        },
                    }}
                    modules={[Autoplay]}
                >
                    {data.map((item, index) => {
                        return (
                            <SwiperSlide>
                                <div key={item.id} className='quote-home-item'>
                                    <div className='quote-avatar'>
                                        <Avatar src={item.name.image !== null ? item.name.image : defaultCover} size={100} className='avatar' />
                                    </div>
                                    <div className='quote-home-icon icon-right'><i className="fa-solid fa-quote-right "></i></div>
                                    <div className='quote-home-text-title'>
                                        <p className='quote-home-text'>
                                            {item.text}
                                        </p>
                                        <p className='quote-home-title'>{item.name.first_name} {item.name.second_name} {item.name.third_name} {item.name.last_name}</p>
                                    </div>
                                    <div className='quote-home-icon icon-left'><i className="fa-solid fa-quote-left "></i></div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}
//{item.name.first_name} {item.name.second_name} {item.name.third_name}  {item.name.last_name}