import { Avatar, Calendar, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Nav, Tab } from 'react-bootstrap';
import { CiTextAlignRight } from 'react-icons/ci';
import { MdEvent } from 'react-icons/md';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { navPage } from '../../funcAndVar/func';
import EventSort from '../../components/event/EventSort';
import Loading from '../../components/Loading';
import { defaultCover, defaultCover2, defaultProfile2 } from '../../funcAndVar/var';
import EventCard from '../../components/event/EventCard';

export default function EventHome() {
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState([]);
    const [dead, setDead] = useState([]);
    const [deadCount, setDeadCount] = useState(0);
    const [mode, setMode] = useState('month');
    const [open, setOpen] = useState(false);
    const onChange = (newDate) => {
        console.log(`New date selected - ${newDate.toString()}`);
    };
    useEffect(() => {
        document.title = 'مذكرة جمال'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/dead_daylist/?page_size=30')
                .then(res => {
                    setDead(res.data.results);
                    setDeadCount(res.data.count);
                    if (res.status === 200) setLoading(false);
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/avent_day/api/v1/avents/?page_size=60')
                .then(res => {
                    setEvents(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])

    const emptyArray = JSON.stringify([]);
    const days = (day) => {
        switch (day) {
            case "Sun": return "الاحد"; break;
            case "Mon": return "الاثنين"; break;
            case "Tue": return "الثلاثاء"; break;
            case "Wed": return "الاربعاء"; break;
            case "Thu": return "الخميس"; break;
            case "Fri": return "الجمعة"; break;
            case "Sat": return "السبت"; break;
            default: return null;
        }
    }
    if (loading) {
        return <Loading />
    }
    return (
        <div className='event-page'>
            <div className="container-fluid mt-2">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-12">
                        <Swiper
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            navigation={true}
                            modules={[Autoplay, Navigation]}
                            className="event-page-swiper swiper"
                            style={{
                                '--swiper-navigation-color': '#aaa',
                                "--swiper-pagination-color": "#fff",
                            }}
                        >
                            {events.map((item, index) => {
                                const imagesAll = JSON.stringify(item.images);
                                return (
                                    <SwiperSlide key={item.id}>
                                        <div className="swiper-card" >
                                            <img className='image-cover' src={imagesAll !== emptyArray ? item.images[0].image : defaultCover} />
                                            <div className='info-card'>
                                                <p className='title-1'><CiTextAlignRight size={18} /> حدث اليوم</p>
                                                <p className='title-2'>{item.name}</p>
                                                <p className='title-3'>{String(item.description).slice(0, 90)}....</p>
                                                <p className='title-4'><MdEvent /> {item.avent_date}</p>
                                                <Button variant='' href={`/event/list/${item.id}/`} className='info-but'>معاينة الحدث</Button>
                                            </div>
                                            <img alt="..." className='img-avatar' src={defaultCover2} />
                                        </div>

                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className='col-12'>
                        <EventSort
                            data={events}
                            open={open}
                            setOpen={setOpen}
                            days_={days}
                        />
                    </div>
                    <div className="col-xl-4">
                        <div className='event-section event-card-h'>
                            <p className='title-section'><MdEvent color='#23b5ab' size={25} /> أحداث اليوم</p>
                            <Swiper
                                loop={true}
                                direction={"vertical"}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={4}
                                spaceBetween={100}
                                modules={[Autoplay]}
                                className="event-content swiper"
                                style={{
                                    '--swiper-navigation-color': '#aaa',
                                    "--swiper-pagination-color": "#fff",
                                }}
                            >
                                {events.map((item, index) => {
                                    var d = new Date(item.avent_date);
                                    var dayName = d.toString().split(' ')[0];
                                    return (
                                        <SwiperSlide key={item.id}>
                                            <EventCard
                                                id={item.id}
                                                name={item.name}
                                                dayName={days(dayName)}
                                                date={item.avent_date}
                                            />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className='event-section event-card-h'>
                            <p className='title-section'><MdEvent color='#23b5ab' size={25} /> أحداث حسب الأيام والأشهر</p>
                            <div className='calendar-card'>
                                <div className='calendar-content'>
                                    <Tab.Container className='container-fluid' id="left-tabs-example" defaultActiveKey="first">
                                        <div className="row justify-content-center align-items-center g-2">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="container-fluid m-0 p-0">
                                                    <div className="row justify-content-center align-items-center g-2">
                                                        <div className="col-12">
                                                            <Nav variant="" className="tap-event">
                                                                <Nav.Item>
                                                                    <Nav.Link onClick={() => setMode('month')} eventKey="first">احداث حسب اليوم</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item><p className='border-p' /> </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link onClick={() => setMode('year')} eventKey="second">احداث حسب الشهر</Nav.Link>
                                                                </Nav.Item>

                                                            </Nav>
                                                        </div>
                                                        <div className="col-12">
                                                            <Calendar
                                                                onChange={onChange}
                                                                fullscreen={false}
                                                                mode={mode}
                                                                headerRender={({ value, type, onChange, onTypeChange }) => {
                                                                    const start = 0;
                                                                    const end = 12;
                                                                    const monthOptions = [];
                                                                    let current = value.clone();
                                                                    const localeData = value.localeData();
                                                                    const months = [];
                                                                    for (let i = 0; i < 12; i++) {
                                                                        current = current.month(i);
                                                                        months.push(localeData.monthsShort(current));
                                                                    }
                                                                    for (let i = start; i < end; i++) {
                                                                        monthOptions.push(
                                                                            <Select.Option key={i} value={i} className="month-item">
                                                                                {months[i]}
                                                                            </Select.Option>,
                                                                        );
                                                                    }
                                                                    const year = value.year();
                                                                    const month = value.month();
                                                                    const options = [];
                                                                    for (let i = year - 10; i < year + 10; i += 1) {
                                                                        options.push(
                                                                            <Select.Option key={i} value={i} className="year-item">
                                                                                {i}
                                                                            </Select.Option>,
                                                                        );
                                                                    }
                                                                    return (
                                                                        <Select
                                                                            dropdownMatchSelectWidth={false}
                                                                            value={month}
                                                                            onChange={(newMonth) => {
                                                                                const now = value.clone().month(newMonth);
                                                                                onChange(now);
                                                                            }}
                                                                            className='m-1'
                                                                        >
                                                                            {monthOptions}
                                                                        </Select>)
                                                                }}
                                                                style={{
                                                                    '--theme-color': '#23b5ab'
                                                                }}
                                                                cite='baghdad'
                                                                lang='ara'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12 overflow-hidden" style={{ height: 400 }}>
                                                <Tab.Content>
                                                    <Tab.Pane className='event-content' eventKey="first">
                                                        <p>احداث حسب اليوم</p>
                                                        <Swiper
                                                            loop={true}
                                                            direction={"vertical"}
                                                            autoplay={{
                                                                delay: 5000,
                                                                disableOnInteraction: false,
                                                            }}
                                                            slidesPerView={4}
                                                            spaceBetween={100}
                                                            modules={[Autoplay]}
                                                            className="event-content swiper"
                                                            style={{
                                                                '--swiper-navigation-color': '#aaa',
                                                                "--swiper-pagination-color": "#fff",
                                                            }}
                                                        >
                                                            {events.map((item, index) => {
                                                                var d = new Date(item.avent_date);
                                                                var dayName = d.toString().split(' ')[0];
                                                                return (
                                                                    <SwiperSlide key={item.id}>
                                                                        <EventCard
                                                                            id={item.id}
                                                                            name={item.name}
                                                                            dayName={days(dayName)}
                                                                            date={item.avent_date}
                                                                        />
                                                                    </SwiperSlide>
                                                                )
                                                            })}
                                                        </Swiper>
                                                    </Tab.Pane>
                                                    <Tab.Pane className='event-content' eventKey="second">
                                                        <p>احداث حسب الشهر</p>
                                                        <Swiper
                                                            loop={true}
                                                            direction={"vertical"}
                                                            autoplay={{
                                                                delay: 5000,
                                                                disableOnInteraction: false,
                                                            }}
                                                            slidesPerView={4}
                                                            spaceBetween={50}
                                                            modules={[Autoplay]}
                                                            className="event-content swiper"
                                                            style={{
                                                                '--swiper-navigation-color': '#aaa',
                                                                "--swiper-pagination-color": "#fff",
                                                            }}
                                                        >
                                                            {events.map((item, index) => {
                                                                var d = new Date(item.avent_date);
                                                                var dayName = d.toString().split(' ')[0];
                                                                return (
                                                                    <SwiperSlide key={item.id}>
                                                                        <EventCard
                                                                            id={item.id}
                                                                            name={item.name}
                                                                            dayName={days(dayName)}
                                                                            date={item.avent_date}
                                                                        />
                                                                    </SwiperSlide>
                                                                )
                                                            })}
                                                        </Swiper>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </div>
                                        </div>

                                    </Tab.Container>
                                </div>

                            </div>
                        </div>
                    </div>
                    {deadCount > 0 ?
                        <div className='col-12'>
                            <div className='event-section'>
                                <p className='title-section'><MdEvent color='#23b5ab' size={25} /> أستشهد في مثل هذا اليوم</p>
                                <Swiper
                                    dir="rtl"
                                    slidesPerView={2}
                                    spaceBetween={4}
                                    slidesPerGroup={2}
                                    loop={true}
                                    breakpoints={{
                                        768: {
                                            slidesPerView: 4    ,
                                            slidesPerGroup: 4
                                        },
                                        1024: {
                                            slidesPerView: 5,
                                            slidesPerGroup: 5
                                        },
                                        1300: {
                                            slidesPerView: 8,
                                            slidesPerGroup: 8
                                        },
                                    }}
                                >
                                    {dead.map((item, index) => {
                                        return (
                                            <SwiperSlide key={item.id}>
                                                <a href={`/dead/list/${item.id}/`} className="d-flex flex-column justify-content-center align-items-center">
                                                    <Avatar size={75} src={item.image !== null ? item.image : defaultProfile2} />
                                                    <div>
                                                        <p className='card-man-info-title'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                                    </div>
                                                </a>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div >
        </div >
    )
}
