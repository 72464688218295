import { Image } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { MdPlace } from 'react-icons/md';
import { useParams } from 'react-router-dom'
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import DeadForm from '../../components/dead/DeadForm';
import Loading from '../../components/Loading';
import { compareDates, navPage } from '../../funcAndVar/func';
import { deadList } from './list';
import { defaultCover, defaultProfile2 } from '../../funcAndVar/var';

export default function DeadItem() {
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  const [dead, setDead] = useState('');
  const [qoute, setQoute] = useState([]);
  const [thoughts, setThoughts] = useState([]);
  const [images, setImages] = useState([]);
  const [remind, setRemind] = useState([]);
  const [text, setText] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    document.title = 'شهدائنا'
    navPage()
  }, [])
  useEffect(() => {
    async function getData() {
      await axios.get(`/dead_wounded/api/v1/Death/${id}/`)
        .then(res => {

          setDead({ ...res.data });
        })
    }
    getData()
  }, [id])
  useEffect(() => {
    async function getData() {
      await axios.get(`/dead_wounded/api/v1/quotes/?type=0&name=${id}`)
        .then(res => {
          setQoute(res.data.results);
        })
      await axios.get(`/dead_wounded/api/v1/quotes/?type=1&name=${id}`)
        .then(res => {
          setThoughts(res.data.results);
        })
      await axios.get(`/gallery/api/v1/images/?deads=${id}&page_size=4`)
        .then(res => {
          setImages(res.data.results);
        })
      await axios.get(`/dead_wounded/api/v1/remains/?name=${id}&page_size=4`)
        .then(res => {
          setRemind(res.data.results);
          if (res.status === 200) setLoading(false)
        })
    }
    getData()
  }, [id])
  const moreText = () => {
    if (text) {
      setText(false)
    } else {
      setText(true)
    }
  }
  const result = compareDates(dead.date_of_death, '2014-06-14');
  if (loading) {
    return <Loading />
  };
  if (!dead) return null;
  return (
    <>
      <div class="container-fluid">
        <div class="row justify-content-center align-items-center g-2">
          <div className='dead-item-bg' />
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='header-link'>
              <a className='header-link-item' href={`/dead/`}>شهداءنا</a>
              <a className='header-link-item' href={`/dead/list/`}>قاعدة بيانات الشهداء</a>
              <a className='header-link-item' style={{ backgroundColor: '#fff', color: '#23b5ab' }}>{dead.first_name} {dead.second_name} {dead.third_name} {dead.last_name}</a>
            </div>
          </div>
          <div className='col-lg-11 col-md-12 col-12'>
            <div className="container-fluid p-0 dead-item">
              <div className="row justify-content-center align-items-start g-2">
                <div className="col-12 p-0 m-0">
                  <img alt="..." src={dead.cover !== null ? dead.cover : defaultCover} className='dead-item-cover' />
                </div>
                <div className="col-11">
                  <div className='dead-item-header'>
                    <img alt="..." src={dead.image !== null ? dead.image : defaultProfile2} className='header-avatar' />
                    <div className='header-about'>
                      <p className='header-about-name'>{dead.first_name} {dead.second_name} {dead.third_name} {dead.last_name}</p>
                      <p className='header-about-info'>
                        {dead.place_residence !== null ? <span><MdPlace /> {dead.place_residence.name}</span> : null}
                      </p>
                      <div className='header-about-buts'>
                        <Button variant=''>موقع القبر</Button>
                        <Button variant='' onClick={handleShow}>اضافة بيانات</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="container-fluid">
                    <div className="row justify-content-center align-items-start g-2">
                      <div className="col-xl-8">
                        <div className="container-fluid">
                          <div className="row justify-content-center align-items-center g-2">
                            <div className="col-12">
                              <div>
                                <p className='f-bold'>حول الشهيد</p>
                                <div className="container-fluid">
                                  <div className="row justify-content-start align-items-center g-2">
                                    {deadList(dead).map((item) => {
                                      if (item.value === null) return null
                                      return (
                                        <div key={item.key} className="col-xl-6">
                                          <div className='dead-item-card'>
                                            <div className='dead-item-card-icon'>{item.icon}</div>
                                            <div className='dead-item-card-info'>
                                              <p className='dead-item-card-info-title'>{item.key}</p>
                                              <p className='dead-item-card-info-dis'>{item.value}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {qoute.length > 0 &&
                              <div className="col-12">
                                <div>
                                  <p> اقتباسات الشهيد</p>
                                  <Swiper
                                    dir="rtl"
                                    slidesPerView={1}
                                    spaceBetween={5}
                                    loop={true}
                                    navigation={true}
                                    modules={[Navigation]}
                                    loopFillGroupWithBlank={true}
                                    className='px-5'
                                  >
                                    {qoute.map((item) => {
                                      return (
                                        <SwiperSlide key={item.id}>
                                          <div className='quote-home-item w-100' style={{ height: 150 }}>
                                            <div className='quote-home-icon'><i className="fa-solid fa-quote-right "></i></div>
                                            <div className='quote-home-text-title ' style={{ width: '100%', height: '100%' }}>
                                              <p className='quote-home-text  text-more'>{item.text}</p>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      )
                                    })}
                                  </Swiper>
                                </div>
                              </div>}
                            {thoughts.length > 0 &&
                              <div className="col-12">
                                <div>
                                  <p>خواطر الشهيد</p>
                                  <Swiper
                                    dir="rtl"
                                    slidesPerView={1}
                                    spaceBetween={5}
                                    loop={true}
                                    navigation={true}
                                    modules={[Navigation]}
                                    loopFillGroupWithBlank={true}
                                    className='px-5'
                                  >
                                    {thoughts.map((item) => {
                                      return (
                                        <SwiperSlide key={item.id}>
                                          <div className='quote-home-item w-100'>
                                            <div className='quote-home-icon'><i className="fa-solid fa-dove"></i></div>
                                            <div className='quote-home-text-title'>
                                              <p
                                                className='quote-home-text text-more'
                                                style={{ height: 200 }}
                                              >
                                                {item.text}
                                              </p>

                                              <div
                                                className='more-buts'
                                                style={{ marginTop: text ? 5 : -50 }}>
                                              </div>


                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      )
                                    })}
                                  </Swiper>
                                </div>
                              </div>}
                          </div>
                        </div>
                      </div>
                      {images.length > 0 || remind.length > 0 ?
                        <div className="col-xl-4">
                          <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                              {images.length > 0 &&
                                <div className="col-12">
                                  <p>صور الشهيد</p>
                                  <div className='dead-image'>
                                    {images.map(item => {
                                      return (
                                        <div key={item.id} className='img-content'>
                                          <img title={item.name} className='img-dead' src={item.image} />
                                        </div>

                                      )
                                    })}

                                    <div >
                                      <Button variant=''>المزيد</Button>
                                    </div>
                                  </div>
                                </div>}
                              {remind.length > 0 &&
                                <div className="col-12">
                                  <p>اثار الشهيد</p>
                                  <div className='dead-image'>
                                    {remind.map(item => {
                                      return (
                                        <div key={item.id} className='img-content'>
                                          <img title={item.type} className='img-dead' src={item.image} />
                                        </div>

                                      )
                                    })}

                                    <div>
                                      <Button href={`/remains/dead/${id}/`} variant=''>المزيد</Button>
                                    </div>
                                  </div>
                                </div>}
                            </div>
                          </div>
                        </div> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Modal show={show} size='xl' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{dead.first_name} {dead.second_name} {dead.third_name} {dead.last_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeadForm hasButton />
        </Modal.Body>
      </Modal>
    </>
  )
}
