import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Carousel } from 'react-bootstrap';
import { BsImages } from 'react-icons/bs';
import { Button } from 'antd';

export default function GalleryHome(props) {
    const { data } = props;
    const [index, setIndex] = useState(0)
    return (
        <div className='gallery-container'>
            <div className='content-image'>
                <Carousel fade activeIndex={index} controls={false} indicators={false}>
                    {data.map((item, index) => {
                        return (
                            <Carousel.Item onClick={() => setIndex(index)}>
                                <div
                                    class="container-fluid"
                                >
                                    <div
                                        class="row justify-content-center align-items-start g-2"
                                    >
                                        <div class="col-lg-6 h-100 d-flex flex-column justify-content-between">
                                            <div className='content-info'>
                                                <div className='h-100'>
                                                    <h2>معرض جمال</h2>
                                                    <p className='title'>{item.name}</p>
                                                    <p>{item.description}</p>
                                                </div>
                                                <Button variant="" icon={<BsImages />} href='/gallery/' className="but-info" >معرض جمال </Button>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 overflow-hidden">
                                            <img src={item.image} className='image' />
                                        </div>
                                    </div>

                                </div>


                            </Carousel.Item>
                        )
                    })}


                </Carousel>
            </div>
            <div className='swiper-container'>
                <Swiper
                    dir="rtl"
                    slidesPerView={2}
                    spaceBetween={5}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        560: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,

                        },
                        1300: {
                            slidesPerView: 6,
                        },
                    }}
                >
                    {data.map((item, i) => {
                        return (
                            <SwiperSlide onClick={() => setIndex(i)}>
                                <img src={item.image} className={index === i ? 'image br-color' : 'image'} />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}
