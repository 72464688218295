import { Avatar } from 'antd'
import React from 'react'
import { Button } from 'react-bootstrap'
import H5AudioPlayer from 'react-h5-audio-player'
import { MdKeyboardArrowDown } from 'react-icons/md'

export default function AudioPlayer({ play, onClose, list, onPause, pause, title, author, image, url }) {
    return (
        <div className='audio-player'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col">
                        <div className='audio-player-info'>
                            <Avatar src={image} size={75} />
                            <div className='player-info'>
                                <p className='player-info-title'>{title}</p>
                                <p className='player-info-author'>{author}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <H5AudioPlayer
                            src={play ? play.file : url}
                            showFilledProgress
                            layout='horizontal'
                            showFilledVolume
                            onPause={() => onPause(true)}
                            onPlay={() => onPause(false)}
                        />
                    </div>
                    <div className="col-1">
                        <Button variant='' onClick={onClose}><MdKeyboardArrowDown size={25} color='#23b5ab' /></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
