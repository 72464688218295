import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { CiTextAlignRight } from 'react-icons/ci';
import { MdQueryBuilder } from 'react-icons/md';
import { TbNews } from "react-icons/tb";
import { useParams } from 'react-router-dom'
import Loading from '../../components/Loading';
import { navPage } from '../../funcAndVar/func'
import { defaultCover2 } from '../../funcAndVar/var';

export default function EventItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [news, setNews] = useState('');
    // const [tag, setTag] = useState('');
    const [images, setImages] = useState([]);
    const [moreNews, setMoreNews] = useState([]);
    useEffect(() => {
        async function getData() {
            await axios.get(`/avent_day/api/v1/avents/${id}/`)
                .then(res => {
                    setNews(res.data)
                    setImages(res.data.images)
                    // setTag(res.data.tags)
                    document.title = res.data.name
                })
            await axios.get(`/avent_day/api/v1/avents/`)
                .then(res => {
                    setMoreNews(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        navPage()
    }, [])
    const imagesAll = JSON.stringify(images)
    const emptyArray = JSON.stringify([]);
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-lg-11 col-md-12 col-sm-12">
                        <div className='news-item-content'>
                            <div className='news-item-content-header'>
                                <img alt="..." src={imagesAll !== emptyArray ? images[0].image : defaultCover2} className='news-item-content-image' />
                                <div className="bg-images bg-video" style={{ zIndex: 1, height: '100%', borderRadius: 0 }} />
                                <div className='news-card-info'>
                                    <p className='news-card-info-tag'><CiTextAlignRight size={18} /> حدث في يوم</p>
                                    <p className='news-card-info-date'><MdQueryBuilder size={14} /> {news.avent_date}</p>
                                    <p className='news-card-info-title text-light'>{news.name}</p>

                                </div>
                            </div>
                            <p className='news-item-content-title text-dark'><TbNews size={25} /> {news.name}</p>
                            <div className="container-fluid mt-2">
                                <div className="row justify-content-center align-items-start g-2">
                                    <div className="col-xxl-8 col-xl-6 col-md-12 col-sm-12">
                                        <div className="container-fluid">
                                            <div className="row justify-content-center align-items-start g-2">

                                                {imagesAll !== emptyArray
                                                    && <div className="col-12 d-flex justify-content-center align-items-center">
                                                        <Carousel indicators={false} className='news-item-content-carousel'>
                                                            {images.map((item, imdex) => {

                                                                return (
                                                                    <Carousel.Item key={item.id}>
                                                                        <img
                                                                            alt="..."
                                                                            src={item.image}
                                                                        />
                                                                    </Carousel.Item>
                                                                )
                                                            })}
                                                        </Carousel>
                                                    </div>}
                                                <div className="col-12 d-flex justify-content-center align-items-start">
                                                    <p className='news-item-content-summary'>{news.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                                        <div className='news-item-content-more'>
                                            <p className='more-title'><CiTextAlignRight size={20} /> احداث أخرى</p>
                                            <div className='news-item-content-more-items'>
                                                {moreNews.map((item, index) => {
                                                    const images = item.images;
                                                    const imagesAll = JSON.stringify(images)
                                                    const emptyArray = JSON.stringify([]);
                                                    if (item.id === Number(id)) return null
                                                    return (
                                                        <div key={item.id} className='more-card'>
                                                            <a href={`/event/list/${item.id}/`} className='more-card-info'>
                                                                <p className='more-card-info-tag'><CiTextAlignRight size={18} /> حدث</p>
                                                                <p className='more-card-info-title'>{item.name}</p>
                                                                <p className='more-card-info-summary'>{String(item.description).slice(0, 75)} ... </p>
                                                                <p className='more-card-info-date'><MdQueryBuilder size={14} /> {item.avent_date}</p>
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
