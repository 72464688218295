
const baseURL = 'https://api.jamal-gate.com/';
const shareURL = 'https://jamal-gate.com';
const mediaURL = 'https://media.jamal-gate.com';
const melodyURL = 'https://melody.jamal-gate.com';
const env = 'production';

module.exports = {
    baseURL,
    shareURL,
    mediaURL,
    melodyURL,
    env
}