import React from 'react'
import { BsInboxes } from 'react-icons/bs'

export default function Empty({ title, icon:Icon }) {
    return (
        <div className='empty'>
            <p className='empty-icon'><BsInboxes /></p>
            <p className='empty-title'>لايوجد محتوى</p>
        </div>
    )
}
