import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { MdPlace } from 'react-icons/md';
import { useParams } from 'react-router-dom'
import SpykerForm from '../../components/dead/SpykerForm';
import Loading from '../../components/Loading';
import { navPage } from '../../funcAndVar/func';
import { spykerList } from './list';
import { defaultCover, defaultProfile2 } from '../../funcAndVar/var';

export default function SpykerItem() {
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  const [dead, setDead] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    document.title = 'شهدائنا'
    navPage()
  }, [])
  useEffect(() => {
    async function getData() {
      await axios.get(`/dead_wounded/api/v1/spyker/${id}/`)
        .then(res => {

          setDead({ ...res.data });
          if (res.status === 200) setLoading(false)
        })
    }
    getData()
  }, [id])

  if (loading) {
    return <Loading />
  };
  if (!dead) return null
  return (
    <>
      <div class="container-fluid">
        <div class="row justify-content-center align-items-center g-2">
          <div className='dead-item-bg' />
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='header-link'>
              <a className='header-link-item' href={`/spyker/`}>شهداءنا</a>
              <a className='header-link-item' href={`/spyker/list/`}>قاعدة بيانات الشهداء</a>
              <a className='header-link-item' style={{ backgroundColor: '#fff', color: '#23b5ab' }}>{dead.first_name} {dead.second_name} {dead.grandfather_name} {dead.last_name}</a>
            </div>
          </div>
          <div class="col-lg-11 col-md-12 col-12">
            <div className="container-fluid p-0 dead-item ">
              <div className="row justify-content-center align-items-start g-2">
                <div className="col-12 p-0 m-0">
                  <img alt="..." src={defaultCover} className='dead-item-cover' />
                </div>
                <div className="col-11">
                  <div className='dead-item-header'>
                    <img alt="..." src={dead.image !== null ? dead.image : defaultProfile2} className='header-avatar' />
                    <div className='header-about'>
                      <p className='header-about-name'>{dead.first_name} {dead.second_name} {dead.grandfather_name} {dead.last_name}</p>
                      <p className='header-about-info'>
                        {dead.place_residence !== null ? <span><MdPlace /> {dead.place_residence.name}</span> : null}
                      </p>
                      <div className='header-about-buts'>
                        <Button variant='' onClick={handleShow}>اضافة بيانات</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="container-fluid">
                    <div className="row justify-content-center align-items-start g-2">
                      <div className="col-xl-8">
                        <div className="container-fluid">
                          <div className="row justify-content-center align-items-center g-2">
                            <div className="col-12">
                              <div>
                                <p className='f-bold'>حول الشهيد</p>
                                <div className="container-fluid">
                                  <div className="row justify-content-start align-items-center g-2">
                                    {spykerList(dead).map((item) => {
                                      if (item.value === null) return null
                                      return (
                                        <div key={item.key} className="col-xl-6">
                                          <div className='dead-item-card'>
                                            <div className='dead-item-card-icon'>{item.icon}</div>
                                            <div className='dead-item-card-info'>
                                              <p className='dead-item-card-info-title'>{item.key}</p>
                                              <p className='dead-item-card-info-dis'>{item.value}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Modal show={show} size='xl' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{dead.first_name} {dead.second_name} {dead.grandfather_name} {dead.last_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SpykerForm />
        </Modal.Body>
      </Modal>
    </>
  )
}
