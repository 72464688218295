import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import axios from 'axios';
import { Avatar, Input } from 'antd';
import { Button } from 'react-bootstrap';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { defaultProfile2 } from '../../funcAndVar/var';

export default function ThoughtsHome() {
    const [loading, setLoading] = useState(true)
    const [qoute, setQoute] = useState([]);
    const [dead, setDead] = useState([]);
    const [search, setSearch] = useState('');
    const [deadSearch, setDeadSearch] = useState([]);

    useEffect(() => {
        document.title = 'الخواطر'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/quotes/?type=1`)
                .then(res => {
                    setQoute(res.data.results);
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/Death/?has_quote=true&page_size=6')
                .then(res => {
                    setDead(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Death/?has_quote=true&search=${search}`)
                .then(res => {
                    setDeadSearch(res.data.results)
                })
        }
        getData()
    }, [search])
    if (loading) {
        return <Loading />
    }
    return (
        <div className="quote-page">
            <div className="container-fluid p-0 m-0 position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-xl-5 col-lg-6 quote-page-search">
                        <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                            <Input
                                type='text'
                                size='large'
                                placeholder='بحث'
                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                style={{
                                    borderRadius: 5,
                                    width: '100%'
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search &&
                                <div className='quote-page-search-content'>
                                    {deadSearch.map((item) => {
                                        return (
                                            <a href={`/thoughts/dead/${item.id}/`} key={item.id} className='search-item'>
                                                <Avatar src={item.image !== null ? item.image : defaultProfile2} className='' size={75} />
                                                <p className='neme'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                            </a>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    {qoute.length > 0 ?
                        <>
                            {qoute.length > 0 &&
                                <div className="col-12">
                                    <Swiper
                                        loop={true}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="quote-page-swiper swiper"
                                        style={{
                                            '--swiper-navigation-color': '#aaa',
                                            "--swiper-pagination-color": "#fff",
                                        }}
                                    >
                                        {qoute.map((item, index) => {
                                            return (
                                                <SwiperSlide key={item.id}>
                                                    {/* <div className="swiper-card">
                                                        <div className='image-content'>
                                                            <div className='info-content'>
                                                                <img alt="..." src={item.name.image} />
                                                                <div className="subtitle">
                                                                    <p>{item.name.first_name} {item.name.second_name} {item.name.third_name} {item.name.last_name}</p>
                                                                    <p>{item.name.description}</p>
                                                                </div>

                                                                <div className='bg-cover' />
                                                            </div>
                                                        </div>
                                                        <div className='text-content' >
                                                            <div className='icon-content'>
                                                                <i className="fa-solid fa-dove"></i>
                                                            </div>
                                                            <div className="text" style={{ width: '50%' }}>
                                                                <p>
                                                                    {String(item.text).slice(0, 150)}...
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <img alt="..." className='img-top' src={item.name.image} />
                                                    </div> */}
                                                    <div className='d-flex flex-column justify-content-center align-items-center h-100 w-100 quote-carousel'>
                                                        <div className="bg-cover"></div>
                                                        <div className='d-flex flex-column justify-content-center align-items-center border-carousel'>
                                                            <Avatar size={120} src={item.name.image} className='avatar' />
                                                            <div className='text-content text-center'>
                                                                <span className='icon icon-dove'><i className="fa-solid fa-dove"></i></span>
                                                                <span className='px-2 text'> {String(item.text).slice(0, 130)}...</span>
                                                            </div>
                                                            <div className='d-flex flex-column justify-content-center align-items-center '>
                                                                <p className='name'>{item.name.first_name} {item.name.second_name} {item.name.third_name} {item.name.last_name}</p>
                                                                {/* <p className='description'>{item.name.description}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            }
                            {qoute.length > 0 &&
                                <div className="col-xl-12 mt-3 quote-page-card">
                                    <p>أبرز الخواطر</p>
                                    <div>
                                        <Swiper
                                            loop={true}
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            breakpoints={{
                                                640: {
                                                    slidesPerView: 2,
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                },
                                                880: {
                                                    slidesPerView: 3,
                                                },
                                                1024: {
                                                    slidesPerView: 4,
                                                },
                                                1350: {
                                                    slidesPerView: 5,
                                                },
                                            }}
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="quote-page-swiper-quote"
                                            style={{
                                                '--swiper-navigation-color': '#aaa'
                                            }}
                                        >
                                            {qoute.map((item, index) => {
                                                return (
                                                    <SwiperSlide key={item.id} className='pb-5'>
                                                        <a href={`/thoughts/list/${item.id}/`} className="card-quote">
                                                            <div className='info-content'>
                                                                <Avatar className='avatar' size={100} src={item.name.image !== null ? item.name.image : defaultProfile2} />
                                                                <div className='icon-content'>
                                                                    <i className="fa-solid fa-dove"></i>
                                                                </div>
                                                                <div className="text">
                                                                    <p>
                                                                        {String(item.text).slice(0, 150)}...
                                                                    </p>
                                                                </div>
                                                                <div className="subtitle">
                                                                    <p>{item.name.first_name} {item.name.second_name} {item.name.third_name} {item.name.last_name}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            }
                            {dead.length > 0 &&
                                <div className="col-xl-12 quote-page-card">
                                    <p>أبرز أصحاب الخواطر</p>
                                    <div className="container-fluid">
                                        <div className="row justify-content-center align-items-center g-2">
                                            {dead.map((item) => {
                                                return (
                                                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-12">
                                                        <div key={item.id} className="card-author">
                                                            <a href={`/thoughts/dead/${item.id}/`} className='card-author-content'>
                                                                <Avatar src={item.image} className='avatar' size={100} />
                                                                <p className='title-1'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                                                <p className='title-2'>{item.place_birth !== null ? item.place_birth.name : ''}</p>
                                                                <div className='info-count'>
                                                                    <p>
                                                                        <span>عدد الخواطر</span>
                                                                        <span>{item.q_count}</span>
                                                                    </p>
                                                                    <p>
                                                                        <span>عدد المتابعين</span>
                                                                        <span>150</span>
                                                                    </p>
                                                                </div>
                                                            </a>
                                                            <Button variant='' className='but-flow'>متابعة</Button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
                                                <Button variant='' href='/thoughts/dead/' className='but-more'>المزيد</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </> : <Empty />}
                </div>
            </div>
        </div>
    )
}
