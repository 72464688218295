import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import axios from 'axios';
import { Avatar, Input, Button } from 'antd';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { defaultProfile2 } from '../../funcAndVar/var';
import { AuthorCard } from '../../components/cards';
import { LuLayoutList } from 'react-icons/lu';

export default function QuoteHome() {
    const [loading, setLoading] = useState(true)
    const [qoute, setQoute] = useState([]);
    const [dead, setDead] = useState([]);
    const [search, setSearch] = useState('');
    const [deadSearch, setDeadSearch] = useState([]);

    useEffect(() => {
        document.title = 'الأقتباسات'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/quotes/?type=0`)
                .then(res => {
                    setQoute(res.data.results);
                })

        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/Death/?has_quote=true&page_size=6')
                .then(res => {
                    setDead(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Death/?has_quote=true&search=${search}`)
                .then(res => {
                    setDeadSearch(res.data.results)
                })
        }
        getData()
    }, [search])
    if (loading) {
        return <Loading />
    }
    return (
        <div className="quote-page">
            <div className="container-fluid m-0 p-0 position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-xl-5 col-lg-6 quote-page-search">
                        <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                            <Input
                                type='text'
                                size='large'
                                placeholder='بحث'
                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                style={{
                                    borderRadius: 5,
                                    width: '100%'
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search &&
                                <div className='quote-page-search-content'>
                                    {deadSearch.map((item) => {
                                        return (
                                            <a href={`/quote/dead/${item.id}/`} key={item.id} className='search-item'>
                                                <Avatar src={item.image !== null ? item.image : defaultProfile2} className='' size={75} />
                                                <p className='neme'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                            </a>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    {qoute.length > 0 ?
                        <>
                            {qoute.length > 0 &&
                                <div className="col-12 p-0 m-0">
                                    <Swiper
                                        loop={true}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="quote-page-swiper swiper"
                                        style={{
                                            '--swiper-navigation-color': '#fff',
                                            "--swiper-pagination-color": "#fff",
                                        }}
                                    >
                                        {qoute.map((item, index) => {
                                            return (
                                                <SwiperSlide key={item.id}>
                                                    <div className='d-flex flex-column justify-content-center align-items-center h-100 w-100 quote-carousel'>
                                                        <div className="bg-cover"></div>
                                                        <div className='d-flex flex-column justify-content-center align-items-center border-carousel'>
                                                            <Avatar size={120} src={item.name.image} className='avatar' />
                                                            <div className='text-content text-center'>
                                                                <span className='icon'><i className="fa-solid fa-quote-right"></i></span>
                                                                <span className='px-2 text'>{item.text}</span>
                                                                <span className='icon'><i className="fa-solid fa-quote-left"></i></span>
                                                            </div>
                                                            <div className='d-flex flex-column justify-content-center align-items-center '>
                                                                <p className='name'>{item.name.first_name} {item.name.second_name} {item.name.third_name} {item.name.last_name}</p>
                                                                {/* <p className='description'>{item.name.description}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            }
                            {qoute.length > 0 &&
                                <div className="col-xl-12 mt-3 quote-page-card">
                                    <p>أبرز الأقتباسات</p>
                                    <div>
                                        <Swiper
                                            loop={true}
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            breakpoints={{
                                                640: {
                                                    slidesPerView: 2,
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                },
                                                1024: {
                                                    slidesPerView: 3,
                                                },
                                                1350: {
                                                    slidesPerView: 4,
                                                },
                                            }}
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="quote-page-swiper-quote"
                                            style={{
                                                '--swiper-navigation-color': '#aaa'
                                            }}
                                        >
                                            {qoute.map((item, index) => {
                                                return (
                                                    <SwiperSlide key={item.id} className='pb-5'>
                                                        <div className="card-quote">
                                                            <div className='info-content'>
                                                                <Avatar className='avatar' size={100} src={item.name.image} />
                                                                <div className='icon-content'>
                                                                    <i className="fa-solid fa-quote-left"></i>
                                                                </div>
                                                                <div className="text">
                                                                    <p>
                                                                        {item.text}
                                                                    </p>
                                                                </div>
                                                                <div className="subtitle">
                                                                    <p>{item.name.first_name} {item.name.second_name} {item.name.third_name} {item.name.last_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            }
                            {dead.length > 0 &&
                                <div className="col-xl-12 quote-page-card m-1">
                                    <p>أبرز أصحاب الأقتباسات</p>
                                    <div className="container-fluid">
                                        <div className="row justify-content-center align-items-center g-2">
                                            {dead.map((item) => {
                                                return (
                                                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-12">
                                                        <AuthorCard
                                                            id={item.id}
                                                            title='شهيد'
                                                            path={`/quote/dead/${item.id}/`}
                                                            name={`${item.first_name} ${item.second_name} ${item.third_name} ${item.last_name}`}
                                                            image={item.image}
                                                            infoTitleOne='أقتباس'
                                                            infoTitleTow='متابع'
                                                            infoCountOne={item.q_count}
                                                            infoCountTow={50}
                                                        />
                                                    </div>
                                                )
                                            })}

                                            <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
                                                <Button icon={<LuLayoutList />} href='/quote/dead/' className='but-more'>المزيد</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </> : <Empty />}
                </div>
            </div>
        </div>
    )
}
