import { Avatar, Button } from 'antd'
import React from 'react'
import { defaultProfile } from '../../funcAndVar/var';
import { SlUserFollowing } from "react-icons/sl";

export function AuthorCard(props) {
    const { id, title, path, name, image, infoTitleOne, infoTitleTow, infoCountOne, infoCountTow } = props;
    return (
        <div key={id} className="card-author shadow" style={{ backgroundColor: '#fff' }}>
            <a href={path} className='card-author-content'>
                <Avatar src={image !== null ? image : defaultProfile} className='avatar' size={100} />
                <p className='avatar-title'>{title}</p>
                <p className='title-1 f-bold'>{name}</p>
                <div className='info-count'>
                    <p>
                        <span>{infoCountOne}</span>
                        <span>{infoTitleOne}</span>
                    </p>
                    <p>
                        <span>{infoCountTow}</span>
                        <span>{infoTitleTow}</span>
                    </p>
                </div>
            </a>
            <Button variant='' icon={<SlUserFollowing />} className='but-flow'>متابعة</Button>
        </div>
    )
}
