import { Avatar, Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { IoIosBook } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { navPage } from '../../funcAndVar/func';
import { useCookies } from 'react-cookie';
import Loading from '../../components/Loading';
import PaginationPage from '../../components/Pagination';
import { defaultProfile } from '../../funcAndVar/var';

export default function BooksList() {
    const [loading, setLoading] = useState(true)
    const { id, name } = useParams()
    const [data, setData] = useState('')
    const [title, setTitle] = useState('')
    const [cover, setCover] = useState(null)
    const [image, setImage] = useState(null)
    const [books, setBooks] = useState([])
    const [searchBook, setSearchBook] = useState('')
    const [booksSearch, setBooksSearch] = useState([])
    const [author, setAuthor] = useState([])
    const [authorNav, setAuthorNav] = useState([])
    const [catogary, setCatogary] = useState([])
    const [publisher, setPublisher] = useState([])
    const [searchAuthor, setSearchAuthor] = useState('')
    const [searchCatogary, setSearchCatogary] = useState('')
    const [searchBooksSearch, setSearchBooksSearch] = useState([])
    const [count, setCount] = useState(0);
    const [searchPublisher, setSearchPublisher] = useState('')
    const [page, setPage] = useCookies('');
    const { author_book, category_page, publisher_page } = page;
    useEffect(() => {
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            if (name === 'category') {
                await axios.get(`/shared/api/v1/categorys/${id}/`)
                    .then(res => {
                        setData(res.data)
                        setTitle(res.data.name)
                        setImage(res.data.image)
                        document.title = res.data.name
                    })
            }
            if (name === 'publisher_name') {
                await axios.get(`/library/api/v1/publishers/${id}/`)
                    .then(res => {
                        setData(res.data)
                        setTitle(res.data.name)
                        document.title = res.data.name
                    })
            }
            if (name === 'author') {
                await axios.get(`/shared/api/v1/authors/${id}/`)
                    .then(res => {
                        const name = `${res.data.first_name} ${res.data.second_name} ${res.data.last_name}`
                        setData(res.data)
                        setTitle(name)
                        setCount(res.data.cover)
                        setImage(res.data.image)
                        document.title = name
                    })
            }
        }
        getData()
    }, [id])
    let pagination = 1;
    if (name === 'category') {
        pagination = category_page ? category_page : 1;
    } else if (name === 'publisher_name') {
        pagination = publisher_page ? publisher_page : 1;
    } else if (name === 'author') {
        pagination = author_book ? author_book : 1;
    }
    const onPagination = (e) => {
        if (name === 'category') {
            setPage('category_page', e)
        } else if (name === 'publisher_name') {
            setPage('publisher_page', e)
        } else if (name === 'author') {
            setPage('author_book', e)
        }
    }
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/?page_size=40&${name}=${id}&page=${pagination}&search=${searchBook}`)
                .then(res => {
                    setCount(res.data.count);
                    setBooks(res.data.results)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        if (name === 'category') {
                            setPage('category_page', 1)
                        } else if (name === 'publisher_name') {
                            setPage('publisher_page', 1)
                        } else if (name === 'author') {
                            setPage('author_book', 1)
                        }
                    }
                })
        }
        getData()
    }, [pagination, searchBook, name, id])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/?page_size=5&search=${searchBooksSearch}`)
                .then(res => {
                    setBooksSearch(res.data.results)
                })
        }
        getData()
    }, [searchBooksSearch])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/authors/?type=0&page_size=5&search=${searchAuthor}`)
                .then(res => {
                    setAuthorNav(res.data.results)
                })
        }
        getData()
    }, [searchAuthor])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=6&page_size=5&search=${searchCatogary}`)
                .then(res => {
                    setCatogary(res.data.results)
                })
        }
        getData()
    }, [searchCatogary])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/publishers/?page_size=5&search=${searchPublisher}`)
                .then(res => {
                    setPublisher(res.data.results)
                })
        }
        getData()
    }, [searchPublisher])
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid mt-3">
                <div className="row justify-content-start align-items-start g-2">
                    <div className='header-link'>
                        <a className='header-link-item' href={`/library/`}>مكتبة جمال</a>
                        <a className='header-link-item'>التصنيفات</a>
                        <a className='header-link-item'>{title}</a>
                    </div>
                    {/* <div className="col-lg-12 d-flex justify-content-center align-items-start">
                        <Input
                            type='text'
                            size='large'
                            placeholder='بحث'
                            suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                            style={{
                                borderRadius: 5,
                                width: '50%'
                            }}
                            onChange={(e) => setSearchBook(e.target.value)}
                        />
                    </div> */}


                    <div className='col-xxl-2 col-xl-3  mt-lg-2 order-1 order-xl-0'>

                        <div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>الأقسام</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <Input
                                                type='text'
                                                placeholder='بحث'
                                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                style={{
                                                    borderRadius: 5,
                                                }}
                                                onChange={(e) => setSearchCatogary(e.target.value)}
                                            />
                                        </div>
                                        <div className='ibrary-home-author'>
                                            {catogary.map(item => {
                                                return (
                                                    <a key={item.id} href={`/library/category/${item.id}/`} className='ibrary-home-author-card'>
                                                        <div
                                                            className={'news-category-radio-active'}
                                                        />
                                                        <p>{item.name}</p>
                                                    </a>
                                                )
                                            })}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>دور النشر</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <Input
                                                type='text'
                                                placeholder='بحث'
                                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                style={{
                                                    borderRadius: 5,
                                                }}
                                                onChange={(e) => setSearchPublisher(e.target.value)}
                                            />
                                        </div>
                                        <div className='ibrary-home-author'>
                                            {catogary.map(item => {
                                                return (
                                                    <a key={item.id} href={`/library/publisher_name/${item.id}/`} className='ibrary-home-author-card'>
                                                        <div
                                                            className={'news-category-radio-active'}
                                                        />
                                                        <p>{item.name}</p>
                                                    </a>
                                                )
                                            })}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>المؤلفين</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <Input
                                                type='text'
                                                placeholder='بحث'
                                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                style={{
                                                    borderRadius: 5,
                                                }}
                                                onChange={(e) => setSearchAuthor(e.target.value)}
                                            />
                                        </div>
                                        <div className='ibrary-home-author'>
                                            {authorNav.map(item => {
                                                return (
                                                    <a key={item.id} href={`/library/author/${item.id}/`} className='ibrary-home-author-card'>
                                                        <Avatar src={item.image !== null ? item.image : defaultProfile} size={40} />
                                                        <p>{item.first_name} {item.second_name} {item.last_name}</p>
                                                    </a>
                                                )
                                            })}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>الكتب</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <Input
                                                type='text'
                                                placeholder='بحث'
                                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                style={{
                                                    borderRadius: 5,
                                                }}
                                                onChange={(e) => setSearchBooksSearch(e.target.value)}
                                            />
                                        </div>
                                        {searchBooksSearch &&
                                            <div style={{ width: '100%' }}>
                                                {booksSearch.map((item) => {
                                                    return (
                                                        <div key={item.id} className='ibrary-home-books-card' style={{ width: '100%' }}>
                                                            <img alt="..." src={item.cover !== null ? item.cover : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/bookcover.jpg'} className='ibrary-home-books-card-image' />
                                                            <div className='ibrary-home-books-card-info'>
                                                                <a href={`/library/book/${item.id}/`}>
                                                                    <p className='ibrary-home-books-card-title'>{item.title}</p>
                                                                </a>
                                                                {item.author !== null ?

                                                                    <a href={`/library/author/${item.author.id}/`}>
                                                                        <p className='ibrary-home-books-card-author'>{item.author.first_name} {item.author.second_name} {item.author.last_name}</p>
                                                                    </a>
                                                                    : null
                                                                }

                                                                <Button variant='' href={`/library/book/${item.id}/`} className='ibrary-home-books-card-but'>قراءة</Button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>

                    <div className="col-xxl-10 col-xl-9  order-0 order-xl-1">
                        <div className="container-fluid">

                            <div className="row justify-content-start align-items-start g-2 m-0">
                                <p className='f-bold border-bottom  pb-3 mb-3'><IoIosBook size={20} color='#23b5ab' /> الكتب</p>
                                {books.map((item) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className='col-xxl-3 col-lg-4 col-md-6 col-sm-6'
                                        >
                                            <div className='card-list'>
                                                <div  className='ibrary-home-books-card'>
                                                    <img alt="..." src={item.cover !== null ? item.cover : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/bookcover.jpg'} className='ibrary-home-books-card-image' />
                                                    <div className='ibrary-home-books-card-info'>
                                                        <div className='h-100'>
                                                            <a href={`/library/book/${item.id}/`}>
                                                                <p className='ibrary-home-books-card-title'>{item.title}</p>
                                                            </a>
                                                            {item.author !== null ?
                                                                <a href={`/library/author/${item.author.id}/`}>
                                                                    <p className='ibrary-home-books-card-author'>{item.author.first_name} {item.author.second_name} {item.author.last_name}</p>
                                                                </a>
                                                                : null
                                                            }
                                                        </div>
                                                        <Button variant='' href={`/library/book/${item.id}/`} className='ibrary-home-books-card-but'>قراءة</Button>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    )
                                })}
                                <div className='col-12'>
                                    <PaginationPage
                                        defaultCurrent={Number(pagination)}
                                        total={count}
                                        defaultPageSize={40}
                                        onChange={onPagination}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
