import Carousel from 'react-bootstrap/Carousel';
import { Media, Milidia } from '../../style/svg/Logo';
import { defaultCover } from '../../funcAndVar/var';
import { MdEvent } from 'react-icons/md';

export default function CarouselHome({ activeIndex, onSelect, data }) {
    const emptyArray = JSON.stringify([]);
    return (
        <Carousel fade className='carousel-serves' activeIndex={activeIndex} onSelect={onSelect}>
            {data.map(item => {
                const imagesAll = JSON.stringify(item.images);
                return (
                    <Carousel.Item key={item.id}>
                        <img
                            alt="..."
                            className="border-radius-15"
                            src={imagesAll !== emptyArray ? item.images[0].image : defaultCover}
                        />
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}
export function CarouselHomeTwo({ activeIndex, onSelect, data }) {
    return (
        <Carousel fade className='carousel-serves-two' controls={false} indicators={false} activeIndex={activeIndex} onSelect={onSelect}>
            {data.map(item => {
                return (
                    <Carousel.Item key={item.id}>
                        <div className='carousel-serves-item'>
                            <p className='carousel-serves-title'>{item.name}</p>
                            <p className='carousel-serves-date'><MdEvent /> {item.avent_date}</p>
                            <p className='carousel-serves-dis'>
                                {item.description}
                            </p>
                        </div>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}
