import * as React from "react"

const Logo = ({ className, width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 712 853.01166"
        width={width}
        height={height}
    >
        <g className={className + '0'}>
            <path d="M712 0v283.35H544.35L381.64 461.1l232.73 241.53-106.88 114.51c-44.48 47.66-118.67 47.85-163.39.44L3.94 456.91l161.73-173.56H0v-56.56C0 101.54 99 0 221.13 0H712Z" />
            <path fillRule="evenodd" d="M691.89 462 585.3 564.46 478 462l107.3-106" />
        </g>
    </svg>
)

export const Milidia = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 640 480"
        style={{
            enableBackground: "new 0 0 640 480",
        }}
        xmlSpace="preserve"
        width="10em"
        height="5em"
    >
        <style>
            {".st1,.st4{fill:#23b5ab;stroke:#23b5ab;stroke-width:4;stroke-miterlimit:10}.st4{fill:none;stroke-width:3}.st5{fill:#23b5ab}"}
        </style>
        <path
            d="M166.7 34.7v207.5c0 54.6-35.9 67.3-68.3 67.3-45.7 0-62.4-31.5-63-67.6.3-19.6 5.4-39.8 14.1-55.8-.5-.8-.9-1.7-1.2-2.7-9.1 16.1-14.6 36.7-15.2 56.6-.1.7-.1 1.3-.1 2v1.9h.1c1.1 38.1 19.5 72.8 65.3 72.8 31.9 0 70.7-13.7 70.7-74.5V34.7h-2.4z"
            style={{
                fill: "#23b5ab",
                stroke: "#23b5ab",
                strokeWidth: 6,
                strokeMiterlimit: 10,
            }}
        />
        <path
            className="st1"
            d="M264.1 303.8c-.2 1.8-1.9 3.1-3.7 3.1h-7.3c-29.6 0-46.7-17-46.7-61V111.7h2.4V246c0 39.4 14.6 53.8 44.3 53.8h7.4c2.3-.1 3.9 1.7 3.6 4z"
        />
        <path
            className="st1"
            d="M258 299.7h2.3c21.7 0 38.3-12.8 42.2-31.7l2.5-12.1c3.7-27.6 27-45.1 51.5-45.1 26.3 0 48.6 20.6 51.2 47.3l1.7 10.1c3.2 18.7 20.5 31.6 42.2 31.6h2.3c1.8 0 3.4 1.3 3.7 3.1.3 2.3-1.3 4.1-3.6 4.1h-2.4c-20.3 0-39-12.4-44.2-35-2.9 25.6-19.2 46-51 46-27.4 0-50.4-18.2-51.8-47-5.6 23.9-24.4 36.1-44.4 36.1h-2.4c-2.2 0-3.9-1.8-3.6-4.1.4-2 2-3.3 3.8-3.3zm98.6-84.1c-22.4 0-43 14.3-48.2 36.6l.2.1-1.3 6.2c-.1 1.7-.2 3.4-.2 5v4.2c0 25.7 21.2 42.8 49.6 42.8 34 0 49.1-20.6 49.1-47v-.7c0-1 0-1.8-.1-2.8l-1.2-7.9c-5.3-20.9-24.4-36.5-47.9-36.5z"
        />
        <path
            d="M469.5 306.9h-17.9c-1.8 0-3.4-1.3-3.7-3.1-.3-2.3 1.3-4.1 3.6-4.1h18c32.5 0 85.1-6.8 151.6-50.5-27.2-6.7-41-13.9-73-32.9-32.5-19.2-54-8.8-72.1 5.5l.3-.2c19.6-15.4 39.4-29 72.5-9.4 32.3 19.2 46 26 77.5 33.5v6.5c-71.5 48.2-124.5 54.7-156.8 54.7z"
            style={{
                fill: "#23b5ab",
                stroke: "#23b5ab",
                strokeWidth: 3,
                strokeMiterlimit: 10,
            }}
        />
        <path
            d="M168.3 34.7c4.3.5 36.6 4.1 51.9 29 6.8 11.1 8.6 22.3 9.1 29l-61.5-25.2"
            style={{
                fill: "none",
                stroke: "#23b5ab",
                strokeWidth: 6,
                strokeMiterlimit: 10,
            }}
        />
        <path
            className="st4"
            d="m652.1 19.6-417.3.6M681.1 117.6l-462.3.6M681.1 215.6l-119.3.6M464.8 217.2h-74M320.3 218.2h-99.5M161.8 218.2h-116M33.3 218.2h-71.5M156.1 19.6l-196.3.6M52.1 121.6l-88.3.6M153.1 119.6l-48.3.6M676.1 317.6l-694.7 1.3"
        />
        <path
            className="st5"
            d="m588.8 315.2-3.2-3.2c-2.9-3-2.9-7.8.1-10.7l3.2-3.1M589 298.2l3.5 3.5c2.7 2.7 2.8 7.2 0 9.9l-3.6 3.7"
        />
        <path
            d="M461.2 32.4c-20.9 4.9-41.2 9.7-61.6 14.2-3.4.8-3.7 2.4-3.6 5.2.3 17 .4 34 .6 51 .1 12.8-7.2 21.3-20.2 22.5-3.9.4-8.1.2-11.8-1.1-7.6-2.5-9.8-10.2-5.4-17 6.2-9.6 19.6-13.1 33.3-8.3 0-2 0-3.7-.1-5.4-.3-22.3-.5-44.6-.9-66.9 0-2.5.2-4 3.1-4.6 22.2-5.1 44.3-10.3 66.5-15.4 1.1-.2 2.2-.1 3.9-.1 0 2.2 0 4 .1 5.8l.9 76.9c.1 12.2-5.1 19.3-17.1 22-3.8.9-8 1-11.8.4-10.6-1.7-13.9-11.4-7.1-19.7 6.2-7.5 19-11 28.2-7.8 1.1.4 2.1.8 3.6 1.4-.2-17.8-.4-35.1-.6-53.1zm-.1-21.8c-21.3 4.9-42.1 9.7-62.9 14.6-1 .2-2.3 1.6-2.3 2.5-.2 5.1 0 10.1.1 15.8 22.3-5.2 43.9-10.2 65.4-15.1-.2-6.1-.2-11.5-.3-17.8zm-19.2 97.7c7.4-.6 14-2.7 18.4-8.9 3.6-5 2-9.8-3.8-11.8-7.4-2.6-19.1.9-23.9 7.3-4.2 5.6-2.2 11.2 4.6 12.8 1.7.4 3.3.4 4.7.6zm-70.5 13.4c8.6-.4 15.1-2.8 19.6-9.1 3-4.3 1.9-8.6-2.6-10.9-8-4-22.2.5-26.3 8.4-2.3 4.4-1 8.3 3.6 10.2 2.1 1 4.5 1.2 5.7 1.4z"
            style={{
                fill: "#0c9",
                stroke: "#23b5ab",
                strokeWidth: 4,
                strokeMiterlimit: 10,
            }}
        />
        <ellipse
            transform="rotate(-16.443 67.902 178.996)"
            cx={67.9}
            cy={179}
            rx={19.3}
            ry={13.8}
            style={{
                fill: "none",
                stroke: "#23b5ab",
                strokeWidth: 5.9999,
                strokeMiterlimit: 9.9994,
            }}
        />
    </svg>
)

export const Media = () => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 595.15 211.35"
        width="7em"
        height="5em"
    >
        <defs>
            <style>{".cls-1{fill:#23a5ab}.cls-3{fill:#fcee21}"}</style>
        </defs>
        <path
            className="cls-1"
            d="M612 151v70.21h-42.43l-41.19 44 58.91 59.84-27 28.37a28.62 28.62 0 0 1-41.36.11l-86.1-89.36 40.94-43h-41.99v-14a56.08 56.08 0 0 1 56-56.19Z"
            transform="translate(-16.85 -151)"
        />
        <path
            style={{
                fill: "#ffe013",
            }}
            className="cls-2"
            d="m567.47 139.19-27.95-26.94 27.95-27.86"
        />
        <path
            className="cls-3"
            d="M114.35 311.42h39.46a4 4 0 0 0 4.16-3.79v-4.87a2.89 2.89 0 0 0-3.8-2.56l-39.82 11.22ZM295.35 311.49h39.45a4 4 0 0 0 4.2-3.79v-4.88a2.9 2.9 0 0 0-3.81-2.55l-39.81 11.22Z"
            transform="translate(-16.85 -151)"
        />
        <path
            className="cls-1"
            d="M360.92 253.13v21.93H350.1v-21.93h-14.44v21.93h-12v-19.42a9.22 9.22 0 0 0-9.48-8.94h-5.76v28.36h-20.51c-7.29 0-13.2 5.57-13.2 12.45v2.67h127.92v-28.54a8.78 8.78 0 0 0-9-8.51Zm26.47 21.93h-11.63v-8.7h11.63ZM31.69 228.55V277h217.75v-23.87h1.65c6.18 0 11.18 4.72 11.18 10.54v26.51H16.85v-47.64c0-7.73 6.65-14 14.84-14"
            transform="translate(-16.85 -151)"
        />
    </svg>
)

export default Logo