import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { CiTextAlignRight } from 'react-icons/ci';
import { MdDashboard, MdQueryBuilder, MdRssFeed } from 'react-icons/md';
import { TbNews } from "react-icons/tb";
import { useParams } from 'react-router-dom'
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { defaultCover2 } from '../../funcAndVar/var';
import { NewsCardMore } from '../../components/cards';

export default function NewsItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [news, setNews] = useState('');
    const [tag, setTag] = useState('');
    const [images, setImages] = useState([]);
    const [moreNews, setMoreNews] = useState([]);
    useEffect(() => {
        async function getData() {
            await axios.get(`/oasis/api/v1/news/${id}/`)
                .then(res => {
                    setNews(res.data)
                    setImages(res.data.images)
                    setTag(res.data.tags)
                    document.title = res.data.title
                })
            await axios.get(`/oasis/api/v1/news/?page_size=5`)
                .then(res => {
                    setMoreNews(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        navPage()
    }, [])
    const imagesAll = JSON.stringify(images)
    const emptyArray = JSON.stringify([]);
    const tags = tag.split(',')
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/news/`}>الأخبار</a>
                            <a className='header-link-item'>{news.title.slice(0, 30)}...</a>
                        </div>
                    </div>
                    <div className="col-lg-11 col-md-12 col-sm-12">
                        <div className='news-item-content'>
                            <div className='news-item-content-header'>
                                <img alt="..." src={imagesAll !== emptyArray ? images[0].image : defaultCover2} className='news-item-content-image' />
                                <div className="bg-images bg-video" style={{ zIndex: 1, height: '100%', borderRadius: 0 }} />
                                <div className='news-card-info'>
                                    <p className='news-card-info-tag'><CiTextAlignRight size={18} /> {tags[0] ? tags[0] : 'عامة'}</p>
                                    <p className='news-card-info-title text-light'>{news.title}</p>
                                    <p className='news-card-info-date'><MdQueryBuilder size={14} /> {String(news.created).slice(11, 19)}   {String(news.created).slice(0, 10)}</p>
                                </div>
                            </div>
                            <p className='news-item-content-title text-dark'><TbNews size={25} /> {news.title}</p>
                            <div className='news-item-tags'>
                                {news.agency !== null ? <a className='tags-link'><MdRssFeed color='#23b5ab' size={20} /> {news.agency.name}</a> : null}
                                {news.category !== null ? <a className='tags-link' href={`/news/category/${news.category.id}/`}><MdDashboard color='#23b5ab' size={20} /> {news.category.name}</a> : null}
                            </div>
                            <div className="container-fluid mt-2">
                                <div className="row justify-content-center align-items-start g-2">
                                    <div className="col-xxl-8 col-xl-6 col-md-12 col-sm-12">
                                        <div className="container-fluid">
                                            <div className="row justify-content-center align-items-start g-2">

                                                {imagesAll !== emptyArray
                                                    && <div className="col-12 d-flex justify-content-center align-items-center">
                                                        <Carousel indicators={false} className='news-item-content-carousel'>
                                                            {images.map((item, imdex) => {

                                                                return (
                                                                    <Carousel.Item key={item.id}>
                                                                        <img alt="..."
                                                                            src={item.image}
                                                                        />
                                                                    </Carousel.Item>
                                                                )
                                                            })}
                                                        </Carousel>
                                                    </div>}
                                                <div className="col-12 d-flex justify-content-center align-items-start">
                                                    <p className='news-item-content-summary'>{news.summary}</p>
                                                </div>
                                                <div className="col-12">
                                                    <div className='news-item-tags'>
                                                        {tags.map((item, i) => {
                                                            if (!item) return null;
                                                            return <a className='tags-link color-tags'>#{item}</a>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                                        <div className='news-item-content-more'>
                                            <p className='more-title'><CiTextAlignRight size={20} /> أخر الأخبار</p>
                                            <div className='news-item-content-more-items'>
                                                {moreNews.map((item, index) => {
                                                    if (item.id === Number(id)) return null
                                                    return (
                                                        <NewsCardMore
                                                            id={item.id}
                                                            title={item.title}
                                                            path={`/news/${item.id}/`}
                                                            images={item.images}
                                                            tag={item.tags}
                                                            summary={item.summary}
                                                            created={item.created}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}