import React from 'react'
import { defaultCover, defaultCover2 } from '../../funcAndVar/var';
import { LuLayoutList } from 'react-icons/lu';
import { Button } from 'antd';
export default function NewsCard({ data, path, more }) {
    const emptyArray = JSON.stringify([]);
    const images_more = data[4].images;
    const imagesmore = JSON.stringify(images_more)
    return (
        <div className='card-news-content' >
            {data.map((item, index) => {
                const images = item.images;
                const imagesAll = JSON.stringify(images)
                const tag = item.tags;
                const tags = tag.split(',')
                if (index === 4) return null;
                return (
                    <a href={`${path}${item.id}/`} className='card-news-item' key={index + 1} style={{ backgroundImage: `url(${imagesAll !== emptyArray ? images[0].image : defaultCover2})` }} >
                        <div className='card-image-bg' />
                        <div className='news-title'>
                            <p style={{ opacity: 1 }}>#{tags[0] ? tags[0] : 'عامة'}</p>
                            <h4>{item.title}</h4>
                            <p>{String(item.summary).slice(0, 300)}</p>
                        </div>
                    </a>
                )
            })}
            <a className='card-news-item more-item' href={path} style={{ backgroundImage: `url(${imagesmore !== emptyArray ? images_more[0].image : defaultCover2})` }} >
                <div className='card-image-bg' />
                <div className='news-title'>
                    <p style={{ opacity: 1 }}>#{'عامة'}</p>
                    <h4>{data[4].title}</h4>
                    <p>{String(data[4].summary).slice(0, 300)}</p>
                </div>
                <div className='more-news-title'>
                    <p>{more}</p>
                </div>
            </a>
        </div>
    )
}
