import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { MdQueryBuilder } from "react-icons/md";
import Player from '../videos_player/Player';
import { defaultCover2 } from '../../funcAndVar/var';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
export default function VideoHome({ data, firstItem }) {
    const [first, setFirst] = useState('')
    const [url, setUrl] = useState('')
    const onPlay = (item, index) => {
        setUrl(item.file)
        setFirst(item)
    }
    return (
        <div className='video-home'>
            <div className='video-home-player'>
                {firstItem &&
                    <div className='video-player'>
                        <Player
                            url={url ? url : (data[0] ? data[0].file : 'http://example.com/audio.mp4')}
                            firstItem={firstItem}
                            first={first}
                        />
                    </div>
                }
            </div>
            <div className='video-home-items'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    loop={true}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    modules={[Autoplay]}
                    className='mx-1 px-1'
                >
                    {data.map((item, index) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <div
                                    onClick={() => onPlay(item, index)}
                                    className='video-home-card'
                                >
                                    <div className='video-image-content'>
                                        <img alt="..." src={item.cover !== null ? item.cover : defaultCover2}
                                            className='video-home-card-image'
                                        />
                                        <div className="bg-images bg-video" />
                                        <div className='video-info-content'>
                                            <p className='video-home-card-title f-bold' style={{ fontSize: 12, }}>{item.name}</p>
                                            <p className='video-home-card-title ' style={{ fontSize: 10, }}>{item.author !== null ? `${item.author.first_name} ${item.author.second_name} ${item.author.last_name}` : ''}</p>
                                        </div>
                                        <Button variant='' onClick={() => onPlay(item, index)} className='play-video' ><i className="fa-solid fa-play"></i></Button>
                                    </div>
                                    <p className='date-video-home f-bold' style={{ fontSize: 10, }}><MdQueryBuilder /> {String(item.created).slice(0, 10)}</p>
                                </div>
                            </SwiperSlide>
                        )
                    })
                    }
                </Swiper>
            </div>
        </div>
    )
}
