import React, { useEffect } from 'react'
import { navPage } from '../../funcAndVar/func'
import { Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { Button } from 'react-bootstrap'

export default function SpeakUp() {
  useEffect(() => {
    document.title = 'قم بالإبلاغ'
    navPage()
  }, [])
  return (
    <div>
      <div className="container-fluid p-0 m-0">
        <div className="row justify-content-center align-items-center g-2">
          <div className="col-12 speak-up">
            <div className="container-fluid">
              <div className="row justify-content-center align-items-center g-2">
                <div className="col-lg-8 col-12">
                  <div className='d-flex flex-column mt-2'>
                    <p className='fs-3 f-bold text-light'>الإبلاغ عن الانتهاكات</p>
                    <p className='f-description text-light'>إذا شهدت أفعالا تتعلق بالاحتيال، الفساد، السرقة، المضايقة/التحرش، أو أي سلوك غير أخلاقي آخر في أي من مواقع بوابة جمال الرقمية، يمكنك الآن الإبلاغ عنها بشكل آمن من خلال خط المساعدة "Speak Up" لدى المجموعة، عبر استخدام القنوات المخصصة متضمنة الموقع الإلكتروني، البريد الإلكتروني، أو الخط الساخن.عن طريق هذه القنوات يمكنك البقاء مجهول الهوية إذا كنت تفضل ذالك.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-11 speak-up-form">
            <div className="container-fluid">
              <div className="row justify-content-center align-items-center g-2">
                <div className="col-12">
                  <label className='m-2 fs-6'>الأسم</label>
                  <Input size='large' placeholder='الأسم' />
                </div>
                <div className="col-12">
                  <label className='m-2 fs-6'>البريد الالكتروني</label>
                  <Input size='large' placeholder='البريد الالكتروني' />
                </div>
                <div className="col-12">
                  <label className='m-2 fs-6'>موضوع الشكوى</label>
                  <Input size='large' placeholder='موضوع الشكوى' />
                </div>
                <div className="col-12">
                  <label className='m-2 fs-6'>الشكوى</label>
                  <TextArea size='large' placeholder='الشكوى' rows={10} />
                </div>
                <div className="col-5">
                  <Button variant='' className='but-home w-100 mt-3'>رفع الشكوى</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
