import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { CiTextAlignRight } from 'react-icons/ci';
import { MdQueryBuilder } from 'react-icons/md';
import NewsAccordion from '../../components/News/NewsAccordion';
import PaginationPage from '../../components/Pagination';
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { defaultCover2 } from '../../funcAndVar/var';
import { NewsCard } from '../../components/cards';

export default function Article() {
    const [loading, setLoading] = useState(true)
    const [news, setNews] = useState([]);
    const [searchNews, setSearchNews] = useState([]);
    const [newsHeader, setNewsHeader] = useState([]);
    const [agency, setAgency] = useState([]);
    const [agencySearch, setAgencySearch] = useState('');
    const [agencyId, setAgencyId] = useState('');
    const [categorys, setCategorys] = useState([]);
    const [category, setCategory] = useState([]);
    const [categorySearch, setCategorySearch] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { page_article } = page;
    console.log(page_article)
    useEffect(() => {
        document.title = 'التدوينات'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/oasis/api/v1/articles/?author=${agencyId}&category=${categoryId}&page=${page_article ? page_article : 1}`)
                .then(res => {
                    setNews(res.data.results)
                    setCount(res.data.count)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    //err.response.data.detail= 'Invalid page.'
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('page_article', 1)
                    }
                })
        }
        getData()
    }, [agencyId, categoryId, page_article])
    useEffect(() => {
        async function getData() {
            await axios.get(`/oasis/api/v1/articles/?search=${search}`)
                .then(res => {
                    setSearchNews(res.data.results)
                })
        }
        getData()
    }, [search])
    useEffect(() => {
        async function getData() {
            await axios.get(`/oasis/api/v1/articles/?page_size=4`)
                .then(res => {
                    setNewsHeader(res.data.results)
                })
        }
        getData()
    }, [])

    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/authors/?type=2&search=${agencySearch}`)
                .then(res => {
                    setAgency(res.data.results)
                })
            await axios.get(`/shared/api/v1/categorys/?type=4&search=${categorySearch}`)
                .then(res => {
                    setCategorys(res.data.results)
                })
        }
        getData()
    }, [agencySearch, categorySearch])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=4`)
                .then(res => {
                    setCategory(res.data.results)
                })
        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid mt-1">
                <div className="row justify-content-center align-items-start g-2">
                    <div className='col-xxl-2 col-xl-3  mt-lg-2 order-1 order-xl-0'>
                        <NewsAccordion
                            type='home'
                            path='/article/'
                            article={agency}
                            category={category}
                            categorys={categorys}
                            news={searchNews}
                            search={search}
                            onChangeSearch={(e) => setSearch(e.target.value)}
                            onChangeArticle={(e) => setAgencyId(e)}
                            onSearchArticle={(e) => setAgencySearch(e)}
                            onChangeCategory={(e) => setCategoryId(e)}
                            onSearchCategory={(e) => setCategorySearch(e)}
                        />
                    </div>
                    <div className='col-xxl-9 col-xl-9  order-0 order-xl-1'>

                        <div className="container-fluid">
                            <div className="row justify-content-start align-items-start g-2">
                                <div className="col-md-12 col-sm-12">
                                    <Carousel className='carousel-news'>
                                        {newsHeader.map((item, index) => {
                                            const images = item.images;
                                            const imagesAll = JSON.stringify(images)
                                            const emptyArray = JSON.stringify([]);
                                            const tag = item.tags;
                                            const tags = tag.split(',')
                                            return (
                                                <Carousel.Item key={item.id}>
                                                    <img alt="..."
                                                        className='carousel-news-image'
                                                        src={imagesAll !== emptyArray ? images[0].image : defaultCover2}
                                                    />
                                                    <div className="bg-images bg-video" style={{ zIndex: 1, height: '100%' }} />
                                                    <Carousel.Caption style={{ zIndex: 11 }}>
                                                        <h5>{item.title}</h5>
                                                        <p className='carousel-news-summary'>{String(item.summary).slice(0, 300)} ...</p>
                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                                {news.map((item, index) => {
                                    return (
                                        <NewsCard
                                            id={item.id}
                                            title={item.title}
                                            path={`/article/${item.id}/`}
                                            images={item.images}
                                            tag={item.tags}
                                            summary={item.summary}
                                            created={item.created}
                                            className="col-md-12 col-sm-12"
                                        />
                                    )
                                })}
                                <div className='d-flex justify-content-center align-items-start'>
                                    <PaginationPage
                                        defaultCurrent={page_article ? Number(page_article) : 1}
                                        total={count}
                                        defaultPageSize={10}
                                        onChange={(e) => setPage('page_article', e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
