import { Button, Avatar, Statistic } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { SVGMap } from 'react-svg-map';
import { navPage } from '../../funcAndVar/func'
import { SvgIraq } from '../../funcAndVar/SvgIraq';
import CountUp from 'react-countup';
import { MdMilitaryTech } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import {  Modal } from 'react-bootstrap';
import Loading from '../../components/Loading';
import { defaultProfile2 } from '../../funcAndVar/var';
import { BsDatabase } from 'react-icons/bs';
export default function Dead() {
    const [loading, setLoading] = useState(true)
    const [dead, setDead] = useState([]);
    const [deadDay, setDeadDay] = useState([]);
    const [deadCount, setDeadCount] = useState(0);
    const [deadCity, setDeadCity] = useState([]);
    const [pointedLocation, setPointedLocation] = useState('');
    const [location, setLocation] = useState('');
    const [count, setCount] = useState('');
    const [tooltipStyle, setTooltipStyle] = useState({ display: 'none' });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        document.title = 'شهدائنا'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/Death/?page_size=30')
                .then(res => {
                    setDead(res.data.results)
                    setDeadCount(res.data.count)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/dead_daylist/?page_size=30')
                .then(res => {
                    setDeadDay(res.data.results)
                    if (res.status === 200) setLoading(false);
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            if (show) {
                await axios.get(`/dead_wounded/api/v1/Death/?search=${location}`)
                    .then(res => {
                        setDeadCity(res.data.results)
                        setCount(res.data.count)
                    })
            } else {
                return null
            }

        }
        getData()
    }, [location, show])
    const formatter = (value) => <CountUp end={value} separator="," />;

    function getLocationName(event) {
        return event.target.attributes.name.value;
    }
    function handleLocationMouseClick(event) {
        const pointedLocation = getLocationName(event);
        setLocation(pointedLocation);
        handleShow()
    }
    function handleLocationMouseOver(event) {
        const pointedLocation = getLocationName(event);
        setPointedLocation(pointedLocation);
    }
    function handleLocationMouseOut() {
        setPointedLocation(null);
        setTooltipStyle({ display: 'none' });
    }
    function handleLocationMouseMove(event) {
        const tooltipStyle = {
            display: 'block',
            top: event.clientY + 10,
            left: event.clientX - 100
        };
        setTooltipStyle(tooltipStyle);
    }
    if (loading) {
        return <Loading />
    }
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-md-12">
                        <div className="container-fluid mt-3 m-0 p-0">
                            <div className="row justify-content-start align-items-center g-2">
                                <div className="col-md-12 home-dead-content">
                                    <div className="container-fluid">
                                        <div className="row justify-content-center align-items-start g-2">
                                            <div className="col-xl-11">
                                                <div className="container-fluid mt-5 m-0 p-0">
                                                    <div className="row justify-content-center align-items-center g-2">
                                                        <div className="col-xl-8">
                                                            <p className='home-dead-title '>المرجع الأعلى عن الشهداء</p>
                                                            <p className='home-dead-disc'>عندما يمر ذكر شهداء الحشد الشعبي استذكر تدافع أصحاب الإمام الحسين عليه السلام الى المنية...</p>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <div className='swiper-bg'>
                                                                <Swiper
                                                                    dir="rtl"
                                                                    slidesPerView={9}
                                                                    spaceBetween={0}
                                                                    loop={true}
                                                                    autoplay={{
                                                                        delay: 2000,
                                                                        disableOnInteraction: false,
                                                                    }}
                                                                    modules={[Autoplay]}
                                                                >
                                                                    {dead.map((item, index) => {
                                                                        return (
                                                                            <SwiperSlide key={item.id}>
                                                                                <a href={`/dead/list/${item.id}/`} className="d-flex flex-column justify-content-center align-items-center">
                                                                                    <Avatar
                                                                                        size={75}
                                                                                        src={item.image !== null ? item.image : defaultProfile2}
                                                                                    />
                                                                                </a>
                                                                            </SwiperSlide>
                                                                        )
                                                                    })}
                                                                </Swiper>
                                                                <Swiper
                                                                    slidesPerView={9}
                                                                    spaceBetween={0}
                                                                    loop={true}
                                                                    className='mt-2'
                                                                    autoplay={{
                                                                        delay: 2000,
                                                                        disableOnInteraction: false,
                                                                    }}
                                                                    modules={[Autoplay]}
                                                                >
                                                                    {dead.map((item, index) => {
                                                                        return (
                                                                            <SwiperSlide key={item.id}>
                                                                                <a
                                                                                    href={`/dead/list/${item.id}/`}
                                                                                    className="d-flex flex-column justify-content-center align-items-center"
                                                                                >
                                                                                    <Avatar
                                                                                        size={75}
                                                                                        src={item.image !== null ? item.image : defaultProfile2}
                                                                                    />
                                                                                </a>
                                                                            </SwiperSlide>
                                                                        )
                                                                    })}
                                                                </Swiper>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-11 mt-5">
                                                <div className='home-dead-card'>
                                                    <div className="container-fluid">
                                                        <div className="row justify-content-center align-items-start g-2">
                                                            <div className="col-xl-6">
                                                                <p className='card-title'>الشهداء حسب المحافظات العراقية</p>
                                                                <div className='card-info'>
                                                                    <p className='card-info-text'>
                                                                        قواعد البيانات المتعلقة بشهداء الحشد الشعبي الذين قدموا أرواحهم في عمليات التحرير المقدسة من أهم الأدوات التي تساهم في الحفاظ على ذاكرة المقاومة وتكريم تضحياتهم الجليلة. فتوفير نظام متكامل وعالي الاحكام لإدارة وتوثيق بيانات الشهداء يمثل أحد أهم الخطوات في تأكيد الوفاء والاحترام للأبطال الذين ضحوا بأرواحهم في سبيل الوطن والمقدسات .فتوفير قاعدة بيانات محكمة ومنظمة للشهداء يعني الحفاظ على الذاكرة التاريخية للتضحيات والبطولات التي قدمها الحشد الشعبي. تعتبر هذه الذاكرة مصدرًا هامًا للأجيال الحالية والمستقبلية لفهم ومعرفة تاريخ الدفاع المقدس.
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <Button href='/dead/list/' icon={<BsDatabase size={20} />} variant="" className="data-but" style={{ zIndex: 10 }}>الولوج لقاعدة بيانات الشهداء </Button>
                                                                </div>

                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className='iraq-map'>
                                                                    <SVGMap
                                                                        className='svg-map svg-iraq'
                                                                        map={SvgIraq}
                                                                        onLocationMouseOver={handleLocationMouseOver}
                                                                        onLocationMouseOut={handleLocationMouseOut}
                                                                        onLocationMouseMove={handleLocationMouseMove}
                                                                        onLocationClick={handleLocationMouseClick}
                                                                    />
                                                                    <div className="map-tooltip" style={tooltipStyle}>
                                                                        {pointedLocation}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-11">
                                                <p className='home-dead-title'> المرجع الأعلى عن الشهداء</p>
                                                <p className='home-dead-disc'>
                                                    ولن تفلح أمة لا ترعى عوائل شهدائها الذين ضحوا بحياتهم وبذلوا ارواحهم في سبيل عزتها وكرامتها
                                                </p>
                                            </div>
                                            <div className={dead.length > 0 ? "col-xl-5" : 'col-xl-11'}>
                                                <div className='home-dead-card'>
                                                    <div className="container-fluid">
                                                        <div className="row justify-content-center align-items-center g-2">
                                                            <div className="col-xl-12 ">
                                                                <p className='card-title'>الشهداء</p>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <Statistic title="" value={deadCount} prefix={<MdMilitaryTech size={50} color='#23b5ab' />} formatter={formatter} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12">
                                                                <div className='card-info'>
                                                                    <p className='card-info-text'>
                                                                        في عمليات التحرير والدفاع المقدس، استطاع شهدائنا العظام  الخطو بخطواتٍ تاريخيةً تركت أثراً عميقاً في نفوسنا وفي ذاكرة الأجيال القادمة اختلفت أعمارهم وأصولهم وثقافاتهم، ولكنهم تحدوا الظروف والتحديات، متحدين الاستكبار والهيمنة والإرهاب مؤمنين بأن نيل الكرامة لا يأتيان إلا بثمنٍ غال فاكنت شهادتهم هي بداية الخلود في التاريخٍ.فقد بلغت الإحصائية الدقيقة اليوم لشهداء المسير الحسيني من أبناء الفتوى المقدسة والمقاومة الإسلامية اكثر من 10 الاف شهيد حتى تاريخ هذا اليوم
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {dead.length > 0 &&
                                                <div className="col-xl-6">
                                                    <div className='home-dead-card'>
                                                        <div className="container-fluid">
                                                            <div className="row justify-content-center align-items-center g-2">
                                                                <div className="col-xl-12 ">
                                                                    <p className='card-title'>أستشهد في مثل هذا اليوم</p>
                                                                    <Swiper
                                                                        dir="rtl"
                                                                        slidesPerView={4}
                                                                        spaceBetween={4}
                                                                        slidesPerGroup={4}
                                                                        loop={true}
                                                                    >
                                                                        {deadDay.map((item, index) => {
                                                                            return (
                                                                                <SwiperSlide key={item.id}>
                                                                                    <a href={`/dead/list/${item.id}/`} className="d-flex flex-column justify-content-center align-items-center">
                                                                                        <Avatar size={75} src={item.image !== null ? item.image : defaultProfile2} />
                                                                                        <div>
                                                                                            <p className='card-man-info-title'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                                                                        </div>
                                                                                    </a>
                                                                                </SwiperSlide>
                                                                            )
                                                                        })}
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-11">
                                                                <div className='card-info'>
                                                                    <p className='card-info-text'>للشهادة والشهيد منزلة ومقام عظيم عند الله تعالى ، والشهادة كرامة وشرف يخص بها الله تعالى اوليائه وأحباءه من دون سائر الخلق ، ومما خص به الله تعالى الشهداء وحباهم به من عظيم آياته هو قوله عز من قائل ﴿ وَلَا تَحْسَبَنَّ الَّذِينَ قُتِلُوا فِي سَبِيلِ اللَّهِ أَمْوَاتًا بَلْ أَحْيَاءٌ عِنْدَ رَبِّهِمْ يُرْزَقُونَ * فَرِحِينَ بِمَا آَتَاهُمُ اللَّهُ مِنْ فَضْلِهِ وَيَسْتَبْشِرُونَ بِالَّذِينَ لَمْ يَلْحَقُوا بِهِمْ مِنْ خَلْفِهِمْ أَلَّا خَوْفٌ عَلَيْهِمْ وَلَا هُمْ يَحْزَنُونَ * يَسْتَبْشِرُونَ بِنِعْمَةٍ مِنَ اللَّهِ وَفَضْلٍ وَأَنَّ اللَّهَ لَا يُضِيعُ أَجْرَ الْمُؤْمِنِينَ﴾ ، ولا يخفى على القراء الكرام ما تضمنته الآية الكريمة أعلاه من فضائل الشهداء وعلو مكانتهم ومقدار كرامتهم وعظيم منزلتهم بخلودهم وفوزهم بالحياة الأبدية .</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {dead.length > 0 &&
                                                <>
                                                    <div className="col-xl-11">
                                                        <p className='home-dead-title'>الشهيد القائد المهندس</p>
                                                        <p className='home-dead-disc'>
                                                            شكرا للشهداء الأبرار الذين تطوف ارواحهم بنا ومن ذكراهم نستمد القوة والعزم على مواصلة الطريق، حيث تنتظرنا تحديات جسام نحن أهل لها ان شاء الله
                                                        </p>
                                                    </div>
                                                    <div className="col-xl-11">
                                                        <div className='home-dead-card'>
                                                            <div className="container-fluid">
                                                                <div className="row justify-content-start align-items-start g-2">
                                                                    <p className='card-title'>نبذة من الشهداء</p>
                                                                    {dead.map((item, index) => {
                                                                        return (
                                                                            <div key={item.id} className="col-xl-2 col-md-3 col-md-4 col-sm-6 col-6">
                                                                                <a href={`/dead/list/${item.id}/`} className="d-flex flex-column justify-content-center align-items-center p-1">
                                                                                    <Avatar size={75} src={item.image !== null ? item.image : defaultProfile2} />
                                                                                    <div>
                                                                                        <p className='card-man-info-title'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>شهداء {location}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-center'>
                        اكثر من {count} شهيد من مقاتلي الحشد الشعبي من محافظة {location}
                    </p>
                    <p>ابرزهم</p>
                    <div className="col-xl-12 ">
                        <Swiper
                            dir="rtl"
                            slidesPerView={4}
                            spaceBetween={4}
                            slidesPerGroup={4}
                            loop={true}
                        >
                            {deadCity.map((item, index) => {
                                return (
                                    <SwiperSlide key={item.id}>
                                        <a href={`/dead/list/${item.id}/`} className="d-flex flex-column justify-content-center align-items-center">
                                            <Avatar size={75} src={item.image !== null ? item.image : defaultProfile2} />
                                            <div>
                                                <p className='card-man-info-title'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
