import { Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { MdPause, MdPlayArrow, MdTextSnippet, MdThumbUp } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import AudioPlayer from '../../components/AudioPlayer';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import Share from '../../components/Share';
import { defaultProfile } from '../../funcAndVar/var';
import { AuthorCard } from '../../components/cards';
import { IoShareOutline } from 'react-icons/io5';

export default function PoemItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams();
    const [item, setItem] = useState('');
    const [text, setText] = useState([]);
    const [data, setData] = useState([]);
    const [author, setAuthor] = useState('');
    const [category, setCategory] = useState('');
    const [play, setPlay] = useState('')
    const [pause, setPause] = useState(true)

    useEffect(() => {
        document.title = 'القصائد'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {

            await axios.get(`/literature/api/v1/poems/${id}/`)
                .then(res => {
                    setItem({ ...res.data })
                    setAuthor(res.data.author)
                    setCategory(res.data.category)
                    const text = String(res.data.poem_text).split('\n')
                    setText(text)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {
            if (author) {
                await axios.get(`/literature/api/v1/poems/?author=${author.id}`)
                    .then(res => {
                        setData(res.data.results)
                        if (res.status === 200) setLoading(false)
                    })
            }
        }
        getData()
    }, [author])
    const onPlay = (item) => {
        setPlay(item)
        if (pause) {
            setPause(false)
        } else {
            setPause(true)
        }
    }
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page'>
            <div className="container-fluid mt-5">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xl-2 col-lg-3 col-md-4 order-1 order-md-0">
                        <div className="container-fluid m-0 p-0">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <AuthorCard
                                        id={item.author.id}
                                        title='شاعر'
                                        path={`/poetry/authors/id/${item.author.id}/name/${item.author.first_name} ${item.author.second_name} ${item.author.last_name}`}
                                        name={`${item.author.first_name} ${item.author.second_name} ${item.author.last_name}`}
                                        image={item.author.image}
                                        infoTitleOne='قصيدة'
                                        infoTitleTow='متابع'
                                        infoCountOne={50}
                                        infoCountTow={item.author.id * 50}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className='more-poem shadow'>
                                        <p className='title'>قصائد اخرى</p>
                                        {data.map(data => {
                                            return (
                                                <div key={data.id} className='poem-item'>
                                                    <a href={`/poetry/poem/${data.id}/`}><MdTextSnippet size={20} color='#23b5ab' /> {data.poem_name}</a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 order-0 order-md-1">
                        <div className='pome-content'>
                            <div className='pome-header'>
                                <p className='title f-bold'>{item.poem_name}</p>
                                <div className='buts'>
                                    {item.file &&
                                        <Button onClick={() => onPlay(item)} variant=''>
                                            {pause ? <MdPlayArrow size={20} /> : <MdPause size={20} />}
                                        </Button>
                                    }
                                    <Button title='اعجاب' variant=''><MdThumbUp size={20} /></Button>
                                    <Share url={`/poetry/poem/${id}/`}>
                                        <Button title='مشاركة' variant=''><IoShareOutline size={20} /></Button>
                                    </Share>
                                </div>
                            </div>
                            <div className='pome'>
                                <div className="container">
                                    <div className="row justify-content-center align-items-center g-2">
                                        {text.map((item, index) => {
                                            if (category.name === 'الشعر العمودي') {
                                                return (
                                                    <p
                                                        key={index + 1}
                                                        className='text col-xl-6'
                                                    >
                                                        {item}
                                                    </p>
                                                )
                                            }
                                            return (
                                                <p
                                                    key={index + 1}
                                                    className='text col-xl-12'
                                                >
                                                    {item}
                                                </p>)
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {play &&
                <AudioPlayer
                    title={item.poem_name}
                    author={`${author.first_name} ${author.second_name} ${author.last_name}`}
                    image={author.image !== null ? author.image : defaultProfile}
                    play={play}
                    onClose={() => setPlay('')}
                    onPause={(value) => setPause(value)}
                    pause={pause}
                />
            }
        </div>
    )
}
