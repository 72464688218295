import React from 'react'
import { defaultCover2 } from '../../funcAndVar/var';
import { CiCalendar, CiTextAlignRight } from 'react-icons/ci';
import moment from 'moment';
import Share from '../Share';
import { IoShareOutline } from 'react-icons/io5';

export function NewsCard(props) {
    const { images, tag, created, summary, id, title, path, className } = props;
    const imagesAll = JSON.stringify(images);
    const emptyArray = JSON.stringify([]);
    const tags = tag.split(',');
    return (
        <div key={id} className={className}>
            <div className='news-card' >
                <a href={path}>
                    <img alt="..." className='news-card-image' src={imagesAll !== emptyArray ? images[0].image : defaultCover2} />
                </a>
                <div className='news-card-info'>
                    <p className='news-card-info-tag'><CiTextAlignRight size={18} /> {tags[0] ? tags[0] : 'عامة'}</p>
                    <a className='news-card-info-title f-bold' href={path} > {title}</a>
                    <p className='news-card-info-date'><CiCalendar /> {moment(created).format('hh:mm YYYY-MM-DD')}</p>
                    <p className='news-card-info-summary'>{String(summary)}</p>

                </div>
                <div className='share-but'>
                    <Share url={path}>
                        <IoShareOutline className='icon' color='#23b5ab' />
                    </Share>
                </div>
            </div>
        </div>
    )
}

export function NewsCardMore(props) {
    const { images, tag, created, summary, id, title, path } = props;
    const imagesAll = JSON.stringify(images);
    const emptyArray = JSON.stringify([]);
    const tags = tag.split(',');
    return (
        <div key={id} className='more-card' >
            <a href={path}>
                <img alt="..." className='more-card-image' src={imagesAll !== emptyArray ? images[0].image : defaultCover2} />
            </a>
            <div className='more-card-info'>
                <p className='more-card-info-tag'><CiTextAlignRight size={18} /> {tags[0] ? tags[0] : 'عامة'}</p>
                <a className='more-card-info-title f-bold' href={path} > {title}</a>
                <p className='more-card-info-date'><CiCalendar /> {moment(created).format('hh:mm YYYY-MM-DD')}</p>
                <p className='more-card-info-summary'>{String(summary)}</p>
            </div>
        </div>
    )
}