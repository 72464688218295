import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { defaultCover2 } from '../../funcAndVar/var';
// This imports the functional component from the previous sample.

export default class Player extends Component {
    player;
    state = {
        url: null,
        playing: false,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,

    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
        })
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    handleSetPlaybackRate = e => {
        this.setState({ playbackRate: parseFloat(e.target.value) })
    }

    handleOnPlaybackRateChange = (speed) => {
        this.setState({ playbackRate: parseFloat(speed) })
    }


    handlePlay = () => {
        console.log('onPlay')
        this.setState({ playing: true })
    }



    handlePause = () => {
        console.log('onPause')
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        console.log('onEnded')
        this.setState({ playing: this.state.loop })
    }

    handleDuration = (duration) => {
        console.log('onDuration', duration)
        this.setState({ duration })
    }

    buttonPlay = () => {
        if (this.state.playing) {
            this.setState({ playing: false })
        } else {
            this.setState({ playing: true })
        }
    }

    renderLoadButton = (url, label) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }
    ref = player => {
        this.player = player
    }
    render() {
        const { playing } = this.state
        const { url, firstItem, first } = this.props
        return (
            <>
                {!playing &&
                    <div onClick={this.buttonPlay}>
                        <img alt="..."
                            src={
                                first ?
                                    (first.cover !== null ? first.cover : defaultCover2)
                                    :
                                    (firstItem.cover !== null ? firstItem.cover : defaultCover2)}
                            className='video-player-poster'
                        />
                        <div className="bg-images bg-video" style={{ zIndex: 11, height: '100%', borderRadius: 0 }} />
                        <div className='video-info-content' style={{ zIndex: 12 }} >
                            <p className='video-home-card-title f-bold' style={{ fontSize: 22 }} >
                                {first ? first.name : firstItem.name}
                            </p>
                        </div>
                        <Button variant='' onClick={this.buttonPlay} className='video-player-but' ><i className="fa-solid fa-play"></i></Button>
                    </div>
                }
                <ReactPlayer
                    url={url}
                    ref={this.ref}
                    className='video-player-viwe'
                    width='100%'
                    height='100%'
                    controls
                    playing={playing}
                    onReady={() => console.log('onReady')}
                    onStart={() => console.log('onStart')}
                    onPlay={this.handlePlay}
                    onPause={this.handlePause}
                    onBuffer={() => console.log('onBuffer')}
                    onSeek={e => console.log('onSeek', e)}
                    onEnded={this.handleEnded}
                    onError={e => console.log('onError', e)}
                    onProgress={this.handleProgress}
                    onDuration={this.handleDuration}
                // onReady={handlePlayerReady}
                />
            </>
        )
    }
}


