import { Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AudioPlayer from '../../components/AudioPlayer';
import Loading from '../../components/Loading';
import { defaultProfile } from '../../funcAndVar/var';

export default function PrayItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [item, setItem] = useState('');
    const [list, setList] = useState([]);
    const [sounds, setSounds] = useState([]);
    const [sound, setSound] = useState('');
    useEffect(() => {
        async function getData() {
            await axios.get(`/praying/api/v1/prays/${id}/`)
                .then(res => {
                    setItem({ ...res.data })
                })
            await axios.get(`/praying/api/v1/sounds/?pray=${id}&page_size=50`)
                .then(res => {
                    const first = res.data.results[0]
                    setSounds(res.data.results)
                    setSound(JSON.stringify(first))
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {
            if (item) {
                await axios.get(`/praying/api/v1/prays/?type=${item.type}`)
                    .then(res => {
                        setList(res.data.results)
                    })
            }
        }
        getData()
    }, [item])
    const mySound = sound ? JSON.parse(sound) : ''
    console.log(mySound)
    if (loading) {
        return <Loading />
    }
    return (
        <div className='oasis-dinia-page'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='pray-content'>
                                        <div className='pray-header'>
                                            <p className='f-bold'>{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                                {sounds.length > 0 &&
                                    <div className="col-12">
                                        <div className='pray-content'>
                                            <p>القارئ</p>
                                            <Select
                                                showSearch
                                                className='col-12'
                                                placeholder='اختر'
                                                suffixIcon={<i className="fa-solid fa-microphone"></i>}
                                                onChange={(e) => setSound(e)}
                                            >
                                                {sounds.map((item) => { return (<option key={item.id} value={JSON.stringify(item)}>{item.artist.first_name} {item.artist.second_name} {item.artist.last_name}</option>) })}
                                            </Select>
                                        </div>
                                    </div>
                                }
                                <div className="col-12">
                                    <div className='pray-content'>
                                        <p>مختارات</p>
                                        <div>
                                            {list.filter(data => (data.id === id ? null : data)).map(data => {
                                                return (
                                                    <div key={item.id} className='more-item'>
                                                        <a href={`/oasis_dinia/pray/${data.id}/`}>{data.name}</a>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8 col-lg-8 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='pray-content'>
                                        <div className='info-content'>
                                            <p className='f-bold'>{item.name}</p>
                                        </div>
                                        <div className='text-content'>
                                            <p className='text'>
                                                {item.pray_text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {sound ?
                <AudioPlayer
                    title={item.name}
                    author={`${mySound.artist.first_name} ${mySound.artist.second_name} ${mySound.artist.last_name}`}
                    image={mySound.artist.image !== null ? mySound.artist.image : defaultProfile}
                    play={mySound}
                    onClose={() => setSound('')}
                />
                : null}
        </div >
    )
}
