
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import { useCookies } from 'react-cookie';
import PaginationPage from '../../components/Pagination';
import { AuthorCard } from '../../components/cards';

export default function PoetryAuthorList() {
    const [loading, setLoading] = useState(true)
    const [authors, setAuthors] = useState([]);
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { poetry_author } = page;
    useEffect(() => {
        document.title = 'القصائد'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/authors/?type=1&page_size=60&page=${poetry_author ? poetry_author : 1}`)
                .then(res => {
                    setCount(res.data.count);
                    setAuthors(res.data.results)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('poetry_author', 1)
                    }
                })
        }
        getData()
    }, [poetry_author])

    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page mt-3'>
            <div className="container-fluid  position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/poetry/`}>الشعر</a>
                            <a className='header-link-item'>الشعراء</a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-start align-items-center g-2">
                                <div className="col-12 border-bottom">
                                    <h4 className='f-bold'>الشعراء</h4>
                                </div>
                                {authors.map((item) => {
                                    return (
                                        <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-12">
                                            <AuthorCard
                                                id={item.id}
                                                title='شاعر'
                                                path={`/poetry/authors/${item.id}/`}
                                                name={`${item.first_name} ${item.second_name} ${item.last_name}`}
                                                image={item.image}
                                                infoTitleOne='قصيدة'
                                                infoTitleTow='متابع'
                                                infoCountOne={50}
                                                infoCountTow={50}
                                            />
                                        </div>
                                    )
                                })}
                                <div className="col-12">
                                    <PaginationPage
                                        defaultCurrent={!poetry_author ? 1 : Number(poetry_author)}
                                        total={count}
                                        defaultPageSize={60}
                                        onChange={(e) => setPage('poetry_author', e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
