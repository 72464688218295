import React from 'react'
import { MdQueryBuilder } from 'react-icons/md'
import Share from '../Share'
import { IoShareOutline } from 'react-icons/io5'

export default function StoryCard({ data }) {
    return (
        <div key={data.id} className="col-xl-10 col-12">
            <div className='poem-card'>
                <div className='title-content'>
                    <a href={`/storys/story/id/${data.id}/name/${data.name}`} className='title-1 f-bold'>{data.name}</a>
                    <p className='title-2'><MdQueryBuilder size={14} /> {String(data.created).slice(11, 19)}   {String(data.created).slice(0, 10)}</p>
                </div>
                <a href={`/storys/story/id/${data.id}/name/${data.name}`}>
                    <p className='line-40'>
                        {
                            String(data.text).length > 400 ?
                                <> {String(data.text).slice(0, 400)}...</>
                                :
                                data.text
                        }
                    </p>
                </a>
                <div className='footer-content'>
                    <div className='footer-count'>
                        <p>اعجاب 10</p>
                        <p>المشاهدات 100</p>
                    </div>
                    <p className='footer-but'>
                        <Share url={`/storys/story/id/${data.id}/name/${data.name}`}>
                            <IoShareOutline size={22} />
                        </Share>
                    </p>
                </div>
            </div>
        </div>
    )
}
