import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func'
import { Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { Button } from 'react-bootstrap'
import DeadForm from '../../components/dead/DeadForm'

export default function ContactUs() {
  const [type, setType] = useState(0)
  useEffect(() => {
    document.title = 'اتصل بنا'
    navPage()
  }, [])
  return (
    <div>
      <div className="container-fluid p-0 m-0">
        <div className="row justify-content-center align-items-center g-2">
          <div className="col-12 speak-up">
            <div className="container-fluid">
              <div className="row justify-content-center align-items-center g-2">
                <div className="col-lg-8 col-12">
                  <div className='d-flex flex-column mt-2'>
                    <p className='fs-3 f-bold text-light'>اتصل بنا</p>
                    <p className='f-description text-light'>للتواصل معنا يمكن مراسلتنا على الفورم ادناة</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-11 speak-up-form">
            <div className="container-fluid">
              <div className="row justify-content-center align-items-center g-2">
                <div className="col-12">
                  <label className='m-2 fs-6'>الأسم</label>
                  <Input size='large' placeholder='الأسم' />
                </div>
                <div className="col-12">
                  <label className='m-2 fs-6'>البريد الالكتروني</label>
                  <Input size='large' placeholder='البريد الالكتروني' />
                </div>
                <div className="col-12">
                  <label className='m-2 fs-6'>نوع الرسالة</label>
                  <Select
                    size={'large'}
                    placeholder='نوع الرسالة'
                    style={{
                      width: '100%',
                    }}
                    defaultValue={0}
                    onChange={(e) => setType(e)}
                  >
                    <option value={0}>رسالة</option>
                    <option value={1}>بيانات شهيد</option>
                  </Select>
                </div>
                {type === 0 &&
                  <div className="col-12">
                    <label className='m-2 fs-6'>الموضوع</label>
                    <Input size='large' placeholder='الموضوع' />
                  </div>
                }
                {type === 1 &&
                  <div className="col-12">
                    <label className='m-2 fs-6'>الحقول المطلوب</label>
                    <DeadForm />
                  </div>
                }
                <div className="col-12">
                  <label className='m-2 fs-6'>الرسالة</label>
                  <TextArea size='large' placeholder='الرسالة' rows={10} />
                </div>
                <div className="col-5">
                  <Button variant='' className='but-home w-100 mt-3'>أرسال الرسالة</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
