import React, { useEffect } from 'react'
import { navPage } from '../../funcAndVar/func'

export default function TermsAndConditions() {
    useEffect(() => {
        document.title = 'الشروط والاحكام'
        navPage()
    }, [])
  return (
    <div>
      
    </div>
  )
}
