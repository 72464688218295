import { Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { defaultProfile2 } from '../../funcAndVar/var';
import { AuthorCard } from '../../components/cards';
import { useCookies } from 'react-cookie';
import PaginationPage from '../../components/Pagination';
export default function DeadQuote() {
    const [loading, setLoading] = useState(true)
    const [dead, setDead] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { qoute_author } = page;
    useEffect(() => {
        document.title = 'شهدائنا'
        navPage()
    }, [])

    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Death/?has_quote=true&page_size=60&page=${qoute_author ? qoute_author : 1}`)
                .then(res => {
                    setCount(res.data.count);
                    setDead(res.data.results)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('qoute_author', 1)
                    }
                })
        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div className="quote-page">
            <div className="container-fluid mt-3">
                <div className="row justify-content-start align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/quote/`}>الأقتباسات</a>
                            <a className='header-link-item'>الشهداء</a>
                        </div>
                    </div>
                    {dead.length > 0 ?
                        <>
                            {dead.map((item) => {
                                return (
                                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-12">
                                        <AuthorCard
                                            id={item.id}
                                            title='شهيد'
                                            path={`/quote/dead/${item.id}/`}
                                            name={`${item.first_name} ${item.second_name} ${item.third_name} ${item.last_name}`}
                                            image={item.image}
                                            infoTitleOne='أقتباس'
                                            infoTitleTow='متابع'
                                            infoCountOne={50}
                                            infoCountTow={50}
                                        />
                                    </div>
                                )
                            })}
                        </>
                        : <Empty />}
                    <div className="col-12">
                        <PaginationPage
                            defaultCurrent={!qoute_author ? 1 : Number(qoute_author)}
                            total={count}
                            defaultPageSize={60}
                            onChange={(e) => setPage('qoute_author', e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
