/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar, { NavBarMin } from "./navbar/Navbar";
import Home from "./pages/Home";
import Footer from "./navbar/Footer";
import Dead from "./pages/dead_wounded/Dead";
import { styleNav } from "./funcAndVar/func";
import News from "./pages/news_articles/News";
import NewsItem from "./pages/news_articles/NewsItem";
import NewsCatogary from "./pages/news_articles/NewsCatogary";
import Article from "./pages/news_articles/Article";
import ArticleItem from "./pages/news_articles/ArticalItem";
import ArticleCatogary from "./pages/news_articles/ArticleCatogary";
import GalleryHome from "./pages/gallery/GalleryHome";
import ImageItem from "./pages/gallery/ImageItem";
import Photographer from "./pages/gallery/Photographer";
import Albums from "./pages/gallery/Albums";
import LibraryHome from "./pages/library/LibraryHome";
import BookItem from "./pages/library/BookItem";
import BooksList from "./pages/library/BooksList";
import BookObject from "./pages/library/BookObject";
import DeadList from "./pages/dead_wounded/DeadList";
import DeadItem from "./pages/dead_wounded/DeadItem";
import QuoteHome from "./pages/quote/QuoteHome";
import DeadQuote from "./pages/quote/DeadQuote";
import QuoteList from "./pages/quote/QuoteList";
import PoemsHome from "./pages/poems/PoemsHome";
import AuthorItem from "./pages/poems/AuthorItem";
import PoemItem from "./pages/poems/PoemItem";
import CategoryItem from "./pages/poems/CategoryItem";
import PoetryAuthorList from "./pages/poems/poetryAuthorList";
import PoetryCatogaryList from "./pages/poems/poetryCatogaryList";
import ThoughtsHome from "./pages/thoughts/ThoughtsHome";
import DeadThoughts from "./pages/thoughts/DeadThoughts";
import ThoughtsList from "./pages/thoughts/ThoughtsList";
import ThoughtsItem from "./pages/thoughts/ThoughtsItem";
import Wounded from "./pages/dead_wounded/Wounded";
import WoundedList from "./pages/dead_wounded/WoundedList";
import WoundedItem from "./pages/dead_wounded/WoundedItem";
import Spyker from "./pages/dead_wounded/Spyker";
import SpykerItem from "./pages/dead_wounded/SpykerItem";
import SpykerList from "./pages/dead_wounded/SpykerList";
import EventHome from "./pages/event/EventHome";
import EventItem from "./pages/event/EventItem";
import HomeOasisDinia from "./pages/oasis_dinia/HomeOasisDinia";
import PrayAndVisits from "./pages/oasis_dinia/PrayAndVisits";
import PrayItem from "./pages/oasis_dinia/PrayItem";
import SahifaAndMafatih from "./pages/oasis_dinia/SahifaAndMafatih";
import Questions from "./pages/oasis_dinia/Questions";
import QuestionsAuthor from "./pages/oasis_dinia/QuestionsAuthor";
import QuestionsItem from "./pages/oasis_dinia/QuestionsItem";
import Quran from "./pages/oasis_dinia/Quran";
import QuranItem from "./pages/oasis_dinia/QuranItem";
import Policy from "./pages/footers/Policy";
import TermsAndConditions from "./pages/footers/TermsAndConditions";
import SpeakUp from "./pages/footers/SpeakUp";
import Applications from "./pages/footers/Applications";
import ContactUs from "./pages/footers/ContactUs";
import RemainsHome from "./pages/remains/RemainsHome";
import DeadRemains from "./pages/remains/DeadRemains";
import RemainsList from "./pages/remains/RemainsList";
import StoryHome from "./pages/storys/StoryHome";
import StoryAuthorItem from "./pages/storys/StoryAuthorItem";
import StoryCategoryItem from "./pages/storys/StoryCategoryItem";
import StoryAuthorList from "./pages/storys/StoryAuthorList";
import StoryCatogaryList from "./pages/storys/StoryCatogaryList";
import StoryItem from "./pages/storys/StoryItem";
import { ConfigProvider } from 'antd'
const theme = require('./theme.json')
function App(props) {
  useEffect(() => {
    styleNav()
  }, [])

  return (
    <ConfigProvider
      direction="rtl"
      theme={theme}
    >
      <div className="App">
        <Router>
          <div className="navbar-max">
            <Navbar />
          </div>
          <div className="navbar-min">
            <NavBarMin />
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dead/" element={<Dead />} />
            <Route path="/dead/list/" element={<DeadList />} />
            <Route path="/dead/list/:id/" element={<DeadItem />} />
            <Route path="/wounded/" element={<Wounded />} />
            <Route path="/wounded/list/" element={<WoundedList />} />
            <Route path="/wounded/list/:id/" element={<WoundedItem />} />
            <Route path="/spyker/" element={<Spyker />} />
            <Route path="/spyker/list/" element={<SpykerList />} />
            <Route path="/spyker/list/:id/" element={<SpykerItem />} />
            <Route path="/news/" element={<News />} />
            <Route path="/news/:id/" element={<NewsItem />} />
            <Route path="/news/category/:id/" element={<NewsCatogary />} />
            <Route path="/article/" element={<Article />} />
            <Route path="/article/:id/" element={<ArticleItem />} />
            <Route path="/article/category/:id/" element={<ArticleCatogary />} />
            <Route path="/gallery/" element={<GalleryHome />} />
            <Route path="/gallery/image/:id/" element={<ImageItem />} />
            <Route path="/gallery/photographer/:id/" element={<Photographer />} />
            <Route path="/gallery/albums/:id/" element={<Albums />} />
            <Route path="/library/" element={<LibraryHome />} />
            <Route path="/library/book/:id/" element={<BookItem />} />
            <Route path="/library/:name/:id/" element={<BooksList />} />
            <Route ex path="/library/book/id/:id/name/:name" element={<BookObject />} />
            <Route path="/quote/" element={<QuoteHome />} />
            <Route path="/quote/dead/" element={<DeadQuote />} />
            <Route path="/quote/dead/:id/" element={<QuoteList />} />
            <Route path="/remains/" element={<RemainsHome />} />
            <Route path="/remains/dead/" element={<DeadRemains />} />
            <Route path="/remains/dead/:id/" element={<RemainsList />} />
            <Route path="/thoughts/" element={<ThoughtsHome />} />
            <Route path="/thoughts/dead/" element={<DeadThoughts />} />
            <Route path="/thoughts/dead/:id/" element={<ThoughtsList />} />
            <Route path="/thoughts/list/:id/" element={<ThoughtsItem />} />
            <Route path="/poetry/" element={<PoemsHome />} />
            <Route path="/poetry/authors/:id/" element={<AuthorItem />} />
            <Route path="/poetry/poem/:id/" element={<PoemItem />} />
            <Route path="/poetry/category/:id/" element={<CategoryItem />} />
            <Route path="/poetry/authors/" element={<PoetryAuthorList />} />
            <Route path="/poetry/category/" element={<PoetryCatogaryList />} />
            <Route path="/event/" element={<EventHome />} />
            <Route path="/event/list/:id/" element={<EventItem />} />
            <Route path="/oasis_dinia/" element={<HomeOasisDinia />} />
            <Route path="/oasis_dinia/pray_and_visits/" element={<PrayAndVisits />} />
            <Route path="/oasis_dinia/sahifa_and_mafatih/" element={<SahifaAndMafatih />} />
            <Route path="/oasis_dinia/pray/:id/" element={<PrayItem />} />
            <Route path="/oasis_dinia/questions/author/" element={<QuestionsAuthor />} />
            <Route path="/oasis_dinia/questions/author/:id/" element={<Questions />} />
            <Route path="/oasis_dinia/questions/author/:id/section/:id2/" element={<QuestionsItem />} />
            <Route path="/oasis_dinia/quran/" element={<Quran />} />
            <Route path="/oasis_dinia/quran/surah/:id/" element={<QuranItem />} />
            <Route path="/storys/" element={<StoryHome />} />
            <Route path="/storys/authors/id/:id/name/:name" element={<StoryAuthorItem />} />
            <Route path="/storys/story/id/:id/name/:name" element={<StoryItem />} />
            <Route path="/storys/categorys/id/:id/name/:name" element={<StoryCategoryItem />} />
            <Route path="/storys/authors/" element={<StoryAuthorList />} />
            <Route path="/storys/categorys/" element={<StoryCatogaryList />} />
            <Route path="/privacy_policy/" element={<Policy />} />
            <Route path="/terms_and_conditions/" element={<TermsAndConditions />} />
            <Route path="/speak_up/" element={<SpeakUp />} />
            <Route path="/applications/" element={<Applications />} />
            <Route path="/contact_us/" element={<ContactUs />} />
          </Routes>
          <Footer />
        </Router >
      </div>
    </ConfigProvider>
  );
}

export default App;
