import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AudioPlayer from '../../components/AudioPlayer';
import Loading from '../../components/Loading';
import { navPage } from '../../funcAndVar/func';

export default function QuranItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [item, setItem] = useState('');
    const [list, setList] = useState([]);
    const [sound, setSound] = useState('');
    useEffect(() => {
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`https://api.quran.com/api/v4/chapters/${id}?language=en`)
                .then(res => {
                    setItem({ ...res.data.chapter })
                    document.title ='سورة '+ res.data.chapter.name_arabic;
                    if (res.status === 200) setLoading(false)
                })
            await axios.get(`https://api.quran.com/api/v4/chapter_recitations/2/${id}`)
                .then(res => {

                    setSound(res.data.audio_file)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {
            if (item) {
                await axios.get(`https://api.quran.com/api/v4/quran/verses/imlaei?chapter_number=${id}`)
                    .then(res => {
                        setList(res.data.verses)
                    })
            }
        }
        getData()
    }, [item, id])

    if (loading) {
        return <Loading />
    }
    return (
        <div className='oasis-dinia-page'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xxl-8 col-xl-9 col-lg-10 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='pray-content'>
                                        <div className='pray-header'>
                                            <p className='fs-35 f-bold'>سورة {item.name_arabic}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* {sounds.length > 0 &&
                                    <div className="col-12">
                                        <div className='pray-content'>
                                            <p>القارئ</p>
                                            <Select
                                                showSearch
                                                className='col-12'
                                                placeholder='اختر'
                                                suffixIcon={<i className="fa-solid fa-microphone"></i>}
                                                onChange={(e) => setSound(e)}
                                            >
                                                {sounds.map((item) => { return (<option key={item.id} value={JSON.stringify(item)}>{item.artist.first_name} {item.artist.second_name} {item.artist.last_name}</option>) })}
                                            </Select>
                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-8 col-xl-9 col-lg-10 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='surah-content'>
                                        <div className='info-content'>
                                            <p><img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/icon_11.png'} width={30} /> الأيات</p>
                                        </div>
                                        {list.map((item, index) => {
                                            return (
                                                <div key={item.id} className='text-content'>
                                                    <p className='text'>
                                                        {item.text_imlaei}
                                                    </p>
                                                    <div className='ayah-icon'>
                                                        <p>{index + 1}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {sound ?
                <AudioPlayer
                    title={'سورة ' + item.name_arabic}
                    author={`عبد الباسط عبد الصمد`}
                    image={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/flower.png'}
                    url={sound.audio_url}
                    onClose={() => setSound('')}
                />
                : null}
        </div >
    )
}
