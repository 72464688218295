import { Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { useCookies } from 'react-cookie';
import PaginationPage from '../../components/Pagination';
import PoemCard from '../../components/poems/PoemCard';

export default function CategoryItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams();
    const [item, setItem] = useState('');
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { category_item_poems } = page;
    useEffect(() => {
        document.title = 'الأقسام'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/${id}/`)
                .then(res => {
                    setItem({ ...res.data })
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {

            await axios.get(`/literature/api/v1/poems/?category=${id}&page=${category_item_poems ? category_item_poems : 1}`)
                .then(res => {
                    setCount(res.data.count);
                    setData(res.data.results)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('category_item_poems', 1)
                    }
                })
        }
        getData()
    }, [category_item_poems])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page mt-3'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-start g-2">
                    <div className='header-link'>
                        <a className='header-link-item' href={`/poetry/`}>الشعر</a>
                        <a className='header-link-item' href={`/poetry/category/`}>الأقسام</a>
                        <a className='header-link-item'>{item.name}</a>
                    </div>
                    <div className="col-xl-3">
                        <div className='catogary-header'>
                            <img alt="..." className='image-header' src={item.image !== null ? item.image : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/mokhadramoun.jpg'} />
                            <p className='title f-bold'>{item.name}</p>
                            <p className='count'>عدد القصائد 50</p>
                        </div>
                    </div>
                    <div className="col-xl-9">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-xl-10 col-12">
                                    <h4 className='f-bold'>القصائد</h4>
                                </div>
                                {data.map(data => {
                                    return <PoemCard data={data} />
                                })}
                                <div className="col-12">
                                    <PaginationPage
                                        defaultCurrent={!category_item_poems ? 1 : Number(category_item_poems)}
                                        total={count}
                                        defaultPageSize={10}
                                        onChange={(e) => setPage('category_item_poems', e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
