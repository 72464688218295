import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { CiTextAlignRight } from 'react-icons/ci';
import { MdQueryBuilder } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import NewsAccordion from '../../components/News/NewsAccordion';
import PaginationPage from '../../components/Pagination';
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { defaultCover2 } from '../../funcAndVar/var';
import { NewsCard } from '../../components/cards';

export default function NewsCatogary() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [item, setItem] = useState('');
    const [news, setNews] = useState([]);
    const [searchNews, setSearchNews] = useState([]);
    const [agency, setAgency] = useState([]);
    const [agencySearch, setAgencySearch] = useState('');
    const [agencyId, setAgencyId] = useState('');
    const [categorys, setCategorys] = useState([]);
    const [category, setCategory] = useState([]);
    const [categorySearch, setCategorySearch] = useState('');
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useCookies('');
    const { page_category_news } = page;

    useEffect(() => {
        document.title = 'الأخبار'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/${id}/`)
                .then(res => {
                    setItem(res.data)
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {
            await axios.get(`/oasis/api/v1/news/?agency=${agencyId}&category=${id}&page=${page_category_news ? page_category_news : 1}`)
                .then(res => {
                    setNews(res.data.results)
                    setCount(res.data.count)
                    if (res.status === 200) setLoading(false)
                }).catch(err => {
                    //err.response.data.detail= 'Invalid page.'
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('page_category_news', 1)
                    }
                })
        }
        getData()
    }, [agencyId, page_category_news, id])
    useEffect(() => {
        async function getData() {
            await axios.get(`/oasis/api/v1/news/?search=${search}`)
                .then(res => {
                    setSearchNews(res.data.results)
                })
        }
        getData()
    }, [search])

    useEffect(() => {
        async function getData() {
            await axios.get(`/oasis/api/v1/news_agency/?search=${agencySearch}`)
                .then(res => {
                    setAgency(res.data.results)
                })
            await axios.get(`/shared/api/v1/categorys/?type=4&search=${categorySearch}`)
                .then(res => {
                    setCategorys(res.data.results)
                })
        }
        getData()
    }, [agencySearch, categorySearch])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=4`)
                .then(res => {
                    setCategory(res.data.results)
                })
        }
        getData()
    }, [])

    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid mt-1">
                <div className="row justify-content-center align-items-start g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/news/`}>الأخبار</a>
                            <a className='header-link-item'>التصنيفات</a>
                            <a className='header-link-item'>{item.name}</a>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-3 col-sm-12 '>
                        <NewsAccordion
                            type='page'
                            path='/news/'
                            agency={agency}
                            category={category}
                            categorys={categorys}
                            news={searchNews}
                            search={search}
                            onChangeSearch={(e) => setSearch(e.target.value)}
                            onChangeAgency={(e) => setAgencyId(e)}
                            onSearchAgency={(e) => setAgencySearch(e)}
                            onSearchCategory={(e) => setCategorySearch(e)}
                        />
                    </div>
                    <div className='col-lg-9 col-md-7 col-sm-12'>
                        <div className="container-fluid">
                            <div className="row justify-content-start align-items-start g-2">
                                {news.map((item, index) => {
                                    return (
                                           <NewsCard
                                            id={item.id}
                                            title={item.title}
                                            path={`/news/${item.id}/`}
                                            images={item.images}
                                            tag={item.tags}
                                            summary={item.summary}
                                            created={item.created}
                                            className="col-md-12 col-sm-12"
                                        />
                                    )
                                })}
                                <div className='d-flex justify-content-center align-items-start'>
                                    <PaginationPage
                                        defaultCurrent={page_category_news ? Number(page_category_news) : 1}
                                        total={count}
                                        defaultPageSize={10}
                                        onChange={(e) => setPage('page_category_news', e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
