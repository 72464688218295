import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { defaultProfile } from '../../funcAndVar/var';

export default function ItemCard({ item }) {
    const [remains, setRemains] = useState([]);
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/remains/?name=${item.id}`)
                .then(res => {
                    setRemains(res.data.results);
                })
        }
        getData()
    }, [item.id])
    return (
        <div className="swiper-card">
            <div className='image-content'>
                <div className='info-content'>
                    <img alt="..." src={item.image !== null ? item.image : defaultProfile} />
                    <div className="subtitle">
                        <p>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                        <p>{item.description}</p>
                    </div>

                    <div className='bg-cover' />
                </div>

            </div>

            <div className='images-remind-layout'>
                {remains.map(data => {
                    return (
                        <div
                            className='layout-images'
                        >
                            <img
                                alt="..."
                                style={{
                                    objectFit: 'cover',
                                    borderRadius: 25,
                                    width: '100%',
                                    height: '100%'
                                }}
                                className='img-layout'
                                src={data.image}
                            />   
                        </div>
                       
                    )
                })}
            </div>


        </div>

    )
}
