import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaMosque } from 'react-icons/fa'
import {
  //getTimeDifference,
  navPage
} from '../../funcAndVar/func';
import {
  Input,
  //Statistic
} from 'antd';
import axios from 'axios';
import Loading from '../../components/Loading';
import moment from "moment";
// import Timer from '../../components/CountTime';
export default function HomeOasisDinia() {
  const date = new Date()
  let timer = moment(date)
  const initStart = timer.toDate().getTime() / 1000
  // const time = date.getTime() / 1000
  const [latitude, setLatitude] = React.useState(0)
  const [longitude, setLongitude] = React.useState(0)
  const [prayDate, setPrayDate] = React.useState(0)
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState([]);
  const [salat, setSalat] = React.useState(0);
  // const [myDate, setMyDate] = React.useState(initStart);
  // const [end, setEnd] = React.useState(0);
  // const [line, setLine] = React.useState(0);

  //pray time change

  // const start = new Date().getTime();
  // const end = moment(start).add("DD-MM-YYYY", 1).set({ "hour": 1, "minute": 32, 'second': 0 }).toDate().getTime();


  useEffect(() => {
    document.title = 'سبل السعادة'
    navPage()
  }, [])

  useEffect(() => {
    async function getData() {
      await axios.get(`/shared/api/v1/categorys/?type=7`)
        .then(res => {
          setCategory(res.data.results);
          if (res.status === 200) setLoading(false)
        })
    }
    getData()
  }, [])
  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude)
      setLongitude(position.coords.longitude)
    });
  }, [])
  React.useEffect(() => {
    async function getdata() {
      if (latitude !== 0 && longitude !== 0) {
        await axios.get(`https://api.aladhan.com/v1/timings/${initStart}?latitude=${latitude}&longitude=${longitude}&method=7`)
          .then(res => {
            setSalat(res.data.data.timings)
          })
        await axios.get(`https://api.aladhan.com/v1/timings/${initStart}?latitude=${latitude}&longitude=${longitude}&method=7`)
          .then(res => {
            setPrayDate({ ...res.data.data.date.hijri })
          })
      }
    }
    getdata()
  }, [longitude, latitude])
  // const timeing = (time) => { return time + ':00' }
  // const getTimer = (time, timer) => {
  //   const times = time.split(':')
  //   const h = times[0]
  //   const m = times[1]
  //   const timeing = timer.set({ "hour": h, "minute": m, 'second': 0 }).toDate().getTime();
  //   return timeing
  // }
  // useEffect(() => {
  //   function getTime() {
  //     const times = timer.format("hh:mm:ss")
  //     const time = timer.toDate().getTime()

  //     if (time > getTimer(salat.Fajr,timer)) {

  //       setMyDate(initStart)
  //       setEnd(getTimer(salat.Dhuhr, timer))
  //       const deadline = end + 1000 * 60 * 60 * 24;
  //       setLine(deadline)

  //     }
  //     else if (time > getTimer(salat.Dhuhr, timer)) {

  //       setMyDate(initStart)
  //       setEnd(getTimer(salat.Maghrib, timer))
  //       const deadline = end + 1000 * 60 * 60 * 24;
  //       setLine(deadline)
  //     }
  //     else if (time > getTimer(salat.Maghrib, timer)) {
  //       const time = timer.add("DD-MM-YYYY", 1).toDate().getTime() / 1000;
  //       setMyDate(time)
  //       setEnd(getTimer(salat.Fajr, timer))
  //       const deadline = end + 1000 * 60 * 60 * 24;
  //       setLine(deadline)
  //     }
  //     else if (times === '00:00:00') {
  //       const time = timer.add("DD-MM-YYYY", 1).toDate().getTime() / 1000;
  //       setMyDate(time)
  //       setEnd(getTimer(salat.Fajr, timer))
  //       const deadline = end + 1000 * 60 * 60 * 24;
  //       setLine(deadline)
  //     }
  //     // console.log(time > getTimer(salat.Fajr))
  //   }
  //   if (salat) getTime()
  // }, [salat])
  // const deadline = 1184652000 + 1000 * 60 * 60 * 24;
  // console.log(start - end);

  if (loading) {
    return <Loading />
  }

  return (
    <div className='oasis-dinia-page'>
      <div className="container-fluid">
        <div className="row justify-content-center align-items-start g-2">
          <div className="col-xxl-3 col-xl-4 col-lg-10 col-12">
            <div className="container-fluid m-0 p-0">
              <div className="row justify-content-center align-items-center g-2">
                <div className="col-12">
                  <div className='side-list'>
                    <div className='links-list'>
                      <h5>البحث</h5>
                      <Input type='text' placeholder='بحث' suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>} />
                    </div>
                  </div>
                </div>
                <div className="col-12 d-none d-xl-block">
                  <div className='side-list'>
                    <div className='links-list'>
                      <h5>الأقسام</h5>
                      <a href='/oasis_dinia/quran/'>القرآن الكريم</a>
                      <a href='/oasis_dinia/pray_and_visits/'>الادعية والزيارات</a>
                      <a href='/oasis_dinia/sahifa_and_mafatih/'>أنوار العاشقين</a>
                      <a href='/oasis_dinia/questions/author/'>الأستفتاءات</a>
                    </div>
                  </div>
                </div>
                {prayDate ?
                  <div className="col-12 col-12 d-none d-xl-block">
                    <div className='card-salah'>
                      <div className='header'>
                        {/* <Timer initialHours={difference.hours} initialMinute={difference.minutes} initialSeconds={difference.sec} /> */}

                        {/* <Countdown title="المتبقي  للصلاة" value={deadline} format="HH:mm:ss" prefix={<FaMosque size={45} color='#23b5ab' />} /> */}
                        <FaMosque size={45} color='#23b5ab' />
                        <div className='date'>
                          <p>{prayDate.day}</p>
                          <p>{prayDate.year} {prayDate.month.ar}</p>
                        </div>
                      </div>
                      <div className='time-salah'>
                        <div className='time-item'>
                          <p>الفجر</p>
                          <p>{salat.Fajr}</p>
                        </div>
                        <div className='time-item'>
                          <p>الضهر</p>
                          <p>{salat.Dhuhr}</p>
                        </div>
                        <div className='time-item'>
                          <p>العصر</p>
                          <p>{salat.Asr}</p>
                        </div>
                        <div className='time-item'>
                          <p>المغرب</p>
                          <p>{salat.Maghrib}</p>
                        </div>
                        <div className='time-item'>
                          <p>العشاء</p>
                          <p>{salat.Isha}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}
                <div className="col d-none d-xl-block">
                  <div className='side-list'>
                    <div className='links-list'>
                      <h5>الاحكام</h5>
                      {category.map(item => {
                        return <a key={item.id}>{item.name}</a>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-xl-8 col-lg-10 col-12">
            <div className="container-fluid m-0 p-0">
              <div className="row justify-content-center align-items-center g-2">
                <div className="col-12">
                  <div className='header-home'>
                    <div className="container-fluid cover-wills">
                      <div className="row justify-content-center align-items-center g-2">
                        <div className="col-xl-5 col-12 d-flex justify-content-center align-items-center">
                          <div className='image-content'>
                            <img alt="..." className='image-flower' src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/flower.png'} />
                            <img alt="..." className='cover-image' src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/mashaf.jpg'} />
                            <img alt="..." className='image-flower-2' src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/flower_2.png'} />
                          </div>
                        </div>
                        <div className="col">
                          <div className='card-headers'>
                            <p className='name-header fs-2 f-bold'> سبل السعادة</p>
                            <p className='info-header'>أنّ الله سبحانه وتعالى ـ كما ندب الى الجهاد ودعا إليه وجعله دعامةً من دعائم الدين وفضّل المجاهدين على القاعدين ــ فإنّه عزّ اسمه جعل له حدوداً وآداباً أوجبتها الحكمة واقتضتها الفطرة، يلزم تفقهها ومراعاتها، فمن رعاها حق رعايتها أوجب له ما قدّره من فضله وسنّه من بركاته، ومن أخلّ بها أحبط من أجره ولم يبلغ به أمله .</p>
                            <a className='link-header mb-1' href='#section'>الأقسام</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className='card-text'>
                    <p className='name-text f-bold'>آية من الذكر الحكيم</p>
                    <p className='info-text text-centet'>وَلَا تَحْسَبَنَّ الَّذِينَ قُتِلُوا فِي سَبِيلِ اللَّهِ أَمْوَاتًا بَلْ أَحْيَاءٌ عِنْدَ رَبِّهِمْ يُرْزَقُونَ</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className='card-text'>
                    <p className='name-text f-bold'>حكمة اليوم</p>
                    <p className='info-text text-centet'>قال الرضا (عليه السلام): إنّ كبر الدار من السعادة، وكثرة المحبّين من السعادة، وموافقة الزوجة كمال السرور.</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className='card-text f-bold'>
                    <p className='name-text f-bold'>هل تريد ثوابًا اليوم؟</p>
                    <p className='info-text text-centet'>قال الامام الحسن (ع): يا ابن آدم أحسن جوار من جاورك تكن مسلما وصاحب الناس بما تحب أن يصحبوك تكن منصفا</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className='wills'>
                    <div className="container-fluid cover-wills">
                      <div className="row justify-content-center align-items-center g-2">
                        <div className="col-md-3 col-12 d-flex justify-content-center align-items-center">
                          <div className='image-content'>
                            <img alt="..." className='image-flower' src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/flower.png'} />
                            <img alt="..." className='cover-image' src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/sistani.jpg'} />
                          </div>
                        </div>
                        <div id='section' className="col-md-9 col-12 d-flex justify-content-center align-items-center ">
                          <div className='card-wills'>
                            <p className='name-wills f-bold'>وصايا المرجعية</p>
                            <p className='info-wills'> أنّ الله سبحانه وتعالى ـ كما ندب الى الجهاد ودعا إليه وجعله دعامةً من دعائم الدين وفضّل المجاهدين على القاعدين ــ فإنّه عزّ اسمه جعل له حدوداً وآداباً أوجبتها الحكمة واقتضتها الفطرة، يلزم تفقهها ومراعاتها، فمن رعاها حق رعايتها أوجب له ما قدّره من فضله وسنّه من بركاته، ومن أخلّ بها أحبط من أجره ولم يبلغ به أمله .</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className='list-main'>
                    <div className='list-item list-1'>
                      <div className='list-info'>
                        <p className='list-title f-bold'>القران الكريم</p>
                        <Button variant='' className='list-but' href='/oasis_dinia/quran/'>الدخول</Button>
                      </div>
                    </div>
                    <div className='list-item list-2'>
                      <div className='list-info'>
                        <p className='list-title f-bold'>الأدعية والزيارات</p>
                        <Button variant='' className='list-but' href='/oasis_dinia/pray_and_visits/'>الدخول</Button>
                      </div>
                    </div>
                    <div className='list-item list-3'>
                      <div className='list-info'>
                        <p className='list-title f-bold'>أنوار العاشقين</p>
                        <Button variant='' className='list-but' href='/oasis_dinia/sahifa_and_mafatih/'>الدخول</Button>
                      </div>
                    </div>
                    <div className='list-item list-4'>
                      <div className='list-info'>
                        <p className='list-title f-bold'>الأستفتاءات</p>
                        <Button variant='' className='list-but' href='/oasis_dinia/questions/author/'>الدخول</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className='pray-time'>
                    <div className="container-fluid cover-wills">
                      <div className="row justify-content-center align-items-center g-2">
                        <div className="col-md-6 col-12 d-flex justify-content-center align-items-center ">
                          <div className='card-pray'>
                          <p className='name-pray f-bold w-100'>أوقات الصلاة</p>
                            <div className='pray-times'>
                              <p>الفجر</p>
                              <p>{salat.Fajr}</p>
                              <p>الضهر</p>
                              <p>{salat.Dhuhr}</p>
                              <p>العصر</p>
                              <p>{salat.Asr}</p>
                              <p>المغرب</p>
                              <p>{salat.Maghrib}</p>
                              <p>العشاء</p>
                              <p>{salat.Isha}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className='image-content'>
                            <img alt="..." className='image-flower' src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/flower.png'} />
                            <img alt="..." className='cover-image' src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/salat.jpg'} />
                            <img alt="..." className='image-flower-2' src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/flower_2.png'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
