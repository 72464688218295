import { Popover } from 'antd'
import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    TelegramIcon,
    XIcon,
    WhatsappIcon,
} from "react-share";
import { shareURL } from '../config';

export default function Share(props) {
    const url = props.type === 'file' ? props.url : shareURL + props.url;

    const content = (
        <div className='share'>
            <div className='share-buts'>
                <FacebookShareButton url={url}>
                    <FacebookIcon size={30} round={true} />
                </FacebookShareButton>
                <WhatsappShareButton url={url}>
                    <WhatsappIcon size={30} round={true} />
                </WhatsappShareButton>
                <TelegramShareButton url={url}>
                    <TelegramIcon size={30} round={true} />
                </TelegramShareButton>
                <TwitterShareButton url={url}>
                    <XIcon size={30} round={true} />
                </TwitterShareButton>
                <EmailShareButton url={url}>
                    <EmailIcon size={30} round={true} />
                </EmailShareButton>
            </div>

        </div>
    );
    return (
        <Popover content={content} title="مشاركة" trigger="click">
            {props.children}
        </Popover>
    )
}
