import { Avatar, Image } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { defaultCover, defaultProfile2 } from '../../funcAndVar/var';

export default function RemainsList() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [dead, setDead] = useState('');
    const [qoute, setQoute] = useState([]);

    useEffect(() => {
        document.title = 'شهدائنا'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Death/${id}/`)
                .then(res => {
                    setDead({ ...res.data })
                })
        }
        getData()
    }, [id])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/remains/?name=${id}&page_size=60`)
                .then(res => {
                    setQoute(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [id])
    if (loading) {
        return <Loading />
    }
    return (
        <div className="remains-page">
            <div className='dead-item-bg' />
            <div className="container-fluid mt-3">
                <div className="row justify-content-center align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12 mb-5'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/remains/`}>الأثار</a>
                            <a className='header-link-item' href={`/remains/dead/`}>الشهداء</a>
                            <a href='/#' className='header-link-item' style={{ backgroundColor: '#fff', color: '#23b5ab' }}>{dead.first_name} {dead.second_name} {dead.third_name} {dead.last_name}</a>
                        </div>
                    </div>
                    <div className="col-12 p-0 m-0">
                        <img alt="..." src={dead.cover !== null ? dead.cover : defaultCover} className='dead-item-cover rounded-3' />
                    </div>
                    <div className="col-xl-10">
                        <div className='dead-item-header'>
                            <img alt="..." src={dead.image !== null ? dead.image : defaultProfile2} className='header-avatar' />

                            <div className='header-about'>
                                <p className='header-about-name'>{dead.first_name} {dead.second_name} {dead.third_name} {dead.last_name}</p>
                                <p className='header-about-info'>
                                    عدد الأثار  {dead.r_count}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-10 mt-5">
                        {qoute.length > 0 ?
                            <div className="container-fluid">
                                <div className="row justify-content-start align-items-center g-2">
                                    {qoute.map((item, index) => {
                                        return (
                                            <>
                                                <div
                                                    className='col-xxl-3 col-xl-4 col-md-6 col-sm-6 col-12'
                                                    key={item.id}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: 'white',
                                                            borderRadius: 15,
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <Image
                                                            alt="..."
                                                            title={item.type}
                                                            src={item.image !== null ? item.image : defaultProfile2}
                                                            width={'100%'}
                                                            style={{
                                                                objectFit: 'cover',
                                                                height: 250
                                                            }}
                                                        />
                                                        <p className='py-2 px-1'>{item.type}</p>
                                                    </div>

                                                    {/* <div className='gallery-images-cover'>
                                                        <a style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'flex-end', color: 'white', fontSize: 18 }} href={`${path}${item.id}/`}><p className='p-2'>{item.first_name} {item.second_name} {item.last_name}</p></a>
                                                    </div> */}
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            <Empty />
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}
