import { Input, Select } from 'antd'
import React from 'react'
import { Accordion, Button } from 'react-bootstrap'
import { TbNews } from 'react-icons/tb'


export default function NewsAccordion(props) {
    return (
        <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header><span><i className="fa-solid fa-magnifying-glass"></i> البحث</span></Accordion.Header>
                <Accordion.Body>
                    <Input type='text' placeholder='بحث' suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>} onChange={props.onChangeSearch} />
                    {props.search &&
                        <div className='search-news'>
                            {props.news.map(item => {
                                return (
                                    <div key={item.id}>
                                        <Button as='a' onClick={(e) => console.log(e)
                                        } className='search-news-card' href={`${props.path}${item.id}/`}>
                                            <p className='search-news-title'><TbNews size={20} /> {item.title}</p>
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header><span><i className="fa-solid fa-filter"></i> فرز</span></Accordion.Header>
                <Accordion.Body>
                    {props.agency &&
                        <>
                            <p className='label'>الوكالة</p>
                            <Select
                                showSearch
                                className='col-12'
                                placeholder='الكل'
                                suffixIcon={<i className="fa-solid fa-building"></i>}
                                onChange={props.onChangeAgency}
                                onSearch={props.onSearchAgency}
                            >
                                <option value=''>الكل</option>
                                {props.agency.map((item) => { return (<option key={item.id} value={item.id}>{item.name}</option>) })}
                            </Select>
                        </>
                    }
                    {props.article &&
                        <>
                            <p className='label'>المدونين</p>
                            <Select
                                showSearch
                                className='col-12'
                                placeholder='الكل'
                                suffixIcon={<i className="fa-solid fa-pen-nib"></i>}
                                onChange={props.onChangeArticle}
                                onSearch={props.onSearchArticle}
                            >
                                <option value=''>الكل</option>
                                {props.article.map((item) => { return (<option key={item.id} value={item.id}>{item.first_name} {item.second_name} {item.last_name}</option>) })}
                            </Select>
                        </>
                    }
                    {props.type === 'home' &&
                        <>
                            <p className='label'>القسم</p>
                            <Select
                                showSearch
                                className='col-12'
                                placeholder='الكل'
                                suffixIcon={<i className="fa-solid fa-tag"></i>}
                                onChange={props.onChangeCategory}
                                onSearch={props.onSearchCategory}
                            >
                                <option value=''>الكل</option>
                                {props.categorys.map((item) => { return (<option key={item.id} value={item.id}>{item.name}</option>) })}
                            </Select>
                        </>
                    }
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header><span><i className="fa-solid fa-tags"></i> التصنيفات</span></Accordion.Header>
                <Accordion.Body>
                    <a href={props.path}>
                        <div className='news-category'>
                            <div
                                className={'news-category-radio-active'}
                            />
                            <p className='news-category-title'>الكل</p>
                        </div>
                    </a>

                    {props.category.map((item, index) => {
                        return (
                            <a key={item.id} href={`${props.path}category/${item.id}/`}>
                                <div className='news-category'>
                                    <div
                                        className={'news-category-radio-active'}
                                    />
                                    <p className='news-category-title'>{item.name}</p>
                                </div>
                            </a>

                        )
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
