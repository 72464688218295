import { Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { defaultProfile } from '../../funcAndVar/var'
export default function QuestionsItem() {
    const [loading, setLoading] = useState(true)
    const { id, id2 } = useParams()
    const [category, setCategory] = useState('');
    const [author, setAuthor] = useState('');
    const [categorys, setCategorys] = useState([]);
    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        document.title = 'الأحكام'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/authors/${id}/`)
                .then(res => {
                    setAuthor(res.data);
                })
            await axios.get(`/shared/api/v1/categorys/${id2}/`)
                .then(res => {
                    setCategory(res.data);
                })
        }
        getData()
    }, [id, id2])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=7`)
                .then(res => {
                    setCategorys(res.data.results);
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/questions/api/v1/FAQS/?author=${id}&category=${id2}`)
                .then(res => {
                    setQuestions(res.data.results);
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='oasis-dinia-page'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='questions-author'>
                                        <div className='image-content'>
                                            <img alt="..." className='cover-author' src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/cover_image.png'} />
                                            <img alt="..." className='img-author' src={author.image !== null ? author.image : defaultProfile} />
                                        </div>
                                        <p className='name-author f-bold pt-3'>{author.first_name} {author.second_name} {author.last_name}</p>
                                        <p className='name-author'>{category.name}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>البحث</h5>
                                            <Input type='text' placeholder='بحث' suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>أحكام أخرى</h5>
                                            {categorys.map(item => {
                                                return <a key={item.id}>{item.name}</a>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8 col-lg-8 col-12">

                        <div className='questions-content'>
                            <p className='title-questions f-bold'><img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/icon_11.png'} width={30} /> الأحكام</p>
                            <div className="container-fluid">
                                <div className="row justify-content-center align-items-center g-2">
                                    {questions.map((item, index) => {
                                        return (
                                            <div key={item.id} className="col-12">
                                                <div className='questions-item'>
                                                    <div className='item-header'>
                                                        <p>الحكم : {index + 1}</p>
                                                    </div>
                                                    <div className='questions-answer'>
                                                        <p className='questions-text'>
                                                            <span>السؤال </span>
                                                            <span> {item.question}</span>
                                                        </p>
                                                        <p className='answer-text'>
                                                            <span>الجواب </span>
                                                            <span> {item.answer}</span>
                                                        </p>
                                                    </div>
                                                    <div className='questions-footer'>
                                                        <p>المصدر : المسائل المنتخب</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}
