import { Select } from 'antd';
import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { CiTextAlignRight } from 'react-icons/ci';
import { MdEvent, MdFilterAlt } from 'react-icons/md';
import EventCard from './EventCard';

export default function EventSort({ data, open, setOpen, days_ }) {
    const value = new Date();
    const start = 1;
    const end = 12;
    const startday = 1;
    const endday = 31;
    const monthOptions = [];
    const months = [];
    const days = [];
    for (let i = start; i <= end; i++) {
        monthOptions.push(
            <Select.Option key={i} value={i} className="month-item">
                {months[i]}
            </Select.Option>,
        );
    }
    for (let i = startday; i <= endday; i++) {
        days.push(
            <Select.Option key={i} value={i} className="month-item">
                {days[i]}
            </Select.Option>,
        );
    }
    const year = value.getFullYear();
    const month = value.getMonth() + 1;
    const options = [];
    for (let i = year - 100; i < year + 100; i += 1) {
        options.push(
            <Select.Option key={i} value={i} className="year-item">
                {i}
            </Select.Option>,
        );
    }

    return (
        <div className='event-page '>
            <div className='event-section'>
                <p className='title-section'><MdFilterAlt color='#23b5ab' size={25} /> فرز الأحداث</p>
                <div className="container-fluid pb-3">
                    <div className="row justify-content-start align-items-end g-2">
                        <div className="col-xl-1 col-lg-2 col-md-2 col-4">
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <label className='m-1'>السنة</label>
                                <Select
                                    placeholder='السنة'
                                    onChange={(e) => {
                                        console.log(e)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {options}
                                </Select>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-4">
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <label className='m-1'>الشهر</label>
                                <Select
                                    placeholder='الشهر'
                                    onChange={(e) => {
                                        console.log(e)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {monthOptions}
                                </Select>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-4">
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <label className='m-1'>اليوم</label>
                                <Select
                                    placeholder='اليوم'
                                    onChange={(e) => {
                                        console.log(e)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {days}
                                </Select>
                            </div>
                        </div>
                        <div className="col-xxl-1 col-xl-3 col-md-3 col-12">
                            <Button
                                onClick={() => setOpen(true)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                className='collapse-but w-100'
                            >
                                معاينة النتائج
                            </Button>
                        </div>
                        <Modal
                            show={open}
                            onHide={() => setOpen(false)}
                            size='lg'
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    نتائج الفرز حسب التاريخ
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <div className='event-modal'>
                                    {data.map((item, index) => {
                                        var d = new Date(item.avent_date);
                                        var dayName = d.toString().split(' ')[0];
                                        return (
                                            <EventCard
                                                id={item.id}
                                                name={item.name}
                                                dayName={days_(dayName)}
                                                date={item.avent_date}
                                            />
                                        )
                                    })}
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}
