import { Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Nav } from 'react-bootstrap'
import { FiDownload, FiShare } from 'react-icons/fi'
import { MdMoreHoriz, MdOutlineRemoveRedEye } from 'react-icons/md'
import { BiCamera, BiFullscreen, BiImage, BiPhotoAlbum } from 'react-icons/bi'
import Gallery from '../../components/gallery/Gallery'
import { downloadFile, navPage } from '../../funcAndVar/func'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useCookies } from 'react-cookie';
import { IoAlbums, IoCamera, IoHome } from 'react-icons/io5'
import Loading from '../../components/Loading';
import Share from '../../components/Share'
import NavGallery from '../../components/gallery/NavGallery'

export default function ImageItem() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams();
    const [image, setImage] = useState('')
    const [catogary, setCatogary] = useState('')
    const [photographer, setPhotographer] = useState('')
    const [images, setImages] = useState([])
    const [keys, setKeys] = useCookies('');
    useEffect(() => {

        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/gallery/api/v1/images/${id}/`)
                .then(res => {
                    document.title = res.data.name
                    setImage(res.data)
                    setCatogary(res.data.category)
                    setPhotographer(res.data.photographer)
                })
        }
        getData()
    }, [id]);
    useEffect(() => {
        async function getData() {
            await axios.get(`/gallery/api/v1/images/?category=${catogary !== null ? catogary.id : ''}&page_size=50`)
                .then(res => {
                    setImages(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [catogary]);

    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid mt-3">
                <div className="row justify-content-center align-items-center g-2">
                    <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12 gallery-nav'>
                    <NavGallery />
                    </div>
                    <div className="col-xxl-9 col-xl-11  col-md-12 col-12">
                        <div className='image-item'>
                            <div class="container-fluid m-0 p-0">
                                <div class="row justify-content-center align-items-center g-2">
                                    <div class="col-xl-6 order-xl-1 order-2">
                                        <div className='image-item-info'>
                                            <div className='gallery-images-more'>
                                                <div>
                                                    <Button variant='' title='تنزيل' onClick={() => downloadFile(image.image, image.name)} className='gallery-images-dropdown'>
                                                        <FiDownload />
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Share url={image.image} type='file'>
                                                        <Button variant='' title='مشاركة' className='gallery-images-dropdown'>
                                                            <FiShare />
                                                        </Button>
                                                    </Share>

                                                </div>
                                            </div>
                                            <div className='gallery-images-name'>
                                                <p className='image-item-title f-bold'><BiImage size={35} color='#aaa' /> {image.name}</p>
                                                <p className='image-item-dis'>{image.description}</p>
                                            </div>
                                            <div className='image-item-content'>
                                                <div class="container-fluid">
                                                    <div class="row justify-content-center align-items-center g-2">
                                                        <div class="col-md-6 col-12 ">
                                                            <div className='image-item-card'>
                                                                <div className='image-item-card-icon'><BiFullscreen size={35} /></div>
                                                                <div className='image-item-card-info'>
                                                                    <p className='image-item-card-info-title'>أبعاد الصورة</p>
                                                                    <p className='image-item-card-info-dis'>{image.dimensions}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-12">
                                                            <div className='image-item-card'>
                                                                <div className='image-item-card-icon'><BiPhotoAlbum size={35} /></div>
                                                                <div className='image-item-card-info'>
                                                                    <p className='image-item-card-info-title'>ألبوم الصورة</p>
                                                                    <p className='image-item-card-info-dis'>{catogary !== null ? <a href={`/gallery/albums/${catogary.id}/`}>{catogary.name}</a> : 'مجهول'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-12">
                                                            <div className='image-item-card'>
                                                                <div className='image-item-card-icon'><BiCamera size={35} /></div>
                                                                <div className='image-item-card-info'>
                                                                    <p className='image-item-card-info-title'>المصور</p>
                                                                    <p className='image-item-card-info-dis'>{photographer !== null ? <a href={`/gallery/photographer/${photographer.id}/`}>{photographer.first_name} {photographer.second_name} {photographer.last_name}</a> : 'مجهول'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-12">
                                                            <div className='image-item-card'>
                                                                <div className='image-item-card-icon'><MdOutlineRemoveRedEye size={35} /></div>
                                                                <div className='image-item-card-info'>
                                                                    <p className='image-item-card-info-title'>عدد المشاهدات</p>
                                                                    <p className='image-item-card-info-dis'>450</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-12 order-xl-2 order-1">
                                        <Image
                                            alt='...'
                                            src={image.image}
                                            width='100%'
                                            style={{
                                                objectFit: 'cover',
                                                width: "100%",
                                                height: 450,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-12">
                        <p className='image-item-more-image f-bold'>صور ذات صلة</p>
                        <div >
                            <Gallery 
                               url={`/gallery/api/v1/images/?page_size=100`}
                            path='/gallery/image/' />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
