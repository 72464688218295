import { MdAccessible, MdApartment, MdBadge,  MdDomain, MdEvent, MdFlag, MdFlightTakeoff, MdHistoryEdu, MdLocationCity, MdLocationOn, MdMap, MdMapsHomeWork, MdMilitaryTech, MdSchool, MdWork } from "react-icons/md";
import { RiPercentFill } from 'react-icons/ri'
import { compareDates } from "../../funcAndVar/func";
export const deadList = (data) => (
    [
        {
            key: 'محل الولادة',
            value: data.place_birth !== null ? data.place_birth.name : null,
            icon: <MdLocationOn />
        },
        {
            key: 'تاريخ الولادة',
            value: data.date_of_birth ? data.date_of_birth : null,
            icon: <MdEvent/>
        },
        {
            key: 'محل الولادة التفصيلي',
            value: data.place_of_birth_detailed ? data.place_of_birth_detailed : null,
            icon: <MdMapsHomeWork />
        },
        {
            key: 'القظاء الاداري للولادة',
            value: data.part_birth !== null ? data.part_birth.name : null,
            icon: <MdApartment />
        },
        {
            key: 'محل السكن',
            value: data.place_residence !== null ? data.place_residence.name : null,
            icon: <MdLocationCity />
        },
        {
            key: 'تاريخ الألتحاق في الفتوى',
            value: !compareDates(data.date_of_death, '2014-06-14') ? data.date_of_enrollment : 'أستشهد قبل الفتوى في معارك الدفاع المقدسة',
            icon: <MdEvent />
        },
        {
            key: 'محل الأستشهاد',
            value: data.place_death !== null ? data.place_death.name : null,
            icon: <MdMap />
        },
        {
            key: 'محل الأستشهاد التفصيلي',
            value: data.place_death_detailed ? data.place_death_detailed : null,
            icon: <MdMapsHomeWork />
        },
        {
            key: 'القظاء الاداري للأستشهاد',
            value: data.part_death !== null ? data.part_death.name : null,
            icon: <MdApartment />
        },
        {
            key: 'تاريخ الأستشهاد',
            value: data.date_of_death ? data.date_of_death : null,
            icon: <MdEvent />
        },
        {
            key: 'اللواء',
            value: data.brigade_name !== null ? data.brigade_name.name : null,
            icon: <MdFlightTakeoff />
        },
        {
            key: 'التشكيل',
            value: data.armed_faction_name !== null ? data.armed_faction_name.name : null,
            icon: <MdFlag />
        },
        {
            key: 'المديرية',
            value: data.management_name !== null ? data.management_name.name : null,
            icon: <MdDomain/>
        },
        {
            key: 'الأسم الجهادي',
            value: data.nickname ? data.nickname : null,
            icon: <MdBadge />
        },
        {
            key: 'التحصيل الدراسي',
            value: data.education ? data.education : null,
            icon: <MdHistoryEdu />
        },
        {
            key: 'الجامعة',
            value: data.university !== null ? data.university.name : null,
            icon: <MdSchool/>
        },
        {
            key: 'العمل السابق',
            value: data.previous_job ? data.previous_job : null,
            icon: <MdWork />
        },
        {
            key: 'منصب عسكري سابق',
            value: data.previous_military_position ? data.previous_military_position : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'المنصب العسكري',
            value: data.military_position ? data.military_position : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'الرتبة العسكري',
            value: data.level ? data.level : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'المنصب الأداري',
            value: data.administrative_position ? data.administrative_position : null,
            icon: <MdLocationOn />
        },
    ]
)
export const woundedList = (data) => (
    [
        {
            key: 'محل الولادة',
            value: data.place_birth !== null ? data.place_birth.name : null,
            icon: <MdLocationOn />
        },
        {
            key: 'تاريخ الولادة',
            value: data.date_of_birth ? data.date_of_birth : null,
            icon: <MdEvent />
        },
        {
            key: 'محل السكن',
            value: data.place_residence !== null ? data.place_residence.name : null,
            icon: <MdLocationCity />
        },
        {
            key: 'تاريخ الألتحاق في الفتوى',
            value: data.date_of_enrollment ? data.date_of_enrollment : null,
            icon: <MdEvent />
        },
        {
            key: 'محل الجرح',
            value: data.place_wound !== null ? data.place_wound.name : null,
            icon: <MdMap />
        },
        {
            key: 'تاريخ الجرح',
            value: data.date_of_wound ? data.date_of_wound : null,
            icon: <MdEvent />
        },
        {
            key: 'نسبة الأعاقة',
            value: data.disability_percentage ? data.disability_percentage : null,
            icon: <RiPercentFill />
        },
        {
            key: 'موضع الأعاقة',
            value: data.type_of_disability ? data.type_of_disability : null,
            icon: <MdAccessible  />
        },
        {
            key: 'اللواء',
            value: data.brigade_name !== null ? data.brigade_name.name : null,
            icon: <MdFlightTakeoff />
        },
        {
            key: 'التشكيل',
            value: data.armed_faction_name !== null ? data.armed_faction_name.name : null,
            icon: <MdFlag />
        },
        {
            key: 'المديرية',
            value: data.management_name !== null ? data.management_name.name : null,
            icon: <MdDomain />
        },
        {
            key: 'الأسم الجهادي',
            value: data.nickname ? data.nickname : null,
            icon: <MdBadge />
        },
        {
            key: 'التحصيل الدراسي',
            value: data.education ? data.education : null,
            icon: <MdHistoryEdu />
        },
        {
            key: 'العمل السابق',
            value: data.previous_job ? data.previous_job : null,
            icon: <MdWork />
        },
 
    ]
)

export const spykerList = (data) => (
    [
        {
            key: 'محل الولادة',
            value: data.place_birth !== null ? data.place_birth.name : null,
            icon: <MdLocationOn />
        },
        {
            key: 'تاريخ الولادة',
            value: data.date_of_birth ? data.date_of_birth : null,
            icon: <MdEvent />
        },
        {
            key: 'محل السكن',
            value: data.place_residence !== null ? data.place_residence.name : null,
            icon: <MdLocationCity />
        },

        {
            key: 'محل الأستشهاد',
            value: data.place_death !== null ? data.place_death.name : null,
            icon: <MdMap />
        },
        {
            key: 'تاريخ الأستشهاد',
            value: data.date_of_death ? data.date_of_death : null,
            icon: <MdEvent />
        },
        {
            key: 'الصنف',
            value: data.unit ? data.unit : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'القوة',
            value: data.military_force ? data.military_force : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'الفرقة',
            value: data.band ? data.band : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'اللواء',
            value: data.brigade ? data.brigade : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'الفوج',
            value: data.regiment  ? data.regiment : null,
            icon: <MdMilitaryTech />
        },
        {
            key: 'السرية',
            value: data.battalion ? data.battalion : null,
            icon: <MdMilitaryTech />
        },
    ]
)