import { DatePicker, Input, Select } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
export default function WoundedForm() {
    const [selectList, setSelectList] = useState([])
    const [armed, setArmed] = useState([]);
    const [brigade, setBrigade] = useState([]);
    const [citie, setCitie] = useState([]);
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Armed_faction/?page_size=100`)
                .then(res => {
                    setArmed(res.data.results);
                })
            await axios.get(`/dead_wounded/api/v1/Brigade/?page_size=100`)
                .then(res => {
                    setBrigade(res.data.results);
                })
            await axios.get(`/dead_wounded/api/v1/citie/?page_size=100`)
                .then(res => {
                    setCitie(res.data.results);
                })
        }
        getData()
    }, [])
    const list = [
        {
            name: 'اسم الجريح',
            type: 'text',
        },
        {
            name: 'اسم الأب',
            type: 'text',
        },
        {
            name: 'اسم الجد الأول',
            type: 'text',
        },
        {
            name: 'اسم الجد الثاني',
            type: 'text',
        },
        {
            name: 'اللقب',
            type: 'text',
        },
        {
            name: 'محل الولادة',
            type: 'select',
            data: citie
        },
        {
            name: 'تاريخ الولادة',
            type: 'date',
        },

        {
            name: 'محل السكن',
            type: 'select',
            data: citie
        },
        {
            name: 'تاريخ الألتحاق في الفتوى',
            type: 'date',
        },
        {
            name: 'محل الجرح',
            type: 'select',
            data: citie
        },
        {
            name: 'تاريخ الجرح',
            type: 'date',
        },
        {
            name: 'نسبة الأعاقة',
            type: 'number',
        },
        {
            name: 'موضع الأعاقة',
            type: 'text',
        },
        {
            name: 'اللواء',
            type: 'select',
            data: brigade
        },
        {
            name: 'التشكيل',
            type: 'select',
            data: armed
        },
        {
            name: 'المديرية',
            type: 'select',
            data: []
        },
        {
            name: 'الأسم الجهادي',
            type: 'text',
        },
        {
            name: 'التحصيل الدراسي',
            type: 'text',
        },
        {
            name: 'العمل السابق',
            type: 'text',
        },
    ]
    const formList = selectList.map((item, index) => {
        const items = JSON.parse(item)
        console.log(items)
        if (items.type === 'text') {
            return (
                <div className='col flex-grow-1'>
                    <label>{items.name}</label>
                    <br />
                    <Input size={'large'} placeholder={items.name} onChange={(e) => console.log({ [items.name]: e.target.value })} />
                </div>
            )
        } else if (items.type === 'select') {
            return (
                <div className='col flex-grow-1'>
                    <label>{items.name}</label>
                    <br />
                    <Select
                        size={'large'}
                        placeholder={items.name}
                        style={{
                            width: '100%',
                        }}

                    >
                        {items.data.map((item, index) => <option key={index + 1} value={JSON.stringify(item)}>{item.name}</option>)}
                    </Select>
                </div>
            )
        } else if (items.type === 'date') {
            return (
                <div className='col flex-grow-1'>
                    <label>{items.name}</label>
                    <br />
                    <DatePicker
                        size={'large'}
                        style={{
                            width: '100%',
                        }}
                        placeholder={items.name}
                    />
                </div>
            )
        } else if (items.type === 'number') {
            return (
                <div className='col flex-grow-1'>
                    <label>{items.name}</label>
                    <br />
                    <Input type='number' size={'large'} placeholder={items.name} onChange={(e) => console.log({ [items.name]: e.target.value })} />
                </div>
            )
        }
    })
    console.log(selectList)
    return (
        <div className="container-fluid">
            <div className="row justify-content-start align-items-center g-2 mb-3">
                <div className="col-12">
                    <Select
                        mode="multiple"
                        size={'large'}
                        maxTagCount='responsive'
                        placeholder="اختر البيانات المطلوبة"
                        onChange={(e) => setSelectList(e)}
                        style={{
                            width: '100%',
                        }}
                    >
                        {list.map((item, index) => <option key={index + 1} value={JSON.stringify(item)}>{item.name}</option>)}
                    </Select>
                </div>
                <div class="container-fluid">
                    <div class="row row-cols-1 row-cols-lg-4 row-cols-md-2 justify-content-center align-items-center  g-2">
                        {formList}
                    </div>
                </div>
                {selectList.length > 0 &&
                    <div className="col-12 mt-5 d-flex justify-content-center align-items-center">
                        <Button variant='' onClick={(e) => console.log(e)} className='modal-but'>ارسال البيانات</Button>
                    </div>
                }
            </div>
        </div>
    )
}
