import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Nav, Tab } from 'react-bootstrap'
import Gallery, { GalleryAlpum, GalleryPhotographer } from '../../components/gallery/Gallery'
import { navPage } from '../../funcAndVar/func'
import { useCookies } from 'react-cookie';
import { IoAlbums, IoCamera, IoHome, IoSearch } from "react-icons/io5";
import Loading from '../../components/Loading';
import { Input } from 'antd'
import Empty from '../../components/Empty'

export default function GalleryHome() {
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('');
    const [keys, setKeys] = useCookies(['nav_gallery']);
    useEffect(() => {
        document.title = 'معرض جمال'
        navPage()
    }, [])

 
    return (
        <div>
            <Tab.Container
                transition={true}
                onSelect={(e) => setKeys('nav_gallery', e)}
                id="left-tabs-example"
                defaultActiveKey={keys.nav_gallery ? keys.nav_gallery : "first"}
            >
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center g-2">

                        <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12 gallery-nav'>
                            <div className='gallery-nav-content'>
                                <Nav variant="pills" className="gallery-nav-items">
                                    <Nav.Item>
                                        <Nav.Link title='الرئيسية' eventKey="first"><IoHome size={20} /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link title='الألبومات' eventKey="second"><IoAlbums size={20} /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link title='المصورين' eventKey="third"><IoCamera size={20} /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link title='بحث' eventKey="forth"><IoSearch size={20} /></Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='gallery-content'>
                                        <Gallery
                                            path='/gallery/image/'
                                            url={`/gallery/api/v1/images/?page_size=100`}
                                            setLoading={setLoading}
                                            loading={loading}
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className='gallery-content'>
                                        <GalleryAlpum
                                            path='/gallery/albums/'
                                            setLoading={setLoading}
                                            loading={loading}
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className='gallery-content'>
                                        <GalleryPhotographer
                                            path='/gallery/photographer/'
                                            setLoading={setLoading}
                                            loading={loading}
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="forth">
                                    <div
                                        class="container-fluid"
                                    >
                                        <div
                                            class="row justify-content-center align-items-center g-2"
                                        >
                                            <div className="col-xl-5 col-lg-6">
                                                <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                                                    <Input
                                                        type='text'
                                                        size='large'
                                                        placeholder='بحث'
                                                        suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                        style={{
                                                            borderRadius: 5,
                                                            width: '100%'
                                                        }}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                {search ?
                                                    <div className='gallery-content'>
                                                        <Gallery
                                                            path='/gallery/image/'
                                                            url={`/gallery/api/v1/images/?page_size=100&search=${search}`}
                                                            effect={search}
                                                            setLoading={setLoading}
                                                            loading={loading}
                                                        />
                                                    </div>
                                                    :
                                                    <Empty />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
    )  
   
}
