import { Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { defaultProfile2 } from '../../funcAndVar/var';
import { AuthorCard } from '../../components/cards';
export default function DeadRemains() {
    const [loading, setLoading] = useState(true)
    const [dead, setDead] = useState([]);
    useEffect(() => {
        document.title = 'شهدائنا'
        navPage()
    }, [])

    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/Death/?has_remain=true&page_size=60')
                .then(res => {
                    setDead(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div className="remains-page">
            <div className="container-fluid mt-3">
                <div className="row justify-content-start align-items-center g-2">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='header-link'>
                            <a className='header-link-item' href={`/remains/`}>الأثار</a>
                            <a className='header-link-item'>الشهداء</a>
                        </div>
                    </div>
                    {dead.length > 0 ?
                        <>
                            {dead.map((item) => {
                                return (
                                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-12">
                                        <AuthorCard
                                            id={item.id}
                                            title='شهيد'
                                            path={`/remains/dead/${item.id}/`}
                                            name={`${item.first_name} ${item.second_name} ${item.third_name} ${item.last_name}`}
                                            image={item.image}
                                            infoTitleOne='اثر'
                                            infoTitleTow='متابع'
                                            infoCountOne={item.r_count}
                                            infoCountTow={50}
                                        />
                                    </div>
                                )
                            })}
                        </>
                        : <Empty />}
                </div>
            </div>
        </div>
    )
}
