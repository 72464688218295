import { Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { navPage } from '../../funcAndVar/func'
import Loading from '../../components/Loading';

export default function Questions() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [category, setCategory] = useState([]);
    useEffect(() => {
        document.title = 'أستفتاءات'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=7&page_size=50`)
                .then(res => {
                    setCategory(res.data.results);
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='oasis-dinia-page'>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>البحث</h5>
                                            <Input type='text' placeholder='بحث' suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className='side-list'>
                                        <div className='links-list'>
                                            <h5>الأقسام</h5>
                                            <a href='/oasis_dinia/quran/'>القرآن الكريم</a>
                                            <a href='/oasis_dinia/pray_and_visits/'>الادعية والزيارات</a>
                                            <a href='/oasis_dinia/sahifa_and_mafatih/'>أنوار العاشقين</a>
                                            <a>الأستفتاءات</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8 col-lg-8 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <div className='pray-list'>
                                        <p className='name-list f-bold'><img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/icons/icon_9.png'} width={30} /> الأحكام</p>
                                        <div className="container">
                                            <div className="row justify-content-center align-items-center g-2">
                                                {category.map(item => {
                                                    return (
                                                        <div key={item.id} className='col-xl-6 col-12 pray-item'>
                                                            <a className='list-item' href={`/oasis_dinia/questions/author/${id}/section/${item.id}/`}>
                                                                <p className='pray-name'>{item.name}</p>
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
