import { saveAs } from 'file-saver';
import axios from 'axios';
export const navPage = () => {
    //nav link color
    const dropdown = document.getElementsByClassName("dropdown-bot");
    for (let i = 0; i < dropdown.length; i++) {
        dropdown[i].classList.remove('dropdown-bot_page');
    }
    //logo color
    const logo = document.getElementsByClassName("st0");
    for (let i = 0; i < logo.length; i++) {
        logo[i].style.fill = "#23b5ab";
    }
    // navbar
    const nav = document.getElementById("nav-three")
    nav.classList.remove("nav-three_page")
    nav.style.margin = '0px'
    // logo link
    const logolink = document.getElementsByClassName("logo-link");
    for (let i = 0; i < logolink.length; i++) {
        logolink[i].style.color = "#23b5ab";
    }
}
export const bookItem = () => {
    const nav = document.getElementById('navbar-content');
    const footer = document.getElementById('footer');
    nav.style.display = 'none';
    footer.style.display = 'none';
}
export const navHome = () => {
    //nav link color
    const dropdown = document.getElementsByClassName("dropdown-bot");
    for (let i = 0; i < dropdown.length; i++) {
        dropdown[i].classList.add('dropdown-bot_page');
    }
    //logo color
    const logo = document.getElementsByClassName("st0");
    for (let i = 0; i < logo.length; i++) {
        logo[i].style.fill = "#fff";
    }
    // navbar
    const nav = document.getElementById("nav-three")
    nav.classList.add("nav-three_page")
    nav.style.margin = '-15px 0'
    // logo link
    const logolink = document.getElementsByClassName("logo-link");
    for (let i = 0; i < logolink.length; i++) {
        logolink[i].style.color = "#fff";
    }
}

export const styleNav = () => {
    const navbar = document.getElementById('navbar-content');
    const nav1 = document.getElementById('nav-one');
    const nav2 = document.getElementById('nav-two');
    let scrolled = false;
    let path = window.location.pathname
    document.addEventListener('scroll', () => {
        if (500 < window.pageYOffset && !scrolled) {
            navbar.classList.add('fixed-top')
            nav1.style.display = 'none';
            nav2.style.display = 'none';
            if (path === '/') navPage();
            scrolled = true;
        };
        if (500 > window.pageYOffset && scrolled) {
            navbar.classList.remove('fixed-top')
            nav1.style.display = 'flex';
            nav2.style.display = 'flex';
            if (path === '/') navHome();
            scrolled = false;
        };
    })
}
export function getTimeDifference(startTime, endTime) {
    const difference = endTime - startTime;
    const differenceInMinutes = difference / 1000 / 60;
    let hours = Math.floor(differenceInMinutes / 60);
    if (hours < 0) {
        hours = 24 + hours;
    }
    let minutes = Math.floor(differenceInMinutes % 60);
    if (minutes < 0) {
        minutes = 60 + minutes;
    }
    let sec = Math.floor(differenceInMinutes % 60);
    if (sec < 0) {
        sec = 60 + sec;
    }
    const hoursAndMinutes = hours + ":" + (minutes < 10 ? '0' : '') + minutes + ":" + (sec < 10 ? '0' : '') + sec;
    return {
        hours: String(hours),
        minutes: (minutes < 10 ? '0' : '') + minutes,
        sec: (sec < 10 ? '0' : '') + sec

    };
}
function dataURItoBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString =
        splitDataURI[0].indexOf('base64') >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
}
export function downloadFile(url, filename) {
    axios.get(url, { responseType: 'blob' })
        .then(response => {
            saveAs(response.data, filename);
        });
}

export function compareDates(date1, date2) {
    const timestamp1 = new Date(date1).getTime();
    const timestamp2 = new Date(date2).getTime();

    if (timestamp1 < timestamp2) {
        return true;
    } else {
        return false;
    }
}