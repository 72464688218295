import React from 'react'
import { Nav } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { useNavigate } from "react-router-dom"
import { IoAlbums, IoCamera, IoHome, IoSearch } from 'react-icons/io5';

export default function NavGallery() {
  const [eventKey, setEventKey] = useCookies(['nav_gallery'])
  const navigate = useNavigate();
  const onClickNav = (key) => {
    setEventKey('nav_gallery', key)
    navigate("/gallery/");
  }
  return (
    <div className='gallery-nav-content'>
      <Nav variant="pills" className="gallery-nav-items">
        <Nav.Item>
          <Nav.Link title='الرئيسية' onClick={() => onClickNav('first')}  ><IoHome size={20} /></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link title='الألبومات' onClick={() => onClickNav('second')} ><IoAlbums size={20} /></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link title='المصورين' onClick={() => onClickNav('third')} ><IoCamera size={20} /></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link title='بحث' onClick={() => onClickNav('forth')} eventKey="forth"><IoSearch size={20} /></Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  )
}
