import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { navPage } from '../../funcAndVar/func'
import PaginationPage from '../../components/Pagination';
import { Accordion, Button } from 'react-bootstrap';
import { Avatar, DatePicker, Image, Input, Select, Spin } from 'antd';
import { ImAirplane } from 'react-icons/im'
import { GiMachineGunMagazine } from 'react-icons/gi'
import { defaultProfile2 } from '../../funcAndVar/var';
export default function WoundedList() {
    const [dead, setDead] = useState([]);
    const [deads, setDeads] = useState([]);
    const [armed, setArmed] = useState([]);
    const [brigade, setBrigade] = useState([]);
    const [citie, setCitie] = useState([]);
    const [searchArmed, setSearchArmed] = useState('');
    const [searchBrigade, setSearchBrigade] = useState('');
    const [searchCitie, setSearchCitie] = useState('');
    const [search, setSearch] = useState('');
    const [change, setChange] = useState({ citie_one: '', citie_two: '', armed: '', brigade: '', date: '' });
    const [count, setCount] = useState(0);
    const [picker, setPicker] = useState('');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useCookies('');
    const { page_wounded } = page;
    useEffect(() => {
        document.title = 'جرحانا'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Wounded/?place_residence=${change.citie_one}&place_wound=${change.citie_two}&armed_faction_name=${change.armed}&brigade_name=${change.brigade}&search=${change.date}&page_size=100&page=${page_wounded ? page_wounded : 1}`)
                .then(res => {
                    setDead(res.data.results);
                    setCount(res.data.count);
                    setLoading(false)
                }).catch(err => {
                    if (err.response.data.detail = 'Invalid page.') {
                        setPage('page_wounded', 1)
                    }
                })
        }
        getData()
    }, [page_wounded, change])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Wounded/?search=${search}`)
                .then(res => {
                    setDeads(res.data.results);
                })
        }
        getData()
    }, [search])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Armed_faction/?page_size=100&search=${searchArmed}`)
                .then(res => {
                    setArmed(res.data.results);
                })
            await axios.get(`/dead_wounded/api/v1/Brigade/?page_size=100&search=${searchBrigade}`)
                .then(res => {
                    setBrigade(res.data.results);
                })
            await axios.get(`/dead_wounded/api/v1/citie/?page_size=100&search=${searchCitie}`)
                .then(res => {
                    setCitie(res.data.results);
                })
        }
        getData()
    }, [])
    const ImageCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props} style={{ padding: 0 }}>
                <div
                    style={{
                        width: 40,
                        height: 40,
                        background: '#f5f5f5',
                        borderRadius: 6,
                        marginTop: 2,
                        overflow: 'hidden',
                        display: 'inline-block'
                    }}
                >
                    <Image preview={{ mask: false }} src={rowData.image !== null ? rowData.image : defaultProfile2} width="40" height='100%' style={{ objectFit: 'cover' }} />
                </div>
            </Cell>
        )
    };
    const NameCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <a style={{ color: '#000' }} href={`/wounded/list/${rowData.id}/`}>{rowData[dataKey]}</a>
        </Cell>
    );
    const CountCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            <a style={{ color: '#000' }} href={`/wounded/list/${rowData.id}/`}>{(((page_wounded ? page_wounded : 1) - 1) * 100) + (rowIndex + 1)}</a>
        </Cell>
    );
    const renderLoading = () => {
        return (
            <div className=' d-flex justify-content-center align-items-start w-100 mt-5'>
                <Spin size="large" tip="جاري التحميل" />
            </div>

        );
    };
    return (
        <div>
            <div className="container-fluid mt-3">
                <div className="row justify-content-center align-items-start g-2">
                    <div className="col-xxl-2 col-xl-3 col-md-4 col-12">
                        <Accordion defaultActiveKey="1">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>فرز</Accordion.Header>
                                <Accordion.Body>
                                    <p className='label'>محل السكن</p>
                                    <Select
                                        showSearch
                                        className='col-12'
                                        placeholder='الكل'
                                        suffixIcon={<i className="fa-solid fa-city"></i>}
                                        onChange={(e) => setChange({ ...change, citie_one: e })}
                                        onSearch={(e) => setSearchCitie(e)}
                                    >
                                        <option value=''>الكل</option>
                                        {citie.map((item) => { return (<option key={item.id} value={item.id}>{item.name}</option>) })}
                                    </Select>
                                    <p className='label'>محل الجرح</p>
                                    <Select
                                        showSearch
                                        className='col-12'
                                        placeholder='الكل'
                                        suffixIcon={<i className="fa-solid fa-building"></i>}
                                        onChange={(e) => setChange({ ...change, citie_two: e })}
                                        onSearch={(e) => setSearchCitie(e)}
                                    >
                                        <option value=''>الكل</option>
                                        {citie.map((item) => { return (<option key={item.id} value={item.id}>{item.name}</option>) })}
                                    </Select>
                                    <p className='label'>تاريخ الجرح</p>
                                    <div className='d-flex justify-content-between'>
                                        <Select
                                            className='col-5'
                                            style={{
                                                marginLeft: 2
                                            }}
                                            placeholder='الكل'
                                            onChange={(e) => setPicker(e)}
                                            defaultValue={''}
                                        >
                                            <option value=''>تاريخ كامل</option>
                                            <option value='year'>سنة</option>
                                            <option value='month'>شهر وسنة</option>

                                        </Select>
                                        <DatePicker
                                            className='col-7 rounded-end'
                                            onChange={(date, dateString) => setChange({ ...change, date: dateString })}
                                            picker={picker}
                                            placeholder='اختر التاريخ'
                                        />
                                    </div>

                                    <p className='label'>اللواء</p>
                                    <Select
                                        showSearch
                                        className='col-12'
                                        placeholder='الكل'
                                        suffixIcon={<ImAirplane />}
                                        onChange={(e) => setChange({ ...change, brigade: e })}
                                        onSearch={(e) => setSearchBrigade(e)}
                                    >
                                        <option value=''>الكل</option>
                                        {brigade.map((item) => { return (<option key={item.id} value={item.id}>{item.name}</option>) })}
                                    </Select>
                                    <p className='label'>التشكيل</p>
                                    <Select
                                        showSearch
                                        className='col-12'
                                        placeholder='الكل'
                                        suffixIcon={<GiMachineGunMagazine size={20} />}
                                        onChange={(e) => setChange({ ...change, armed: e })}
                                        onSearch={(e) => setSearchArmed(e)}
                                    >
                                        <option value=''>الكل</option>
                                        {armed.map((item) => { return (<option key={item.id} value={item.id}>{item.name}</option>) })}
                                    </Select>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>بحث</Accordion.Header>
                                <Accordion.Body>
                                    <Input type='text' placeholder='بحث' suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>} onChange={(e) => setSearch(e.target.value)} />
                                    {search &&
                                        <div className='search-news'>
                                            {deads.map(item => {
                                                return (
                                                    <div key={item.id} title={`${item.first_name} ${item.second_name} ${item.third_name} ${item.last_name}`}>
                                                        <Button as='a' href={`/dead/list/${item.id}/`} className='search-news-card w-100'>
                                                            <p className='search-news-title'><Avatar src={item.image !== null ? item.image : defaultProfile2} size={40} /> {item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                                        </Button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="col-xxl-10 col-xl-9 col-md-8 col-12">
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <h1>قائمة الجرحى</h1>
                                </div>
                                <div className="col-12">
                                    <Table
                                        loading={loading}
                                        virtualized
                                        height={760}
                                        data={dead}
                                        rtl={true}
                                        renderEmpty={() => {
                                            return <div className="rs-table-body-info">لاتوجد بيانات</div>;
                                        }}
                                        renderLoading={renderLoading}
                                    >
                                        <Column width={70} align="center" fixed>
                                            <HeaderCell>ت</HeaderCell>
                                            <CountCell dataKey="id" />
                                        </Column>

                                        <Column width={80} align="center" fixed>
                                            <HeaderCell>الصورة</HeaderCell>
                                            <ImageCell dataKey="avartar" />
                                        </Column>

                                        <Column align='center' fullText fixed resizable>
                                            <HeaderCell>الأسم الأول</HeaderCell>
                                            <NameCell dataKey="first_name" />
                                        </Column>

                                        <Column align='center' fullText resizable>
                                            <HeaderCell>اسم الأب</HeaderCell>
                                            <NameCell dataKey="second_name" />
                                        </Column>

                                        <Column align='center' fullText resizable>
                                            <HeaderCell>اسم الجد الاول</HeaderCell>
                                            <NameCell dataKey="third_name" />
                                        </Column>

                                        <Column align='center' fullText resizable>
                                            <HeaderCell>اسم الجد الثاني</HeaderCell>
                                            <NameCell dataKey="grandfather_name" />
                                        </Column>

                                        <Column align='center' fullText resizable>
                                            <HeaderCell>اللقب</HeaderCell>
                                            <NameCell dataKey="last_name" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>تاريح الولادة</HeaderCell>
                                            <Cell dataKey="date_of_birth" />
                                        </Column>
                                        <Column width={100} align='center' fullText resizable>
                                            <HeaderCell>محل الولادة</HeaderCell>
                                            <Cell dataKey="place_birth.name" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>محل السكن</HeaderCell>
                                            <Cell dataKey="place_residence.name" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>تاريخ الالتحاق بالفتوى</HeaderCell>
                                            <Cell dataKey="date_of_enrollment" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>تاريخ الجرح</HeaderCell>
                                            <Cell dataKey="date_of_wound" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>محل الجرح</HeaderCell>
                                            <Cell dataKey="place_wound.name" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>نسبة الأعاقة</HeaderCell>
                                            <Cell dataKey="disability_percentage" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>موضع الأعاقة</HeaderCell>
                                            <Cell dataKey="type_of_disability" />
                                        </Column>
                                        <Column width={200} align='center' fullText resizable>
                                            <HeaderCell>الواء</HeaderCell>
                                            <Cell dataKey="brigade_name.name" />
                                        </Column>
                                    </Table>
                                </div>
                                <div className="col-12">
                                    <PaginationPage
                                        defaultCurrent={!page_wounded ? 1 : Number(page_wounded)}
                                        total={count}
                                        defaultPageSize={100}
                                        onChange={(e) => setPage('page_wounded', e)}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
