import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Col, Row, Nav, Tab, Carousel } from 'react-bootstrap';
import CarouselHome, { CarouselHomeTwo } from '../components/home/CarouselHome';
import NewsCard from '../components/home/NewsCard';
import QuoteHome from '../components/home/QuoteHome';
import SoundHome from '../components/home/SoundHome';
import VideoHome from '../components/home/VideoHome';
import { navHome } from '../funcAndVar/func';
import Slider from "react-slick";
import Loading from '../components/Loading';
import { defaultProfile } from '../funcAndVar/var';
import { BsApple, BsAndroid2, BsDatabase, BsNewspaper, BsFeather, BsBlockquoteLeft, BsHeadsetVr } from 'react-icons/bs';
import { env, mediaURL, melodyURL } from '../config';
import GalleryHome from '../components/gallery/GalleryHome';
import { TapsContentScale, TapsScale } from '../components/home/TapsScale';
import { PiArticleLight } from "react-icons/pi";
import { Button } from 'antd'
import { LuCalendarDays, LuListMusic, LuListVideo } from 'react-icons/lu';
import { GiPrayerBeads } from 'react-icons/gi';
export default function Home() {
    const [loading, setLoading] = useState(true)
    const [index, setIndex] = useState(0);
    const [indexTab, setIndexTab] = useState(0);
    const [gallery, setGallery] = useState([]);
    const [video, setVideo] = useState([]);
    const [firstVideo, setFirstVideo] = useState({});
    const [sound, setSound] = useState([]);
    const [news, setNews] = useState([]);
    const [articles, setArticles] = useState([]);
    const [events, setEvents] = useState([]);
    const [qoute, setQoute] = useState([]);
    const [dead, setDead] = useState([]);
    const [dead2, setDead2] = useState([]);
    const [category, setCategory] = useState([]);
    const [authors, setAuthors] = useState([]);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        navHome()
    }, []);

    useEffect(() => {
        async function getData() {
            const page = env === 'testing' ? 1 : 10;
            await axios.get('/gallery/api/v1/images/')
                .then(res => {
                    setGallery(res.data.results);
                });
            await axios.get('/video/api/v1/videos/?is_active=true&status=ok&page_size=8')
                .then(res => {
                    setVideo(res.data.results);
                    setFirstVideo(res.data.results[0]);
                });
            await axios.get('/sound/api/v1/sounds/?is_active=true')
                .then(res => {
                    setSound(res.data.results);
                });
            await axios.get(`/oasis/api/v1/news/?page_size=5`)
                .then(res => {
                    setNews(res.data.results);
                });
            await axios.get(`/avent_day/api/v1/avents/?page_size=5`)
                .then(res => {
                    setEvents(res.data.results);
                });
            await axios.get(`/oasis/api/v1/articles/?page_size=5`)
                .then(res => {
                    setArticles(res.data.results);
                });
            await axios.get(`/dead_wounded/api/v1/quotes/?type=0&page_size=4`)
                .then(res => {
                    setQoute(res.data.results);
                });
            await axios.get(`/dead_wounded/api/v1/Death/`)
                .then(res => {
                    setDead(res.data.results);
                });
            await axios.get(`/dead_wounded/api/v1/Death/?page=${page}`)
                .then(res => {
                    setDead2(res.data.results);
                });
            await axios.get(`/shared/api/v1/categorys/?type=1&page_size=4`)
                .then(res => {
                    setCategory(res.data.results);
                });
            await axios.get(`/shared/api/v1/authors/?type=1`)
                .then(res => {
                    setAuthors(res.data.results);
                    if (res.status === 200) setLoading(false);
                });
        }
        getData()
    }, [])
    const responsive = [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        arrows: false,
        responsive: responsive
    };
    const settingslit = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        arrows: false,
        responsive: responsive

    };
    if (loading) {
        return <Loading />
    };
    return (
        <div className="home-container" >
            <section className="home-header">
                <div className="image-container ">
                    <Carousel fade controls={false} indicators={false} style={{ zIndex: 1, width: '100%', height: '100%' }}>
                        <Carousel.Item>
                            <img alt="..."
                                className='home-header-image'
                                src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/home_image.JPG'}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt="..."
                                className='home-header-image'
                                src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/home_image2.JPG'}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt="..."
                                className='home-header-image'
                                src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/home_image3.jpg'}
                            />
                        </Carousel.Item>
                    </Carousel>
                    <div className="bg-image"></div>
                </div>
                <div className="header-info">
                    <p className="header-text">عن جمال</p>
                    <p className="header-text-title">سيكرمني الله بالشهادة</p>
                    <p className="header-svg"></p>
                    <p className="header-text">اقتباسات</p>
                </div>
            </section>
            <section className="seven-section">
                <h2 className="mb-5 border-title">الشهداء</h2>
                <div class="container-fluid seven-content">
                    <div class="row justify-content-center align-items-center g-2">
                        <div class="col-lg-8 col-md-8 col-sm-7">
                            <div className='dead-content'>
                                <div class="row justify-content-center align-items-center g-2">
                                    <div class="col-12">
                                        <Slider {...settings} rtl={true}>
                                            {dead.map((item) => {
                                                return (
                                                    <div key={item.id} className='d-flex flex-column justify-content-center align-items-center h-100'>
                                                        <p>{item.first_name} {item.second_name} {item.third_name}  {item.last_name} </p>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                    <div class="col-12">
                                        <Slider {...settings}>
                                            {dead2.map((item) => {
                                                return (
                                                    <div key={item.id} className='d-flex flex-column justify-content-center align-items-center h-100'>
                                                        <p>{item.first_name} {item.second_name} {item.third_name} {item.last_name} </p>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-5 d-flex justify-content-center align-items-center">
                            <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/bg_main_012.png'} className='image-dead-section' />
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <Button href='/dead/list/' icon={<BsDatabase size={20} />} variant="" className="but-home but-daed" style={{ zIndex: 10 }}>الولوج لقاعدة بيانات الشهداء </Button>
                        </div>
                    </div>
                </div>
            </section >
            {/* <section className='home-tabs'>
                <h2 className="mb-5 border-title">خدماتنا</h2>
                <div className="container">
                    <div className="row justify-content-center align-items-center g-2">
                        <div className="col-12">
                            <TapsScale indexTab={indexTab} onSelect={(e) => setIndexTab(e)} />
                        </div>
                        <div className="col-12">
                            <TapsContentScale indexTab={indexTab} />
                        </div>
                    </div>
                </div>
            </section> */}
            {events.length > 0 &&
                <section className="home-serves">
                    <h2 className="mb-5 border-title">مذكرة جمال</h2>
                    <div className="home-serves-content">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-xl-8 col-12">
                                    <CarouselHome data={events} activeIndex={index} onSelect={handleSelect} />
                                </div>
                                <div className="col-xl-4 col-12">
                                    <CarouselHomeTwo data={events} activeIndex={index} onSelect={handleSelect} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button variant="" href='/event/' icon={<LuCalendarDays size={20} />} className="but-home mt-5" style={{ zIndex: 10 }}>مذكرة جمال</Button>
                </section>
            }
            <section className="home-gallery-2" style={{ zIndex: 2 }}>
                <div class="container">
                    <div class="row justify-content-center align-items-center g-2">
                        <div class="col-12">
                            <GalleryHome data={gallery} />
                        </div>
                    </div>
                </div>
            </section>
            {news.length > 0 ?
                <section className="home-news">
                    <h2 className="m-5 border-title">أخبار جمال</h2>
                    <div class="container">
                        <div class="row justify-content-center align-items-center g-2"
                        >
                            <div class="col">
                                <NewsCard more='المزيد من الاخبار' data={news} path='/news/' />
                            </div>
                        </div>
                    </div>

                    <Button variant="" icon={<BsNewspaper size={20} />} href='/news/' className="but-home but-home_op" style={{ zIndex: 10 }}>المزيد من الأخبار</Button>
                </section>
                : null}
            {qoute.length > 0 ?
                <section className="home-quote">
                    <h2 className="m-5 border-title">أقتباسات الشهداء</h2>
                    <div className="container">
                        <QuoteHome data={qoute} />
                    </div>
                    <Button variant="" href='/quote/' icon={<BsBlockquoteLeft size={20} />} className="but-home mt-5" style={{ zIndex: 10 }}>أقتباسات الشهداء </Button>
                </section>
                : null}
            <section className='lit-section'>
                <h2 className="m-5 border-title">الأدب المقاوم</h2>
                <div className="container">
                    <div className="row justify-content-center align-items-center g-2">
                        <div className='col-12'>
                            <div class="container-fluid">
                                <div class="row justify-content-center align-items-center g-2">
                                    <div class="col-xl-5 fs-25 f-bold">عَشِقوا الشهادةَ ذَوداً عن محارِمِهم</div>
                                    <div class="col-2"></div>
                                </div>
                                <div class="row justify-content-center align-items-center g-2">
                                    <div class="col-5"></div>
                                    <div class="col-xl-5 fs-25 f-bold">فخُلِّدوا من وراءِ الموتِ باقينا</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <h5 className='f-bold'>الأقسام</h5>
                            <Carousel fade controls={false} style={{ zIndex: 1, width: '100%', height: '100%' }}>
                                {category.map(item => {
                                    return (
                                        <Carousel.Item key={item.id}>
                                            <a href={`/poetry/category/${item.id}/`}>
                                                <div className='author-carousel-card'>
                                                    <img
                                                        alt="..."
                                                        className='image-item'
                                                        src={item.image !== null ? item.image : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/mokhadramoun.jpg'}
                                                    />
                                                    <div className='name-item'>
                                                        <p className='title'>{item.name}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                        </div>
                        <div className="col-lg-8">
                            <h5 className='f-bold'>الشعراء</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center align-items-center g-2">
                                    <div className="col-12">
                                        <Slider {...settingslit} rtl={true}>
                                            {authors.map((item) => {
                                                return (
                                                    <a href={`/poetry/authors/${item.id}/`} key={item.id}>
                                                        <div key={item.id} className='slider-lit'>
                                                            <img alt='...' src={item.image !== null ? item.image : defaultProfile} className='slider-lit-image' />
                                                            <div className='slider-lit-name'>
                                                                <p className='title'>{item.first_name} {item.second_name}  {item.last_name} </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                    <div className="col-12">
                                        <Slider {...settingslit}>
                                            {authors.map((item) => {
                                                return (
                                                    <a href={`/poetry/authors/${item.id}/`} key={item.id}>
                                                        <div key={item.id} className='slider-lit'>
                                                            <img alt='...' src={item.image !== null ? item.image : defaultProfile} className='slider-lit-image' />
                                                            <div className='slider-lit-name'>
                                                                <p className='title'>{item.first_name} {item.second_name}  {item.last_name} </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button variant="" href='/poetry/' icon={<BsFeather size={20} />} className="but-home" style={{ zIndex: 10, margin: 25 }}>الأدب المقاوم</Button>
            </section>
            <section className="home-media" style={{ zIndex: 2 }}>
                <h2 className="m-5 border-title">الوسائط</h2>
                <div className="container">
                    <div className="row justify-content-center align-items-center g-4">
                        {video &&
                            <div className="col-xl-7 d-flex flex-column justify-content-center align-items-center">
                                <h5 className="m-1 f-bold w-100">جمال ميديا</h5>
                                <VideoHome data={video} firstItem={firstVideo} />
                                <Button variant="" icon={<LuListVideo size={20} />} onClick={(e) => window.open(mediaURL)} className="but-home" style={{ zIndex: 10 }}>جمال ميديا</Button>
                            </div>}
                        {sound &&
                            <div className="col-xl-5 d-flex flex-column justify-content-center align-items-center">
                                <h5 className="m-1 f-bold w-100">جمال ميلوديا</h5>
                                <SoundHome data={sound} />
                                <Button variant="" icon={<LuListMusic size={20} />} onClick={(e) => window.open(melodyURL)} className="but-home" style={{ zIndex: 10 }}>جمال ميلوديا</Button>
                            </div>
                        }
                    </div>
                </div>
            </section>
            <div className='forth-section' style={{ zIndex: 2 }}>
                <p className="mt-5 mb-2 fs-3 f-bold">الزيارة الافتراضية</p>
                <p className="m-2 fs-2"> أينما كنت.. يمكنك زيارة قبر الشهيد.</p>
                <Button variant="" className="but-home" icon={<BsHeadsetVr size={20} />} style={{ zIndex: 10 }}>الزيارة الافتراضية</Button>
                <div className='vr-content'>
                    <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/vr_s.png'} />
                    <div className='vr-point'>
                        <div className='position position-1' >
                            <p className='point'>
                                <span className='point-dot' />
                                <span className='point-style'>قبر الشهيد عز الدين سليم ورفقائة</span>
                            </p>
                        </div>
                        <div className='position position-2' >
                            <p className='point '>
                                <span className='point-dot' />
                                <span className='point-style'>مقبرة ابو ايوب البصري</span>
                            </p>
                        </div>
                        <div className='position position-3' >
                            <p className='point'>
                                <span className='point-dot' />
                                <span className='point-style'>قبر الشهيد ابو مهدي المهندس ورفقائة</span>
                            </p>
                        </div>
                        <div className='position position-4' >
                            <p className='point'>
                                <span className='point-dot' />
                                <span className='point-style'>مقبرة كتائب سيد الشهداء (ع)</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pray-section" style={{ zIndex: 2 }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center g-4">
                        <div className="col order-1 order-xl-0">
                            <div className='pray-section-info'>
                                <p className='f-bold border-title'>سبل السعادة</p>
                                <p className='discraption'>
                                    مرحباً بزائرنا الكريم في خدمة "سبل السعادة" على بوابة جمال الرقمية والتي هي خدمة دينية شاملة تهدف لرفع المستوى الديني والعقائدي للمجاهدين في هيئة الحشد الشعبي تم تطوير هذه الخدمة لتلبية احتياجات المقاتل والافراد الذين يسعون للتواصل مع الجانب الروحاني من حياتهم
                                    تعتبر" سبل السعادة" مصدراً شاملاً للمعرفة الدينية ، حيث تحتوي على مجموعة متنوعة من المحتوى القيم من بين هذا المحتوى ستجد استفتاءات شرعية لمجموعة كبيرة من فقهاء مدرسة اهل البيت صلوات الله عليهم اجمعين  تهم الأفراد في عباداتهم ومعاملاتهم اليومية حيث يمكنهم طرح الأسئلة والاستفتاءات الدينية والحصول على إجابات مبنية وفق اراء مراجع التقليد
                                    بالإضافة إلى ذلك، توفر "سبل السعادة" نصوص القرآن الكريم للاستماع والقراءة، مما يمكن المستخدمين من استكشاف وتدبر آيات الله. كما توفر الخدمة الصحيفة السجادية ومفاتيح الجنان والزيارات لأئمة أهل البيت (عليهم السلام)، مما يساعد المستخدمين على الاقتراب من أئمة اهل البيت عليهم السلام والتعرف على تعاليمهم ونصائحهم. بالإضافة إلى ذلك، توفر "سبل السعادة" مواقيت الصلاة الدقيقة والمحدثة، حيث يمكن للمستخدمين معرفة أوقات الصلاة وتنظيم جدولهم اليومي وفقًا لها. تم تصميم الخدمة لتوفير تجربة متكاملة وسلسة للمستخدمين
                                </p>
                                <Button variant="" href='/oasis_dinia/' icon={<GiPrayerBeads size={20} />} className="but-home" style={{ zIndex: 10, width: 200 }}>سبل السعادة</Button>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="lab-item">
                                <div className="lab-inner">
                                    <div className="lab-thumb">
                                        <div className="img-grp">
                                            <div className="about-circle-wrapper">
                                                <div className="about-circle-2"></div>
                                                <div className="about-circle"></div>
                                            </div>
                                            <div className="about-fg-img">
                                                <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/quran-home.png'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {articles.length > 0 ?
                <section className="home-news">
                    <h2 className="m-5 border-title">التدوينات</h2>
                    <div class="container">
                        <div class="row justify-content-center align-items-center g-2"
                        >
                            <div class="col">
                                <NewsCard more='المزيد من التدوينات' data={articles} path='/article/' />
                            </div>
                        </div>
                    </div>
                    <Button variant="" href='/article/' icon={<PiArticleLight size={22} />} className="but-home but-home_op" style={{ zIndex: 10 }}>المزيد من التدوينات</Button>
                </section>
                : null}
            <div className='six-section'>
                <p className="mt-5 mb-5 fs-3 text-light f-bold">تطبيقاتنا</p>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={12}>
                            <Nav variant="" className="nav-app flex-row justify-content-center align-items-center">
                                <Nav.Item className='title-item'>
                                    <Nav.Link eventKey="first">
                                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                                            <p className=' m-0'>جمال</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='title-item '>
                                    <Nav.Link eventKey="second">
                                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                                            <p className=' m-0'>جمال ميديا</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='title-item '>
                                    <Nav.Link eventKey="third">
                                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                                            <p className=' m-0'>جمال ميلوديا</p>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={12} className='mt-5' style={{ zIndex: 2 }}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="container">
                                        <div className="row justify-content-center align-items-center g-2">
                                            <div className="col order-1 order-lg-0">
                                                <div>
                                                    <p className='name-apps'>جـمـال</p>
                                                    <p className='dis-apps'>
                                                        تطبيق جمال ويعمل على انظمة ابل والاندرويد ،جمال منصة رقمية متكاملة تجمع تاريخ الفتوى المقدسة والمقاومة الاسلامية في منصة واحدة بدءاً من بيانات الشهداء واحصائياتهم الى الخدمات الثقافية والخبرية والاجتماعية.
                                                    </p>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center mt-5'>
                                                    <Button variant="" className="but-home m-2 fs-20" icon={<BsApple size={25} />} style={{ zIndex: 10, backgroundColor: 'white', color: '#23a5ab', width: 150 }}> ios</Button>
                                                    <Button variant="" className="but-home m-2 fs-20" icon={<BsAndroid2 size={25} />} style={{ zIndex: 10, backgroundColor: 'white', color: '#23a5ab', width: 150 }}> android</Button>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-6 col-12">
                                                <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/mobile.png'} className='image-apps' width={'100%'} />
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="container">
                                        <div className="row justify-content-center align-items-center g-2">
                                            <div className="col order-1 order-lg-0">
                                                <div>
                                                    <p className='name-apps'>جـمـال ميديا</p>
                                                    <p className='dis-apps'>
                                                        تطبيق جمال ميديا ويعمل على انظمة ابل والاندرويد والشاشات واجهزة الكمبيوتر وهي منصة تدفق ومشاركة الفيديو اشبه باليوتيوب وتقدم خدماتها بشكل مستقل وتظم الالاف من الفيديوهات الحربية والافلام القصيرة والوثائقية والسينمائية
                                                    </p>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center mt-5'>
                                                    <Button variant="" className="but-home m-2 fs-20" icon={<BsApple size={25} />} style={{ zIndex: 10, backgroundColor: 'white', color: '#23a5ab', width: 150 }}> ios</Button>
                                                    <Button variant="" className="but-home m-2 fs-20" icon={<BsAndroid2 size={25} />} style={{ zIndex: 10, backgroundColor: 'white', color: '#23a5ab', width: 150 }}> android</Button>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-6 col-12">
                                                <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/mobile.png'} className='image-apps' width={'100%'} />
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="container">
                                        <div className="row justify-content-center align-items-center g-2">
                                            <div className="col order-1 order-lg-0">
                                                <div>
                                                    <p className='name-apps'>جـمـال ميلوديا</p>
                                                    <p className='dis-apps'>
                                                        تطبيق جمال ميلوديا ويعمل على انظمة ابل والاندرويد والشاشات واجهزة الكمبيوتر وهي منصة تدفق ومشاركة الموسيقى  اشبه بالساوندكلاود وتقدم خدماتها بشكل مستقل وتظم الالاف من المقطوعات الصوتية والموسيقية بدءاً من القران الكريم والادعية والمراثي الحسينية والمحاضرات الدينية
                                                    </p>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center mt-5'>
                                                    <Button variant="" className="but-home m-2 fs-20" icon={<BsApple size={25} />} style={{ zIndex: 10, backgroundColor: 'white', color: '#23a5ab', width: 150 }}> ios</Button>
                                                    <Button variant="" className="but-home m-2 fs-20" icon={<BsAndroid2 size={25} />} style={{ zIndex: 10, backgroundColor: 'white', color: '#23a5ab', width: 150 }}> android</Button>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-6 col-12">
                                                <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/mobile.png'} className='image-apps' width={'100%'} />
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div >
    )
}
