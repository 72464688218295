import { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

function DropdownNav({ title, list }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleMouseOver = () => {
        setDropdownOpen(true);
    }

    const handleMouseOut = () => {
        setDropdownOpen(false);
    }

    return (
        <Dropdown show={dropdownOpen} drop='down-centered' >
            <Button
                variant=''
                className='dropdown-bot'
                id='dropdown-bot'
                onClick={handleMouseOver}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
            >
                {title}
                {dropdownOpen ?
                    <i className="fa-solid fa-chevron-up dropdown-icon"></i>
                    :
                    <i className="fa-solid fa-chevron-down dropdown-icon"></i>
                }
            </Button>

            <Dropdown.Menu onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} flip>
                {list.map((item, index) => { return (<Dropdown.Item key={index + 1} href={item.route}>{item.name}</Dropdown.Item>) })}

            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownNav;

export function DropdownNavMin({ title, list }) {
    return (
        <Accordion style={{ backgroundColor: 'transparent' }}>
            <Accordion.Item eventKey="0" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <Accordion.Header style={{ backgroundColor: 'transparent' }}>{title}</Accordion.Header>
                <Accordion.Body >
                    {list.map((item, index) => {
                        return (
                            <p >
                                <a style={{ color: '#000' }} key={index + 1} href={item.route}>{item.name}</a>
                            </p>
                        )
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    );
}
