import React, { useEffect } from 'react'
import { navPage } from '../../funcAndVar/func'

export default function Applications() {
  useEffect(() => {
    document.title = 'تطبيقات'
    navPage()
  }, [])
  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-items-center g-2">
        <div className="col-12">
          <div className='applications-image'>
            <img src='https://jcdn.eu-central-1.linodeobjects.com/shared/APPS_THUMBNAILS.png' />
          </div>
        </div>
        <div className="col-12">
          <div className='applications-buts'>
            <a className='fs-20'><i className="fa-brands fa-android"></i> android</a>
            <a className='fs-20'><i className="fa-brands fa-android"></i> tv</a>
            <a className='fs-20'><i className="fa-brands fa-apple"></i> ios</a>
            <a className='fs-20'><i className="fa-brands fa-apple"></i> tv</a>
          </div>
        </div>
        <div className="col-12">
          <div className='applications-text'>
            <p>
              أكتشف   بوابة جمال الرقمية – المنصة العراقية الرائدة في التوثيق!
              إنها واحة الإلهام والتواصل التي تقدم تجربة استثنائية لعشاق قادة الانتصار على الارهاب ومحبيهم . مع منصة بوابة جمال الرقمية، يمكنك الاستمتاع بمجموعة واسعة من المحتوى الفريد والمثير الذي يجمع بين عمليات تحرير العراق وأرشيف شهداء الوطن وبطولات الحشد الشعبي والقوات الأمنية العراقية.
              انغمس في عمليات التحرير المقدسة التي شكلت تاريخنا الحديث، واستعد لمشاهدة أحداث لا تُنسى كتبتها دماء طاهرة. يمنحك ارشيف قادة الحشد الشعبي وشهداءة  فرصة لاكتشاف تجاربهم وروحهم القيادية الاستثنائية، بما في ذلك المؤسس والقائد العظيم الشهيد أبو مهدي المهندس.
              بفضل توافر بوابة جمال الرقمية على جميع المنصات وأنظمة الهواتف، يمكنك الاستمتاع بتجربتك المفضلة على جهازك المحمول أو الكمبيوتر اللوحي أو التلفاز. استمتع بالمحتوى الفريد والمتنوع في أي وقت وفي أي مكان.
              اكتشف بوابة جمال الرقمية اليوم وانغمس في تجربتك الجديدة. تعايش مع الأحداث التاريخية وتابع تطورات البلاد، واستمتع بارشيف الدفاع المقدس وقصص البطولة التي ستلهمك وتشعرك بالفخر والعزة. انضم إلينا الآن وكن جزءًا من عائلة بوابة جمال الرقمية، المنصة العراقية الفريدة من نوعها.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
