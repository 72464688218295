import React, { useEffect, useState } from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import { navPage } from '../../funcAndVar/func'
import { IoIosBook, IoIosCreate } from "react-icons/io";
import { Navigation } from "swiper";
import axios from 'axios';
import Loading from '../../components/Loading';
import { Accordion, Button } from 'react-bootstrap';
import { Avatar, Input } from 'antd';
import { defaultProfile } from '../../funcAndVar/var';
export default function LibraryHome() {
    const [loading, setLoading] = useState(true)
    const [books, setBooks] = useState([])
    const [author, setAuthor] = useState([])
    const [catogary, setCatogary] = useState([])
    const [authorNav, setAuthorNav] = useState([])
    const [publisher, setPublisher] = useState([])
    const [searchBook, setSearchBook] = useState('')
    const [searchBook2, setSearchBook2] = useState('')
    const [booksSearch, setBooksSearch] = useState([])
    const [searchAuthor, setSearchAuthor] = useState('')
    const [booksSearch2, setBooksSearch2] = useState([])
    const [searchCatogary, setSearchCatogary] = useState('')
    const [searchPublisher, setSearchPublisher] = useState('')

    useEffect(() => {
        document.title = 'مكتبة جمال'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/?page_size=24`)
                .then(res => {
                    setBooks(res.data.results)
                })
            await axios.get(`/shared/api/v1/authors/?type=0&page_size=20`)
                .then(res => {
                    setAuthor(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/?page_size=5&search=${searchBook2}`)
                .then(res => {
                    setBooksSearch2(res.data.results)
                })
        }
        getData()
    }, [searchBook2])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/?page_size=5&search=${searchBook}`)
                .then(res => {
                    setBooksSearch(res.data.results)
                })
        }
        getData()
    }, [searchBook])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/authors/?type=0&page_size=5&search=${searchAuthor}`)
                .then(res => {
                    setAuthorNav(res.data.results)
                })
        }
        getData()
    }, [searchAuthor])
    useEffect(() => {
        async function getData() {
            await axios.get(`/shared/api/v1/categorys/?type=6&page_size=5&search=${searchCatogary}`)
                .then(res => {
                    setCatogary(res.data.results)
                })
        }
        getData()
    }, [searchCatogary])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/publishers/?page_size=5&search=${searchPublisher}`)
                .then(res => {
                    setPublisher(res.data.results)
                })
        }
        getData()
    }, [searchPublisher])
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid mt-3">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-lg-12">
                        <div className="container-fluid mt-1">
                            <div className="row justify-content-center align-items-start g-2">
                                <div className="col-12">
                                    <div class="container-fluid">
                                        <div class="row justify-content-center align-items-center g-2">
                                            <div className="col-lg-5 col-md-6  position-relative d-flex flex-column justify-content-center align-items-center">
                                                <Input
                                                    type='text'
                                                    size='large'
                                                    placeholder='بحث'
                                                    suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                    style={{
                                                        borderRadius: 5,
                                                        width: '100%'
                                                    }}
                                                    onChange={(e) => setSearchBook2(e.target.value)}
                                                />
                                                {searchBook2 &&
                                                    <div className='ibrary-home-search'>
                                                        {booksSearch2.map((item) => {
                                                            return (
                                                                <div key={item.id} className='ibrary-home-books-card w-100'>
                                                                    <img alt="..." src={item.cover !== null ? item.cover : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/bookcover.jpg'} className='ibrary-home-books-card-image' />
                                                                    <div className='ibrary-home-books-card-info'>
                                                                        <div>
                                                                            <p className='ibrary-home-books-card-title'>{item.title}</p>
                                                                            <p className='ibrary-home-books-card-author'>{item.author.first_name} {item.author.second_name} {item.author.last_name}</p>
                                                                        </div>
                                                                        <Button variant='' href={`/library/book/${item.id}/`} className='ibrary-home-books-card-but'>قراءة</Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-xl-3  mt-lg-2 order-1 order-xl-0">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>الأقسام</Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <Input
                                                        type='text'
                                                        placeholder='بحث'
                                                        suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                        style={{
                                                            borderRadius: 5,
                                                        }}
                                                        onChange={(e) => setSearchCatogary(e.target.value)}
                                                    />
                                                </div>
                                                <div className='ibrary-home-author'>
                                                    {catogary.map(item => {
                                                        return (
                                                            <a key={item.id} href={`/library/category/${item.id}/`} className='ibrary-home-author-card'>
                                                                <div
                                                                    className={'news-category-radio-active'}
                                                                />
                                                                <p>{item.name}</p>
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>دور النشر</Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <Input
                                                        type='text'
                                                        placeholder='بحث'
                                                        suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                        style={{
                                                            borderRadius: 5,
                                                        }}
                                                        onChange={(e) => setSearchPublisher(e.target.value)}
                                                    />
                                                </div>
                                                <div className='ibrary-home-author'>
                                                    {publisher.map(item => {
                                                        return (
                                                            <a key={item.id} href={`/library/publisher_name/${item.id}/`} className='ibrary-home-author-card'>
                                                                <div
                                                                    className={'news-category-radio-active'}
                                                                />
                                                                <p>{item.name}</p>
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>المؤلفين</Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <Input
                                                        type='text'
                                                        placeholder='بحث'
                                                        suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                        style={{
                                                            borderRadius: 5,
                                                        }}
                                                        onChange={(e) => setSearchAuthor(e.target.value)}
                                                    />
                                                </div>
                                                <div className='ibrary-home-author'>
                                                    {authorNav.map(item => {
                                                        return (
                                                            <a key={item.id} href={`/library/author/${item.id}/`} className='ibrary-home-author-card'>
                                                                <Avatar src={item.image} size={40} />
                                                                <p>{item.first_name} {item.second_name} {item.last_name}</p>
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>الكتب</Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <Input
                                                        type='text'
                                                        placeholder='بحث'
                                                        suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                                        style={{
                                                            borderRadius: 5,
                                                        }}
                                                        onChange={(e) => setSearchBook(e.target.value)}
                                                    />
                                                </div>
                                                {searchBook &&
                                                    <div style={{ width: '100%' }}>
                                                        {booksSearch.map((item) => {
                                                            return (
                                                                <div key={item.id} className='ibrary-home-books-card' style={{ width: '100%' }}>
                                                                    <img alt="..." src={item.cover !== null ? item.cover : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/bookcover.jpg'} className='ibrary-home-books-card-image' />
                                                                    <div className='ibrary-home-books-card-info'>
                                                                        <p className='ibrary-home-books-card-title'>{item.title}</p>
                                                                        <p className='ibrary-home-books-card-author'>{item.author.first_name} {item.author.second_name} {item.author.last_name}</p>
                                                                        <Button variant='' href={`/library/book/${item.id}/`} className='ibrary-home-books-card-but'>قراءة</Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="col-xxl-10 col-xl-9  order-0 order-xl-1">
                                    <div className="container-fluid ibrary-home-content">
                                        <div className="row justify-content-start align-items-start g-2">
                                            <div className="col-12">
                                                <Swiper
                                                    dir="rtl"
                                                    slidesPerView={1}
                                                    spaceBetween={5}
                                                    slidesPerGroup={1}
                                                    loop={true}
                                                    navigation={true}
                                                    breakpoints={{
                                                      
                                                        768: {
                                                            slidesPerView: 2,
                                                            slidesPerGroup: 2
                                                        },
                                                        1024: {
                                                            slidesPerView: 3,
                                                            slidesPerGroup: 3
                                                        },
                                                        1300: {
                                                            slidesPerView: 4,
                                                            slidesPerGroup: 4
                                                        },
                                                    }}
                                                    modules={[Navigation]}
                                                    loopFillGroupWithBlank={true}
                                                    className='ibrary-home-swiper'
                                                >
                                                    {books.map((item) => {
                                                        return (
                                                            <SwiperSlide key={item.id}>
                                                                <div className='ibrary-home-swiper-item'>
                                                                    <div className='ibrary-home-card'>
                                                                        <img alt="..." src={item.cover !== null ? item.cover : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/bookcover.jpg'} className='ibrary-home-card-image' />
                                                                        <div className='ibrary-home-card-info'>
                                                                            <a href={`/library/book/${item.id}/`}>
                                                                                <p className='ibrary-home-card-title'>
                                                                                    {item.title}
                                                                                </p>
                                                                            </a>
                                                                            {item.author !== null ?
                                                                                <a href={`/library/author/${item.author.id}/`}>
                                                                                    <p className='ibrary-home-card-author' >
                                                                                        {item.author.first_name} {item.author.second_name} {item.author.last_name}
                                                                                    </p>
                                                                                </a>
                                                                                : null
                                                                            }

                                                                            <Button variant='' href={`/library/book/${item.id}/`} className='ibrary-home-card-but'>قراءة</Button>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p className='ibrary-home-info'>الوصف</p>
                                                                        <p className='ibrary-home-summary'>
                                                                            {String(item.summary).slice(0, 75)} ...
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    })}
                                                </Swiper>
                                            </div>
                                            <div className="col-xl-12">
                                                <p className='f-bold border-bottom pb-3 mb-3'><IoIosBook size={20} color='#23b5ab' /> الكتب المميزة</p>
                                                <div className='ibrary-home-books'>
                                                    <div class="container-fluid">
                                                        <div class="row justify-content-start align-items-center g-2">
                                                            {books.map((item) => {
                                                                return (
                                                                    <div key={item.id} className='ibrary-home-books-card col-xxl-3 col-lg-4 col-md-6 col-sm-6'>
                                                                        <img alt="..." src={item.cover !== null ? item.cover : 'https://jcdn.eu-central-1.linodeobjects.com/website/image/bookcover.jpg'} className='ibrary-home-books-card-image' />
                                                                        <div className='ibrary-home-books-card-info'>
                                                                            <div className='h-100'>
                                                                                <a href={`/library/book/${item.id}/`}>
                                                                                    <p className='ibrary-home-books-card-title'>{item.title}</p>
                                                                                </a>
                                                                                {item.author !== null ?
                                                                                    <a href={`/library/author/${item.author.id}/`}>
                                                                                        <p className='ibrary-home-books-card-author'>{item.author.first_name} {item.author.second_name} {item.author.last_name}</p>
                                                                                    </a>
                                                                                    : null
                                                                                }
                                                                            </div>
                                                                            <Button variant='' href={`/library/book/${item.id}/`} className='ibrary-home-books-card-but'>قراءة</Button>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mt-5">
                                                <p className='f-bold border-bottom pb-3 mb-3'><IoIosCreate size={20} color='#23b5ab' /> ابرز المؤلفين</p>
                                                <Swiper
                                                    dir="rtl"
                                                    slidesPerView={2}
                                                    spaceBetween={5}
                                                    loop={true}
                                                    navigation={true}
                                                    modules={[Navigation]}
                                                    breakpoints={{
                                                        640: {
                                                            slidesPerView: 4,
                                                        },
                                                        768: {
                                                            slidesPerView: 4,
                                                        },
                                                        1024: {
                                                            slidesPerView: 6,
                                                        },
                                                        1300: {
                                                            slidesPerView: 8,
                                                        }
                                                    }}
                                                >
                                                    {author.map(item => {
                                                        return (
                                                            <SwiperSlide key={item.id}>
                                                                <a key={item.id} href={`/library/author/${item.id}/`} className='d-flex flex-column justify-content-center align-items-center'>
                                                                    <Avatar shape='square' src={item.image !== null ? item.image : defaultProfile} size={100} />
                                                                    <p className='text-dark fs-14 text-center'>{item.first_name} {item.second_name} {item.last_name}</p>
                                                                </a>
                                                            </SwiperSlide>

                                                        )
                                                    })}

                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
