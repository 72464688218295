import React, { useEffect, useState } from 'react'
import { navPage } from '../../funcAndVar/func'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import axios from 'axios';
import { Avatar, Input, Button } from 'antd';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import ItemCard from '../../components/remains/ItemCard';
import { defaultProfile2 } from '../../funcAndVar/var';
import { AuthorCard } from '../../components/cards';
import { LuLayoutList } from 'react-icons/lu';

export default function RemainsHome() {
    const [loading, setLoading] = useState(true)
    const [qoute, setQoute] = useState([]);
    const [dead, setDead] = useState([]);
    const [search, setSearch] = useState('');
    const [deadSearch, setDeadSearch] = useState([]);

    useEffect(() => {
        document.title = 'أثار الشهداء'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/remains/`)
                .then(res => {
                    setQoute(res.data.results);
                })

        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get('/dead_wounded/api/v1/Death/?has_remain=true&page_size=6')
                .then(res => {
                    setDead(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/dead_wounded/api/v1/Death/?has_remain=true&search=${search}`)
                .then(res => {
                    setDeadSearch(res.data.results)
                })
        }
        getData()
    }, [search])
    if (loading) {
        return <Loading />
    }
    return (
        <div className="remains-page">
            <div className="container-fluid p-0 m-0 position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-12 remains-page-search">
                        <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                            <Input
                                type='text'
                                size='large'
                                placeholder='بحث'
                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                style={{
                                    borderRadius: 5,
                                    width: '50%'
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search &&
                                <div className='remains-page-search-content'>
                                    {deadSearch.map((item) => {
                                        return (
                                            <a href={`/remains/dead/${item.id}/`} key={item.id} className='search-item'>
                                                <Avatar src={item.image !== null ? item.image : defaultProfile2} className='' size={75} />
                                                <p className='neme'>{item.first_name} {item.second_name} {item.third_name} {item.last_name}</p>
                                            </a>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    {qoute.length > 0 ?
                        <>
                            {qoute.length > 0 &&
                                <div className="col-12">
                                    <Swiper
                                        loop={true}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="remains-page-swiper swiper"
                                        style={{
                                            '--swiper-navigation-color': '#aaa',
                                            "--swiper-pagination-color": "#fff",
                                        }}
                                    >
                                        {dead.map((item, index) => {
                                            return (
                                                <SwiperSlide key={item.id}>
                                                    <ItemCard item={item} />
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            }
                            {dead.length > 0 &&
                                <div className="col-xl-12 quote-page-card">
                                    <p>أبرز أصحاب الأثار</p>
                                    <div className="container-fluid">
                                        <div className="row justify-content-center align-items-center g-2">
                                            {dead.map((item) => {
                                                return (
                                                    <div key={item.id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6 col-12">
                                                        <AuthorCard
                                                            id={item.id}
                                                            title='شهيد'
                                                            path={`/remains/dead/${item.id}/`}
                                                            name={`${item.first_name} ${item.second_name} ${item.third_name} ${item.last_name}`}
                                                            image={item.image}
                                                            infoTitleOne='اثر'
                                                            infoTitleTow='متابع'
                                                            infoCountOne={item.r_count}
                                                            infoCountTow={50}
                                                        />
                                                    </div>
                                                )
                                            })}

                                            <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
                                                <Button icon={<LuLayoutList />} href='/remains/dead/' className='but-more'>المزيد</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </> : <Empty />}
                </div>
            </div>
        </div>
    )
}
