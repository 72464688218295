import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { bookItem } from '../../funcAndVar/func'
import Loading from '../../components/Loading';
import { Result } from 'antd';
export default function BookObject() {
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [url, setUrl] = useState('')
    useEffect(() => {
        bookItem()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/library/api/v1/books/${id}/`)
                .then(res => {
                    document.title = res.data.title
                    setUrl(res.data.file)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    if (url === '') return null
    if (loading) {
        return <Loading />;
    }
    return (
        <div className='h-100 w-100 m-0 p-0'>
            <div className="container-fluid m-0 p-0">
                <div className="row justify-content-center align-items-center g-2">
                    {isFirefox ?
                        (
                            <div className='col-12 d-flex justify-content-center align-items-center'>
                                <Result
                                    status="warning"
                                    title="عذرا هذا المتصفح لا يدعم ملفات pdf"
                                    extra={
                                        <p>
                                            لمشاهدة الملف يمكن{' '}
                                            <a href={url} download>
                                                تحميل الملف
                                            </a>.
                                        </p>
                                    }
                                />
                            </div>
                        )
                        :
                        (
                            <div className='col-12' style={{ width: '100%', height: window.innerHeight }}>
                                <object width="100%" height="100%" data={url} type="application/pdf" />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
