import { Button, Avatar } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FiShare } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import Gallery from '../../components/gallery/Gallery';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import { defaultCover2, defaultProfile } from '../../funcAndVar/var';
import NavGallery from '../../components/gallery/NavGallery';
import Share from '../../components/Share';
import { SlUserFollowing } from 'react-icons/sl';

export default function Photographer() {
    const [loading, setLoading] = useState(true)
    const { id } = useParams();
    const [count, setCount] = useState(0)
    const [photographer, setPhotographer] = useState('')
    const [images, setImages] = useState([])
    useEffect(() => {
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/gallery/api/v1/photographers/${id}/`)
                .then(res => {
                    setPhotographer(res.data)

                    document.title = `${res.data.first_name} ${res.data.second_name} ${res.data.last_name}`
                })
        }
        getData()
    }, [id]);
    useEffect(() => {
        async function getData() {
            await axios.get(`/gallery/api/v1/images/?photographer=${id}&page_size=50`)
                .then(res => {
                    setCount(res.data.count)
                    setImages(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [id]);
    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center g-2">

                    <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12">
                        <div className='photographer-header'>
                            <div>
                                <img alt="..." src={defaultCover2} className='photographer-header-cover' />
                            </div>
                            <div className='photographer-header-avatar'>
                                <Avatar src={photographer.image !== null ? photographer.image : defaultProfile} size={125} />
                                <p className='photographer-header-title f-bold'>{photographer.first_name} {photographer.second_name} {photographer.last_name}</p>
                                <p className='photographer-header-count'>{count} صورة</p>
                                <p className='photographer-header-count'>{photographer.description}</p>
                            </div>
                            <div className='photographer-header-buts'>
                                <Button variant='' icon={<SlUserFollowing />} className='but-flow'>متابعة</Button>
                                <div>
                                    <Share url={`/gallery/photographer/${id}`}>
                                        <Button type='text' title='مشاركة' className='photographer-header-dropdown'>
                                            <FiShare size={20} />
                                        </Button>
                                    </Share>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className='image-item-more-image f-bold'>الصور</p>
                        <div >
                            <Gallery
                                url={`/gallery/api/v1/images/?photographer=${id}&page_size=100`}
                                path='/gallery/image/' />
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12 gallery-nav'>
                        <NavGallery />
                    </div>
                </div>
            </div>
        </div>
    )
}
