import { Button, Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MdTextSnippet } from 'react-icons/md';
import { navPage } from '../../funcAndVar/func';
import Loading from '../../components/Loading';
import { AuthorCard, CategoryCard } from '../../components/cards';
import { LuLayoutList } from 'react-icons/lu';

export default function StoryHome() {
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [search, setSearch] = useState('');
    const [dataSearch, setDataSearch] = useState([]);
    useEffect(() => {
        document.title = 'القصص'
        navPage()
    }, [])
    useEffect(() => {
        async function getData() {

            await axios.get(`/shared/api/v1/categorys/?type=8&page_size=4`)
                .then(res => {
                    setCategory(res.data.results)
                })
            await axios.get(`/shared/api/v1/authors/?type=5`)
                .then(res => {
                    setAuthors(res.data.results)
                    if (res.status === 200) setLoading(false)
                })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await axios.get(`/literature/api/v1/storys/?search=${search}`)
                .then(res => {
                    setDataSearch(res.data.results)
                })
        }
        getData()
    }, [search])
    if (loading) {
        return <Loading />
    }
    return (
        <div className='literature-page'>
            <div className="container-fluid p-0 m-0 position-relative">
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-xl-5 col-lg-6 literature-page-search">
                        <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                            <Input
                                type='text'
                                size='large'
                                placeholder='بحث'
                                suffix={<i className="fa-solid fa-magnifying-glass icon-input"></i>}
                                style={{
                                    borderRadius: 5,
                                    width: '100%'
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            {search &&
                                <div className='literature-page-search-content'>
                                    {dataSearch.map((item) => {
                                        return (
                                            <div key={item.id} className='poem-item p-1'>
                                                <a href={`/storys/story/id/${item.id}/name/${item.name}`}><MdTextSnippet size={20} color='#23b5ab' /> {item.name}</a>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12 section-1">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-xl-6 d-flex justify-content-center align-items-center">
                                    <img alt="..." className='img-section' src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/bg_ad_nov.png'} />
                                </div>
                                <div className="col-xl-6">
                                    <div>
                                        <p className='title-1 f-bold'>سرد المقاومة</p>
                                        <p className='title-2'>
                                            أما المقاومة فهي ليست مجرد تعبير وليست مجرد إظهار لعواطف وأحاسيس بل هي في الواقع فعل وسلوك خارجي يقوم به الإنسان على أثر عقيدة أو هدف أو قيم عالية وبالتالي فإن هذا الفعل يحتزن في داخله معاني سامية تدعو إلى التمجيد والقبول والاستحسان... فهو إذن موضوع بحد ذاته يشكل في خلفياته وأسبابه ونتائجه موارد للدراسة والتحقيق والتأمل.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 section-2">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-xl-6">
                                    <div>
                                        <p className='title-1 f-bold'>ذاكرة ..الحشد والفتوى</p>
                                        <p className='title-2'>
                                            تأثير هذه القصة في صنع التحولات الإيمانية والروحية عند الناس خصوصاً قصص الشهداء التي تأتي في سياق وصايا شهداء أو حياة الشهداء وتعميم القصة المكتوبة
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xl-6 d-flex justify-content-center align-items-center">
                                    <div className="lab-item">
                                        <div className="lab-inner">
                                            <div className="lab-thumb">
                                                <div className="img-grp">
                                                    <div className="about-circle-wrapper">
                                                        <div className="about-circle-2"></div>
                                                        <div className="about-circle"></div>
                                                    </div>
                                                    <div className="about-fg-img-3">
                                                        <img alt="..." src={'https://jcdn.eu-central-1.linodeobjects.com/website/image/qasims_idab.png'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-5 pt-5">
                        <div className="container">
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-12">
                                    <h4 className='f-bold'>الأقسام</h4>
                                </div>
                                {category.map(item => {
                                    return (
                                        <a key={item.id} href={`/storys/categorys/id/${item.id}/name/${item.name}`} className="col-xl-3">
                                            <CategoryCard
                                                name={item.name}
                                                image={item.image}
                                            />
                                        </a>
                                    )
                                })}
                                <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
                                    <Button variant='' icon={<LuLayoutList />} href='/storys/categorys/' className='but-more'>المزيد</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pb-5 pt-5 section-4">
                        <div className="container">
                            <div className="row justify-content-start align-items-center g-2">
                                <div className="col-12">
                                    <h4 className='f-bold'>ابرز القُصَّاص</h4>
                                </div>
                                {authors.map((item) => {
                                    const name = `${item.first_name} ${item.second_name} ${item.last_name}`
                                    return (
                                        <div key={item.id} className="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6">
                                            <AuthorCard
                                                id={item.id}
                                                title='قاص'
                                                path={`/storys/authors/id/${item.id}/name/${name}`}
                                                name={name}
                                                image={item.image}
                                                infoTitleOne='قصة'
                                                infoTitleTow='متابع'
                                                infoCountOne={50}
                                                infoCountTow={50}
                                            />
                                        </div>
                                    )
                                })}
                                <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
                                    <Button variant='' icon={<LuLayoutList />} href='/storys/authors/' className='but-more'>المزيد</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
